import React from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {fieldValidation} from "../../../helpers/fieldValidation";
import {stringReplace} from "../../../helpers/stringReplace";

export default function Validation(fields, customFunc) {
  let schemaData = {};
  let initialValues = {};

  if (fields && Object.entries(fields).length > 0) {
    Object.entries(fields).map(([fieldName, field], i) => {

      const label = fields[fieldName].label ? stringReplace(fields[fieldName].label, 'capitalize') : stringReplace(fieldName, 'capitalize');
      initialValues[fieldName] = fields[fieldName].initial_value;

      switch (fieldName) {
        case 'date_range':
          fields[fieldName].date_range_fields && Object.entries(fields[fieldName].date_range_fields).map(([key, dateField], dr_id) => {
            initialValues[key] = dateField.initial_value
          })
          break
        case 'type_id':
          fields[fieldName].autocomplete_field && Object.entries(fields[fieldName].autocomplete_field).map(([key, autocompleteField], dr_id) => {
            initialValues[key] = autocompleteField.initial_value

            const autocompleteValidationRules = autocompleteField.validation.split("|");
            schemaData[key] = fieldValidation(autocompleteValidationRules, key, label);
          })
          break
        case 'address':
          fields[fieldName] && Object.entries(fields[fieldName]).map(([fieldAddress, addressField], ad_i) => {
            initialValues[fieldAddress] = addressField.initial_value
          })
          break
        default:
          initialValues[fieldName] = fields[fieldName].initial_value;
      }

      if (typeof (field.validation) !== 'undefined') {
        const validationRules = field.validation.split("|");
        schemaData[fieldName] = fieldValidation(validationRules, fieldName, label);
      }
    })
  }

  const validationSchema = yup.object(schemaData);

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      customFunc(values);
    },
  })
}


