import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {stringReplace} from "../../../helpers/stringReplace";
import Option from "../../../stores/action/Form/Option";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export default function SearchDateField(props) {
  const {name, id, label, variant, disabled, future, past, required, value, className, onChangeEvent} = props;

  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (value === "") {
      setSelectedDate(moment().toISOString());
    } else {
      setSelectedDate(moment(value).toISOString());
    }
  }, [value]);

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date.format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale('en-au')}>
      <KeyboardDatePicker
        className={className}
        inputVariant={variant}
        id={id}
        fullWidth={true}
        name={name}
        required={required}
        label={label}
        format="DD/MM/YYYY"
        value={selectedDate}
        onChange={onChangeEvent}
        KeyboardButtonProps={{
          'aria-label': {label} + ' change',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
