import React from 'react';
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import CustomCard from "../../containers/Card/CustomCard";
import AccordionForm from "../../containers/Accordion/AccordionForm";
import DynamicValue from "../../containers/DataTable/DynamicTable/DynamicValue";
import PersonnelForm from "./Form/PersonnelForm";
import {Divider, Typography} from "@material-ui/core";

const header = (fields) => {

  return (
    <CustomGrid auto={false}>
      <CustomBox className={'noMargin'} direction={'column'}>
        <Typography variant={'body2'}>
          Personnel Identifier: <DynamicValue type={'default'} value={fields.external_personnel_identifier}/>
        </Typography>
        <Typography variant={'body2'}>
          Personnel Type: <DynamicValue type={'render|types'} value={fields.type_id_resource_type}/>
        </Typography>
        <Typography variant={'body2'}>
          Start Date: <DynamicValue type={'date'} value={fields.start_date}/>
          {' '}
          End Date: <DynamicValue type={'date|no_expiry'} value={fields.end_date}/>
        </Typography>

      </CustomBox>
      <CustomBox className={'noMargin'} align={'right'}>
        <DynamicValue type={'render|status'} value={fields.status_id}/>
      </CustomBox>
    </CustomGrid>
  )
}

const UserPersonnelItems = (props) => {
  const {fields, uid, cmid, sectionCrudAllowed} = props;

  return (
    <CustomCard
      variant={'outlined'}
    >
      <AccordionForm
        header={header(fields)}
        addButton={true}
        title={'Personnel'}
        form={<PersonnelForm data={{}} cmid={cmid} sectionCrudAllowed={sectionCrudAllowed}/>}
      >
        <Divider/>
        <PersonnelForm data={fields} id={fields.id} uid={uid} cmid={cmid} sectionCrudAllowed={sectionCrudAllowed}/>
      </AccordionForm>
    </CustomCard>
  )
}

export default UserPersonnelItems;