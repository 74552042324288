import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  Typography
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import UserTranscript from "../../stores/action/User/UserTranscript";
import {useStore} from "../../stores/store";
import Loading from "../UI/Loading/Loading";
import Alert from "../UI/Alert/Alert";
import Box from "@material-ui/core/Box";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const DialogTitle =((props) => {
  const { onClose} = props;
  return (
    <MuiDialogTitle>
      <Box display="flex" justifyContent="flex-end">
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} edge={'end'} className={'cellRed'} >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
});

export default function TranscriptButton(props) {
  const {id} = props;
  const {state, dispatch} = useStore();
  const [error, setError] = useState({});
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const downloadFile = (version) => {
    if(version === 'pdf') {
      setLoadingPdf(true);
    } else {
      setLoadingCsv(true);
    }

    UserTranscript(dispatch, id, setError, version);
  };

  useEffect(() => {
    setLoadingCsv(false);
    setLoadingPdf(false);

    state.global_update = false;
  }, [state.global_update]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box mt={'5px'}>
      <Button variant="contained" color="primary" onClick={handleClickOpen} endIcon={<GetAppIcon/>}>
        Transcript
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6">{"Select transcript version you want to download?"}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={loadingCsv}
            endIcon={(loadingPdf) ? <Loading height={20} size={30} color={'secondary'}/> : ''}
            onClick={() => downloadFile('pdf')}
          >
            {(loadingPdf) ? 'Downloading...' : 'PDF Version'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loadingPdf}
            endIcon={(loadingCsv) ? <Loading height={20} size={30} color={'secondary'}/> : ''}
            onClick={() => downloadFile('csv')}
          >
            {(loadingCsv) ? 'Downloading...' : 'CSV Version'}
          </Button>
          {
            (error.message) && (
              <Alert notification={error} state={setError} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
            )
          }
        </DialogActions>
      </Dialog>
    </Box>
  );
}