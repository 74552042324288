import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from "@material-ui/core/Box";
import {ListItem, Typography} from "@material-ui/core";
import {useStore} from "../../stores/store";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(2, 0)
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        '&:hover': {
            cursor: 'pointer'
        }
    },
}));

//TODO Add Company and position
export default function ProfileAvatar() {
    const classes = useStyles();
    const {state} = useStore();

    return (
        <div className={classes.root} >
            <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar src="/broken-image.jpg" className={classes.large} color="primary.main" onClick={() => window.location = "/profile"}/>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">{state.user_data.user_details.first_name} {state.user_data.user_details.last_name}</Typography>
            </Box>
            {/*<Box display="flex" justifyContent="center" alignItems="center">*/}
            {/*    <Typography variant="body2">{state.user_data.work_details.position}</Typography>*/}
            {/*</Box>*/}
        </div>
    );
}
