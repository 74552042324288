const competencyCategoryReducer = (state, action) => {
    switch (action.type) {
        case "COMPETENCY_CATEGORY":
            return {
                ...state,
                competency_category: action.competency_category
            }
        case "COMPETENCY_CATEGORY_UPDATED":
            return {
                ...state,
                competency_category_update: action.competency_category_update,
            }
        default:
            return state;
    }
}

export default competencyCategoryReducer;