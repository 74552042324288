import * as yup from "yup";

export function fieldValidation(validations, fieldName, label) {
    let schemaData;

    validations.map(function (validation) {
        if (!validation) {
            return;
        }

        if (validation === "string") {
            schemaData = yup.string();
        }

        if (validation === "integer") {
            schemaData = yup.number();
        }

        if (validation === "array") {
            schemaData = yup.array();
        }

        if (validation === "object") {
            schemaData = yup.object();
        }

        if (validation === "boolean") {
            schemaData = yup.boolean();
        }

        if (validation === "date") {
            schemaData = yup.date();
        }

        if (validation === "email") {
            schemaData = schemaData.email('Must be a valid email Address');
        }

        if (validation === "nullable") {
            schemaData = schemaData.nullable(true);
        }

        if (validation === "phone") {
            schemaData = schemaData.typeError('Must be a valid '+ label +' number only');
        }

        if (validation === "required") {
            schemaData = schemaData.required(label + " is required");
        }

        if (validation.includes("min:")) {
            const minVal = parseInt(validation.replace(/\D/g, ""));
            schemaData = schemaData.min(minVal, label + " should be minimum of " + minVal + " ");
        }

        if (validation.includes("max:")) {
            const maxVal = parseInt(validation.replace(/\D/g, ""));
            schemaData = schemaData.max(maxVal, label + " should be maximum of " + maxVal + " ");
        }
    });

    return schemaData;
}
