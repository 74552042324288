import React from "react";
import BuildField from "./BuildField";

export default function Field(form, field) {

    let formFields = [];

    form.map((function (value, key) {
        formFields.push(
            BuildField(value, field)
        );
    }));

    return ( formFields );
}