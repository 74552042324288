import React from 'react';
import AppContainer from "../../containers/Container/App/Main";
import TitleButtons from "../UI/Title/TitleButtons";
import CustomGrid from "../../containers/Grid/CustomGrid";
import {Box, Container, Paper} from "@material-ui/core";

const NotFoundPage = () => {
  const title_buttons = [];
  return (
    <AppContainer>

      <Box display="flex" justifyContent="center" alignItems="center" className={"loginContainer"}>
        <Container component="div" maxWidth="sm" align="center" >
          <div>
          <h2>Page not found</h2>
          <p className="error-subtitle">Sorry, but the page you are trying to view does not exist.</p>
        </div>
        </Container>
      </Box>
    </AppContainer>
  );
}

export default NotFoundPage;