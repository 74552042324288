import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Loading from "../Loading/Loading";

const SubmitButton = (props) => {
  const {fullwidth, variant, color, formik} = props;
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if(typeof formik !== "undefined" && formik.isSubmitting) {
      setSending(true);
      setTimeout(() => formik.setSubmitting(false), 5000);
    } else {
      setSending(false);
    }
  }, [formik]);

  return (
    <Button
      fullWidth={fullwidth}
      variant={variant || 'contained'}
      color={color || "primary"}
      type="submit"
      size="large"
      disabled={sending}
      endIcon={(sending) ? <Loading height={20} size={30} color={'secondary'}/> : ''}
    >
      {props.children}
    </Button>
  );
}

export default SubmitButton;
