import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {stringReplace} from "../../../helpers/stringReplace";
import Option from "../../../stores/action/Form/Option";
import {Autocomplete} from "@material-ui/lab";

export default function SearchAutocompleteField(props) {
  const {name, id, label, variant, option, multi, required, value, className, limiter, exclude, onChangeEvent} = props;
  let initialValueType = null;
  if (multi) {
    initialValueType = [];
  }
  const [selectedValue, setSelectedValue] = React.useState(initialValueType);
  const [inputValue, setInputValue] = React.useState("");

  const options = Option(option, limiter, exclude);

  let newOptions = [];
  let optionId = initialValueType;

  options && Object.entries(options).map(([fieldKey, field]) => {
    newOptions.push(field);
    if (multi) {
      if (value.includes(options[fieldKey].id)) {
        optionId.push(field);
      }
    } else {
      if (options[fieldKey].id === value) {
        optionId = options[fieldKey];
      }
    }
  });

  useEffect(() => {
    if (Object.entries(options).length > 0) {
      setSelectedValue(optionId);
    }
  }, [Object.entries(options).length]);

  return (
    <FormControl variant={variant} required={required} className={className} fullWidth>
      <Autocomplete
        multiple={multi}
        id={id}
        name={name}
        value={selectedValue}
        onChange={(event, newValue) => {
          setSelectedValue(newValue)
          onChangeEvent(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={newOptions}
        getOptionLabel={(newOptions) => newOptions.name}
        getOptionSelected={(optionId) => optionId.code}
        renderInput={(params) => <TextField {...params} required={required} variant={variant} label={label}
                                            placeholder={label}/>}
      />

    </FormControl>
  );
}
