import React, {useState} from "react";
import moment from "moment";

export default function RoleFields(data = {}) {

  return {
    name: {
      field: "input",
      label: "Name",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.name : ''
    },
    code: {
      field: "input",
      label: "Code",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.code : ''
    },
    position_id_escort: {
      field: "autocomplete",
      multi: false,
      option: "position",
      label: "Parent Position",
      required: false,
      // validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.position_id_escort !== null) ? data.position_id_escort : '',
      className: 'full'
    },
    date_range: {
      field: "date_range",
      label: "Validity Date Range",
      className: "full",
      date_range_fields: {
        valid_from_date:{
          field: "date",
          label: "Valid From",
          required: true,
          validation: "date|required",
          past: Object.entries(data).length > 0 && data.valid_from_date ? false : true,
          initial_value: Object.entries(data).length > 0 ?  data.valid_from_date : '',
        },
        valid_to_date:{
          field: "date",
          label: "Valid To",
          required: true,
          past: Object.entries(data).length > 0 && data.valid_to_date ? false : true,
          validation: "date|required",
          initial_value: Object.entries(data).length > 0 ? data.valid_to_date : '',
        }
      },
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        {id: 1, name: "Active", code: "ACTIVE"},
        {id: 2, name: "Disabled", code: "DISABLED"},
        {id: 3, name: "Archived", code: "ARCHIVED"},
        {id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED"},
      ],
      label: "Status",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: 'full'
    },
  };
};