import React, {useEffect, useState} from 'react';
import AppContainer from "../../containers/Container/App/Main";
import SimpleTabs from "../../containers/Tab/SimpleTabs";
import TitleButtons from "../UI/Title/TitleButtons";
import {useStore} from "../../stores/store";
import UserDetails from "../../stores/action/User/UserDetails";
import Loading from "../UI/Loading/Loading";
import jwt_decode from "jwt-decode";
import UserNotification from "../Notification/UserNotification";
import TeamNotification from "../Notification/TeamNotification";

const Settings = (props) => {
  const {id} = props
  const {state, dispatch} = useStore();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  const [accountHolder, setAccountHolder] = useState(true);
  const [crudAllowed, setCrudAllowed] = useState([]);
  let uid = decoded.user.user_detail;
  let newTab = [];

  if (id) {
    uid = id;
  }

  useEffect(() => {
    UserDetails(dispatch, uid);
  }, [state.global_update]);


  useEffect(() => {

    if (state.user_data.user_details_updated) {
      dispatch({type: "USER_DETAILS_UPDATED", user_details_updated: false});
      setUserData(state.user_data.user_details);
      setLoading(false);
    }

    if (state.user_data.user_details.information) {
      setAccountHolder(state.user_data.user_details.information.user_id === decoded.sub);

      if (typeof state.user_data.menu.settings !== 'undefined') {
        state.user_data.menu.settings.map((tab) => {
          if (tab.menu_id_parent === 4) {
            newTab[tab.code] = tab;
            setCrudAllowed(newTab);
          }
        })
      }
    }

  }, [state.user_data]);

  let backButton = [];

  if(!accountHolder) {
    backButton = [{
      'title': 'Back',
      'color': 'primary',
      'link': '/admin/users',
    }]
  }

  return (
    <AppContainer>
      <TitleButtons button={backButton} title={"My System Settings"} />
      {loading && <Loading/>}
      {!loading &&
        <SimpleTabs>
          <UserNotification
            title={'My Notifications'}
            uid={userData.information.user_id}
            crudAllowed={crudAllowed}
            accountHolder={accountHolder}
          />
          <TeamNotification
            title={'Teams Notifications'}
            uid={userData.information.user_id}
            visible={(typeof crudAllowed['TEAM-NOTIFICATION'] !== 'undefined') ? !!crudAllowed['TEAM-NOTIFICATION'].crud.read : false}
          />
        </SimpleTabs>
      }
    </AppContainer>
  );
};

export default Settings;
