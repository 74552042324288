const vendorReducer = (state, action) => {
  switch (action.type) {
    case "VENDOR":
      return {
        ...state,
        vendor: action.vendor,
      }
    case "VENDOR_UPDATED":
      return {
        ...state,
        update_vendor: action.update_vendor,
      }
    default:
      return state;
  }
}

export default vendorReducer;