import React, {useState} from "react";

export default function UserNotificationFields(data = {},  crudAllowed = [], accountHolder = true) {

  return {
    status_id: {
      field: "showhide",
      option: [{value: 0}, {value: 1}],
      label: "Override Notification",
      required: false,
      validation: "integer|required",
      hidden: accountHolder
        ? !accountHolder
        : typeof crudAllowed['USER-NOTIFICATION'] !== 'undefined'
        ? !!crudAllowed['USER-NOTIFICATION'].crud.update
        : false,
      initial_value: Object.entries(data).length > 0 ? (data.status_id == 2 ? 0 : 1) : 0,
      className: "full",
    },
    user_id: {
      field: "input",
      label: "USER ID",
      hidden: true,
      initial_value: (Object.entries(data).length > 0) ? data.user_id : ''
    },
    type_id_notification_type: {
      field: "select",
      multi: false,
      option: [
        {id: 61, name: "NOTIFICATION PERIOD", code: "NOTIFICATION_PERIOD"},
      ],
      label: "Notification Type",
      hidden: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.type_id_notification_type) ? data.type_id_notification_type : '',
      className: 'half'
    },
    days_before_expiry: {
      field: "input",
      label: "Send Notification Days Before Expiry",
      required: true,
      validation: "integer|required|min:1|max:90",
      initial_value: (Object.entries(data).length > 0 && data.days_before_expiry !== null) ? data.days_before_expiry : 1,
      className: 'full'
    },
    days: {
      label: "Notify me every 'X' Days",
      field: "input",
      required: true,
      validation: "integer|required|min:1|max:90",
      initial_value: (Object.entries(data).length > 0 && data.days !== null) ? data.days : 1,
      className: 'full'
    },
    repeat: {
      field: "switch",
      option: [{value: 0}, {value: 1}],
      label: "Repeat Notification until expired",
      required: false,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.repeat : 0,
      className: "full",
    },
  };
};