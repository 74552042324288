const stateReducer = (state, action) => {
  switch (action.type) {
    case "STATE":
      return {
        ...state,
        state: action.state,
      }
    case "STATE_UPDATED":
      return {
        ...state,
        update_state: action.update_state,
      }
    default:
      return state;
  }
}

export default stateReducer;