import React, {useEffect, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import UserNotificationForm from "./Form/UserNotificationForm";
import NotificationUser from "../../stores/action/User/NotificationUser";
import {useStore} from "../../stores/store";
import CustomGrid from "../../containers/Grid/CustomGrid";
import UserNotificationItems from "./UserNotificationItems";
import CustomBox from "../../containers/Box/CustomBox";
import UserPersonnelItems from "../Personnel/UserPersonnelItems";
import PersonnelForm from "../Personnel/Form/PersonnelForm";
import Loading from "../UI/Loading/Loading";
import TeamNotificationItems from "./TeamNotificationItems";
import TeamNotificationForm from "./Form/TeamNotificationForm";

const TeamNotification = (props) => {
  const {uid, crudAllowed, accountHolder} = props;
  const {state, dispatch} = useStore();
  const [notificationData, setNotificationData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    NotificationUser(dispatch, uid, true);
  }, [state.global_update]);

  useEffect(() => {
    if (state.user_data.update_notification_team) {
      setNotificationData(state.user_data.notification_team);
      dispatch({type: "NOTIFICATION_TEAM_UPDATED", update_notification_team: false});
      setLoading(false);
    }
  }, [state.user_data.update_notification_team]);

  return (
    <Box component={'div'} p={3}>
      {loading && <Loading/>}
      {(notificationData && notificationData.length > 0) ?
          notificationData.map((fields, id) => {
            return <CustomGrid key={id}>
              <TeamNotificationItems fields={fields} id={fields.id} crudAllowed={crudAllowed} accountHolder={accountHolder}/>
            </CustomGrid>
          }) :
        !loading && <TeamNotificationForm data={notificationData} crudAllowed={crudAllowed} accountHolder={accountHolder}/>
      }
    </Box>
  );
};

export default TeamNotification;