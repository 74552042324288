const countryReducer = (state, action) => {
  switch (action.type) {
    case "COUNTRY":
      return {
        ...state,
        country: action.country,
      }
    case "COUNTRY_UPDATED":
      return {
        ...state,
        update_country: action.update_country,
      }
    default:
      return state;
  }
}

export default countryReducer;