import React, {} from 'react';
import {Typography} from "@material-ui/core";
import CustomCard from "../../containers/Card/CustomCard";
import CustomBox from "../../containers/Box/CustomBox";
import EmergencyContactEdit from "./EmergencyContactEdit";
import AccordionForm from "../../containers/Accordion/AccordionForm";
import CustomGrid from "../../containers/Grid/CustomGrid";
import RenderValue from "../Render/RenderValue";
import moment from "moment";

const header = (fields) => {

  return (
    <CustomGrid auto={true} align={'left'}>
      <CustomBox direction={'column'}>
        <Typography variant={'body2'}>
          Priority: <RenderValue id={fields.priority} type={'priority'}/>
        </Typography>
        <Typography variant={'body2'}>Relationship: {fields.relationship.name}</Typography>
        <Typography variant={'body2'}>Name: {fields.information.first_name} {fields.information.last_name} </Typography>
        <Typography variant={'body2'}>Date of Birth: {(fields.information.date_of_birth !== null) ? moment(fields.information.date_of_birth).format("DD/MM/YYYY") : 'Not Provided'}</Typography>
      </CustomBox>
      <CustomBox direction={'column'}>
        <Typography variant={'body2'}>Mobile: {fields.information.mobile}</Typography>
        <Typography
          variant={'body2'}>Phone: {(fields.information.phone !== '' && fields.information.phone !== null) ? fields.information.phone : 'Not Provided'}</Typography>
        <Typography
          variant={'body2'}>Email: {(fields.information.email !== '' && fields.information.email !== null) ? fields.information.email : 'Not Provided'}</Typography>
      </CustomBox>
    </CustomGrid>
  )
}

const EmergencyContactItem = (props) => {
  const {fields, userId, disableField, crudAllowed} = props;

  return (
    <CustomCard
      variant={'outlined'}
    >
      <AccordionForm
        header={header(fields)}
      >
        <EmergencyContactEdit
          data={fields}
          userId={userId}
          disableField={disableField}
          crudAllowed={crudAllowed}
        />
      </AccordionForm>
    </CustomCard>
  )
}

export default EmergencyContactItem;