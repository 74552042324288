import React, {useState} from "react";
import {Skeleton} from "@material-ui/lab";
import {Post} from '../../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../../Form/Form";
import FieldBuilder from "../../../Form/Fields/FieldBuilder";
import Validation from '../../../../stores/action/Form/Validation';
import GroupSubmit from "../../../UI/Button/Group/GroupSubmit";
import {Update} from "../../../../stores/action/Form/Update";
import {useStore} from "../../../../stores/store";
import CompetencyCompetencyCategoryFields from "./CompetencyCompetencyCategoryFields";

function setFields(fields, formik) {
  return <>
    {
      fields && Object.entries(fields).map(([fieldKey, _field]) => {
        return (
          <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        )
      })
    }
  </>;
}

function getSubmitText(id) {
  return id && id !== "create" ? 'Update' : 'Save';
}

const CompetencyCompetencyCategoryForm = (props) => {

  let history = useHistory();
  const {id, data} = props
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (id && id !== "create") ? 'api/v1/competency-competency-category/' + id : 'api/v1/competency-competency-category/';
  const fields = CompetencyCompetencyCategoryFields(data);
  const customCompetencyCompetencyCategoryFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history));
  const formik = Validation(fields, customCompetencyCompetencyCategoryFunc);


  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {setFields(fields, formik)}
      {
        fields && Object.entries(fields).length > 0
          ? <GroupSubmit
            className={'GroupSubmit'} reset={false} auto={false}
            right={true} submitText={getSubmitText(id)} formik={formik}
          />
          : <Skeleton variant="rect" width={'100%'}/>
      }

    </Form>
  )
}

export default CompetencyCompetencyCategoryForm;