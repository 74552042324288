const positionReducer = (state, action) => {
  switch (action.type) {
    case "POSITION":
      return {
        ...state,
        position: action.position,
      }
    case "POSITION_UPDATED":
      return {
        ...state,
        update_position: action.update_position,
      }
    default:
      return state;
  }
}

export default positionReducer;