import axiosApiInstance from "../../../helpers/axiosConfig";

const UserCompletions = (dispatch, uid) => {
  const endpoint = (uid) ? "api/v1/user/completions/" + uid : "api/v1/user/completions";
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "USER_COMPLETIONS", user_completions: res.data});
      dispatch({type: "USER_COMPLETIONS_UPDATED", user_completions_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default UserCompletions;