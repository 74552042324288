import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 0)
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

export default function FallbackAvatars() {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Avatar src="/broken-image.jpg" className={classes.large} color="primary.main" onClick={() => window.location = "/profile"}/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5">{'Name here'}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2">{'Position Here'}</Typography>
      </Box>
    </div>
  );
}
