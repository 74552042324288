import React from 'react';
import Button from '@material-ui/core/Button';

const ResetButton = (props) => {
    return (
        <Button
            className={'reset'}
            fullWidth
            variant="contained"
            type="reset"
            size="large"
        >
            {props.children}
        </Button>
    );
}

export default ResetButton;
