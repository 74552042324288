import React from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormControl, InputLabel, Typography} from "@material-ui/core";

export default function DateRangeField(props) {
    const {
        className,
        id,
        title,
        variant,
        margin,
        disabled,
        required
    } = props;

    const [selectedFromDate, setselectedFromDate] = React.useState(new Date());
    const [selectedToDate, setselectedToDate] = React.useState(new Date());

    const handleFromDateChange = (date) => {
        setselectedFromDate(date);
    };

    const handleToDateChange = (date) => {
        setselectedToDate(date);
    };

    return (
        <FormControl className={className + ' daterange'} variant={variant} required={required} margin={margin}>
            <Typography variant="label" >{title}</Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                    inputVariant={variant}
                    id={'selected_from_date'}
                    required={required}
                    label={'From'}
                    disablePast
                    format="MM/dd/yyyy"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'From change',
                    }}
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputVariant={variant}
                    id={'selected_to_date'}
                    required={required}
                    label={'To'}
                    disablePast
                    format="MM/dd/yyyy"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'To change',
                    }}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    )
}