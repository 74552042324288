
function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');

    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

export function stringReplace(string, action='', cleanUp = false) {

    let cleanString = string;

    if (cleanUp && string !== '' && typeof (string) == 'string') {
        cleanString = string.replace(/[^a-zA-Z ]/g, " ");
    }

    switch(action) {
        case 'uppercase':
            return (typeof (cleanString) == 'string') ? cleanString.toUpperCase() : cleanString;
        case 'capitalize':
            return (typeof (cleanString) == 'string') ? titleCase(cleanString) : cleanString;
        case 'lowercase':
            return (typeof (cleanString) == 'string') ? cleanString.toLowerCase() : cleanString;
        default:
            return cleanString
    }
}