import React from "react";

import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Typography, Avatar, Chip,
} from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";

export default function CustomCard(props) {
  const {title, action, subheader, avatar, className, variant} = props;
  let titleHeader = '';
  let subHeader = '';
  let actionButton = '';

  if (action) {
    actionButton = <CardActions className={'right'}><Divider/> {action}</CardActions>;
  }

  if (title) {
    titleHeader = <Typography variant='caption'>{title}</Typography>;
  }

  if (subheader) {
    subHeader = <Typography variant='body2'>{subheader}</Typography>;
  }

  return (
    <Card className={'flex ' + className} variant={variant}>
      {
        (titleHeader) && <CardHeader
          title={titleHeader}
          subheader={subHeader}
          avatar={avatar}
        >
        </CardHeader>
      }

      <CardContent>
        {props.children}
      </CardContent>
      {actionButton}
    </Card>
  );
}