
const SetAlertMessage = (error, state) => {

  let defaultMessage = {
    type: "error",
    message: "Something is not correct please try again..."
  }

  if (typeof error !== 'undefined' && typeof error.status !== 'undefined') {
    defaultMessage = {
      type: (error.status === 200)?'success':'error',
      message: error.data.message
    }
  }

  if (typeof error !== 'undefined' && typeof error.type !== 'undefined') {
    defaultMessage = {
      type: error.type,
      message: error.message
    }
  }

  state(defaultMessage);

}

export default SetAlertMessage;