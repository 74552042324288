import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React from "react";

export default function DateField(props) {
    const {
        className,
        id,
        title,
        variant,
        margin,
        disabled,
        required
    } = props;

    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} className={className}  margin={margin}>
            <KeyboardDatePicker
                inputVariant={variant}
                id={id}
                required={required}
                label={title}
                disabled={disabled}
                disablePast
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': {title} +' change',
                }}
            />
        </MuiPickersUtilsProvider>
    )
}
