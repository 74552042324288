import React, {useEffect} from "react";
import {Box, Typography} from "@material-ui/core";
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import DynamicModal from "../../containers/DataTable/DynamicTable/DynamicModal";
import PersonnelForm from "./Form/PersonnelForm";
import UserPersonnelItems from "./UserPersonnelItems";

const UserPersonnel = (props) => {
  const {uid, personnel, cmid, sectionCrudAllowed} = props

  return (
    <Box component={'div'} p={3}>
      <Box component={'div'} className={'titleBackground primary_color'} >
        <CustomGrid auto={false} alignItems={'center'}>
          <CustomBox className={'noMargin'}>
            <Typography color={'secondary'} variant={'caption'}> {'Personnel'}</Typography>
          </CustomBox>
          <CustomBox className={'noMargin'} align={'right'}>
            {/*{(personnel && personnel.length > 0 && sectionCrudAllowed['USER_PERSONNEL'].crud.create) && <DynamicModal*/}
            {/*  buttonType={'Add'}*/}
            {/*  buttonTitle={'Add New Personnel'}*/}
            {/*  className={'secondary_color'}*/}
            {/*  size={'small'}*/}
            {/*  modalWidth={'md'}*/}
            {/*>*/}
            {/*  <PersonnelForm fields={{}}/>*/}
            {/*</DynamicModal>*/}
            {/*}*/}
          </CustomBox>
        </CustomGrid>
      </Box>
      {
        (personnel && personnel.length > 0) ?
          personnel.map((fields, id) => {
            return <CustomGrid key={id}>
              <UserPersonnelItems fields={fields} uid={uid} cmid={cmid} sectionCrudAllowed={sectionCrudAllowed} />
            </CustomGrid>
          }) :
          <PersonnelForm data={personnel[0]} id={personnel[0].id} uid={uid} cmid={cmid} sectionCrudAllowed={sectionCrudAllowed}/>
      }
    </Box>
  )
}

export default UserPersonnel;