import axiosApiInstance from "../../../helpers/axiosConfig";

const State = (dispatch) => {
    axiosApiInstance
        .get(process.env.REACT_APP_API_DOMAIN + "api/v1/state")
        .then(res => {
            dispatch({type: "STATE", state: res.data});
            dispatch({type: "STATE_UPDATED", update_state: true});
        })
        .catch((error) => {
            console.error(error.message);
        });
};

export default State;