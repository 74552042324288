import React from 'react';

import AppContainer from "../../containers/Container/App/Main";
import CustomGrid from '../../containers/Grid/CustomGrid';
import LastActivity from "../TeamLearning/LastActivity";
import TeamCompetency from "../TeamLearning/TeamCompetencies";

const TeamLearning = () => {
  return (
    <>
      <AppContainer>
        <CustomGrid>
          <LastActivity title={'Last Activity'} />
        </CustomGrid>

        <CustomGrid>
          <TeamCompetency title={'Team Competencies'} />
        </CustomGrid>
      </AppContainer>
    </>
  );
};

export default TeamLearning;
