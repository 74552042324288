import axiosApiInstance from "../../../helpers/axiosConfig";

const Country = (dispatch) => {
    axiosApiInstance
        .get(process.env.REACT_APP_API_DOMAIN + "api/v1/country")
        .then(res => {
            dispatch({type: "COUNTRY", country: res.data});
            dispatch({type: "COUNTRY_UPDATED", update_country: true});
        })
        .catch((error) => {
            console.error(error.message);
        });
};

export default Country;