import React from "react";
import axios from 'axios';
import setAlertMessage from '../Alert/alert';

export const LoginDetails = (fields, setState, endpoint, history) => {
  const url = process.env.REACT_APP_API_DOMAIN + endpoint;
  let fieldsData = {};

  fields && Object.entries(fields).map(([fieldKey]) => {
    fieldsData[fieldKey] = fields[fieldKey]
  })

  axios
    .post(url, fieldsData)
    .then(res => {
      if (typeof res.data.access_token !== 'undefined') {
        localStorage.setItem('token', JSON.stringify(res.data));

        history.push('/dashboard');
      } else {
        const error = {
          type: 'error',
          message: 'Invalid token'
        }

        setAlertMessage(error.response.data, setState);
      }
    })
    .catch((error) => {
      if(typeof error.response !== 'undefined') {
        setAlertMessage(error.response.data, setState);
      } else {
        setAlertMessage(error, setState);
      }
    });
};