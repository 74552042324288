import React, {useEffect} from "react";
import {Box, Typography} from "@material-ui/core";
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import DynamicModal from "../../containers/DataTable/DynamicTable/DynamicModal";
import PersonnelForm from "../Personnel/Form/PersonnelForm";
import UserPersonnelItems from "../Personnel/UserPersonnelItems";
import UserDetails from "../../stores/action/User/UserDetails";
import {useStore} from "../../stores/store";
import PersonnelPosition from "../../stores/action/Personnel/PersonnelPosition";
import PersonnelPositionForm from "./Form/PersonnelPositionForm";
import PersonnelPositionItems from "./PersonnelPositionItems";
import {allowEdit} from "../../helpers/allowEdit";
import jwt_decode from "jwt-decode";

const PersonnelPositions = (props) => {

  const {uid, positions, personnelId, sectionCrudAllowed} = props;

  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  let disable = (uid === decoded.sub);

  return (
    <Box component={'div'} p={3}>
      <Box component={'div'} className={'titleBackground primary_color'}>
        <CustomGrid auto={false} alignItems={'center'}>
          <CustomBox className={'noMargin'}>
            <Typography color={'secondary'} variant={'caption'}> {'Position'}</Typography>
          </CustomBox>
          <CustomBox className={'noMargin'} align={'right'}>
            {(positions && positions.length > 0 && sectionCrudAllowed['USER_PERSONNEL'].crud.create && !disable) ?
              <DynamicModal
                buttonType={'Add'}
                buttonTitle={'Add New Personnel'}
                className={'secondary_color'}
                size={'small'}
                modalWidth={'md'}
              >
                <PersonnelPositionForm data={{}} personnelId={personnelId} sectionCrudAllowed={sectionCrudAllowed}/>
              </DynamicModal>
              : <span></span>
            }
          </CustomBox>
        </CustomGrid>
      </Box>
      {
        (positions && positions.length > 0) ?
          positions.map((position, id) => {
            return <CustomGrid key={id}>
              <PersonnelPositionItems fields={position} personnelId={personnelId} uid={uid}
                                      sectionCrudAllowed={sectionCrudAllowed}/>
            </CustomGrid>
          }) :
          <PersonnelPositionForm
            data={(typeof positions[0] !== 'undefined') ? positions[0] : {}}
            id={(typeof positions[0] !== 'undefined') ? positions[0].id : ''}
            personnelId={personnelId} uid={uid}
            sectionCrudAllowed={sectionCrudAllowed}
          />
      }
    </Box>
  )
};

export default PersonnelPositions;