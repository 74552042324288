import React, {useState} from "react";
import {Post} from "../../stores/action/Form/Post";
import Validation from "../../stores/action/Form/Validation";
import FieldBuilder from "../Form/Fields/FieldBuilder";
import Title from "../UI/Title/Title";

const Address = (props) => {
  const {fields, formik} = props

  return (
    <>
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        })
      }
    </>
  )
}

export default Address;