const teamLastActivityReducer = (state, action) => {
  switch (action.type) {
    case "TEAM_LAST_ACTIVITY":
      return {
        ...state,
        team_last_activity: action.team_last_activity,
      }
    case "TEAM_LAST_ACTIVITY_UPDATED":
      return {
        ...state,
        updated_team_last_activity: action.updated_team_last_activity,
      }
    default:
      return state;
  }
}

export default teamLastActivityReducer;