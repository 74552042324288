import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const Role = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/role")
    .then(res => {
      dispatch({type: "ROLE", role: res.data});
      dispatch({type: "ROLE_UPDATED", update_role: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default Role;