import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import {stringReplace} from "../../../../helpers/stringReplace";
import Option from "../../../../stores/action/Form/Option";

export default function SelectField(props) {
  const {name, id, label, variant, option, required, value, className, disabled, formik, limiter, exclude} = props;
  const [selectedOption, setSelectedOption] = useState("");
  let optionCollection = [];

  optionCollection = Option(option, limiter, exclude)

  useEffect(() => {
    if (optionCollection && Object.entries(optionCollection).length > 0) {
      setSelectedOption((value === 0) ? '' : value);
    }
  }, [optionCollection]);

  useEffect(() => {
    if (value && Object.entries(optionCollection).length > 0) {
      setSelectedOption(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    formik.setFieldValue(id, event.target.value);
  };

  return (
    <FormControl variant={variant} required={required} className={className} >
      <InputLabel id={id} className={(formik.errors[id])?'Mui-error':''}>{label}</InputLabel>
      <Select id={id} name={name} labelId={id} value={selectedOption} onChange={handleChange} label={label}
              required={required} className={(formik.errors[id])?'Mui-error':''} disabled={disabled}>
        <MenuItem key={0} value=''>
          <em>Please Select One</em>
        </MenuItem>

        {optionCollection && Object.entries(optionCollection).map(([key, optionItem]) => (
          <MenuItem disabled={optionItem.name === 'SAP'} key={optionItem.id+'_'+key} value={optionItem.id} name={optionItem.name}>
            {stringReplace(optionItem.name)}
          </MenuItem>
        ))}
      </Select>

      {
        formik.errors[id] && (
          <Typography component="p" color={'error'} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
            Please select one option.
          </Typography>
        )
      }

    </FormControl>
  );
}
