const personnelReducer = (state, action) => {
  switch (action.type) {
    case "PERSONNEL":
      return {
        ...state,
        personnel: action.personnel,
      }
    case "PERSONNEL_UPDATED":
      return {
        ...state,
        update_personnel: action.update_personnel,
      }
    default:
      return state;
  }
}

export default personnelReducer;