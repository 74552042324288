import React, {useEffect, useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import {Typography} from "@material-ui/core";

export default function CheckboxField(props) {
  const {name, id, label, variant, required, value, className, formik} = props;
  const [defaultValue, setDefaultValue] = useState(!!(value));

  useEffect(() => {
    setDefaultValue(!!(value));
  }, [value]);

  const handleChange = (event) => {
    setDefaultValue(event.target.checked);
    formik.setFieldValue(id, event.target.checked);
  };

  return (
    <>
      <FormControl className={className} required={required}>
        <FormControlLabel
          className={(formik.errors[id]) ? 'Mui-error' : ''}
          variant={variant}
          checked={defaultValue}
          value={defaultValue}
          control={
            <Checkbox
              id={id}
              color="primary"
              onChange={handleChange}
            />
          }
          label={label}
          labelPlacement={'end'}
        />
      </FormControl>
      {
        formik.errors[id] && (
          <Typography component="p" color={'error'} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
            Please select one option.
          </Typography>
        )
      }
    </>
  );
}
