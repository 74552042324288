import React, {useEffect, useState} from 'react';
import AppContainer from "../../containers/Container/App/Main";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import VendorForm from '../Vendor/Form/VendorForm';
import {useStore} from "../../stores/store";
import {setupCrud} from "../../helpers/setupCrud";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '25%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Name",
    setWidth: '25%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "abn",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "ABN",
    setWidth: '10%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "contact_detail_id",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Contact",
    setWidth: '10%',
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const subRow = [
  {id: "full_address", renderType: 'default', align: 'left', disablePadding: false, label: "Address", setWidth: '25%'},
  {id: "location_id", renderType: 'default', align: 'left', disablePadding: false, label: "Location", setWidth: '25%'},
  {id: "website", renderType: 'default', align: 'left', disablePadding: false, label: "Website", setWidth: '20%'},
  {
    id: "external_vendor_identifier",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "External Vendor ID",
    setWidth: '25%'
  },
  {id: "comments", renderType: 'default', align: 'left', disablePadding: false, label: "Comments", setWidth: '100%'},
];

const AdminVendor = (props) => {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const buttonEndpoint = 'api/v1/vendor/';
  let sortBy = "id";
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));
  }, [state.user_data.menu_updated]);

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <DynamicTable
        tableTitle={'Vendors'}
        filter={!!(crud.read)}
        headCells={columnHeader}
        subRow={subRow}
        sortBy={sortBy}
        addButton={!!(crud.create)}
        editButton={!!(crud.update)}
        deleteButton={!!(crud.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<VendorForm/>}
      />
    </AppContainer>
  );
}

export default AdminVendor;