import React from "react";
import AllowNoEndDate from "../../../../stores/action/Form/AllowNoEndDate";

export default function FaceToFaceCompetencyFields(data = {}, personnelId = '') {
  return {
    seperator: {
      field: "seperator",
      label: "Add Competency, Compliance or Certification for this User",
      className: "full seperator",
    },
    personnel_id: {
      field: "input",
      label: "personnel_id",
      required: false,
      hidden: true,
      initial_value: Object.entries(data).length > 0 ? data.personnel_id : personnelId,
    },
    competency_id: {
      field: "autocomplete",
      multi: false,
      option: "competency",
      exclude: Object.entries(data).length > 0 ? '' : 'type_id_competency_type|36',
      label: "Select Competency / Compliance / Certification",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.competency_id : '',
      className: "full"
    },
    valid_from_date: {
      field: "date",
      label: "Valid From",
      required: true,
      validation: "date|required",
      past: false,
      initial_value: Object.entries(data).length > 0 ? data.valid_from_date : '',
    },
    valid_to_date: {
      field: "date",
      label: "Valid To",
      required: !AllowNoEndDate(data.competency_id, 'competency'),
      past: false,
      validation: (AllowNoEndDate(data.competency_id, 'competency')) ? "date|nullable" : "date|required",
      initial_value: Object.entries(data).length > 0 ? data.valid_to_date : '',
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        {id: 1, name: "Active", code: "ACTIVE"},
        {id: 5, name: "Pending", code: "PENDING"},
        {id: 2, name: "Inactive", code: "INACTIVE"},
        {id: 3, name: "Archived", code: "ARCHIVED"},
        {id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED"},
      ],
      label: "Status",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: "full"
    },
    message: {
      field: "textarea",
      label: "Comments",
      initial_value: (Object.entries(data).length > 0 && data.message !== null) ? data.message : '',
      className: "full"
    },
  };
};