import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

//Material Cores
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

//Material Icons
import ListItem from "@material-ui/core/ListItem";
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ReportIcon from '@material-ui/icons/Report';
import SecurityIcon from '@material-ui/icons/Security';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BusinessIcon from '@material-ui/icons/Business';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useStore} from "../../../../stores/store";

//Custom Values
const renderSwitch = (param) => {
  switch(param) {
    case 'My Learning':
      return <DashboardIcon />;
    case 'My Profile':
      return <PersonIcon />;
    case 'People@GEMCO':
      return <GroupIcon />;
    case 'My Team':
      return <GroupWorkIcon />;
    case 'Competencies':
      return <BallotIcon />;
    case 'Personnel':
      return <HowToRegIcon />;
    case 'Vendors':
      return <BusinessIcon />;
    case 'Organisations':
      return <LocationCityIcon />;
    case 'Roles':
      return <AssignmentIndIcon />;
    case 'Reports':
      return <ReportIcon />;
    case 'Admin Setting':
      return <SecurityIcon />;
    case 'MobiliseMe':
      return <ArrowBackIcon />;
    default:
      return <GroupIcon />;
  }
}

export default function NavigationItem(props) {
  let history = useHistory();
  const [active, setActive]= useState(false);
  const {state} = useStore();

  const clickHandler = () => {
    if(props.link.includes('https') || props.link.includes('http')) {
      window.location = props.link;
    } else {
      history.push(props.link)
    }
    state.search = [];
  }

  useEffect(() => {
    if (history.location.pathname.replace(/[^a-zA-Z ]/g, "") === props.link.replace(/[^a-zA-Z ]/g, "") ) {
      setActive(true);
    }
  }, [history.location.pathname]);

  return (
    <ListItem button selected={active} key={props.children} onClick={clickHandler}>
      <ListItemIcon>{renderSwitch(props.children)}</ListItemIcon>
      <ListItemText className={props.className} primary={props.children}/>
    </ListItem>
  );
}

