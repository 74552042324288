import React from "react";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DynamicModal from "./DynamicModal";

function DynamicTableButton(props) {
  const {
    subRow,
    editButton,
    deleteButton,
    link,
    buttonEndpoint,
    row,
    modalWidth,
    form,
    expandOpen,
    setExpandOpen
  } = props;

  const allowEditDelete = (!((buttonEndpoint.includes('position') || buttonEndpoint.includes('organisation-unit')) && row.type_id_update_type === 27));

  return (
    <>
      {link ? <>
          {editButton &&
          <DynamicModal buttonType={'Link'} buttonTitle={'View this record'} className={'primary_color'} size={'small'}
                        row={row}
                        buttonEndpoint={buttonEndpoint} link={link}/>
          }</>
        :
        <>
          {
            editButton &&
            <DynamicModal buttonType={(allowEditDelete) ? 'Edit' : 'View'} buttonTitle={(allowEditDelete) ? 'Edit' : 'View'} className={'primary_color'} size={'small'}
                        modalWidth={"md"} row={row}
                        buttonEndpoint={buttonEndpoint}>{form}</DynamicModal>
          }
        </>
      }
      {(deleteButton && allowEditDelete) &&
      <DynamicModal buttonType={'Delete'} buttonTitle={'Delete'} className={'cellRed'} size={'small'} row={row}
                    buttonEndpoint={buttonEndpoint} modalWidth={modalWidth}/>}
      {
        subRow && <Tooltip title={expandOpen ? 'Collapse' : 'Expand'}>
          <IconButton aria-label="expand row" size="small" className={'tertiary_color'}
                      onClick={() => setExpandOpen(!expandOpen)}>
            {expandOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton></Tooltip>
      }
    </>
  )
}

export default DynamicTableButton;
