import axiosApiInstance from "../../../helpers/axiosConfig";

const Relationship = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/relationship")
    .then(res => {
      dispatch({type: "RELATIONSHIP", relationship: res.data});
      dispatch({type: "RELATIONSHIP_UPDATED", relationship_update: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default Relationship;