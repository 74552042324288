import React, {useEffect, useState} from "react";
import {Badge, Divider, Grid, Typography, IconButton, Button, Box, Collapse, Tooltip,} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import CustomGrid from "../../Grid/CustomGrid";
import SearchSelectField from "../../../components/Form/SearchFileds/SearchSelectField";
import SearchDateField from "../../../components/Form/SearchFileds/SearchDateField";
import CustomBox from "../../Box/CustomBox";
import DynamicModal from "./DynamicModal";
import SearchAutocompleteField from "../../../components/Form/SearchFileds/SearchAutocompleteField";
import {useStore} from "../../../stores/store";

function setSelectOptions(field, searchType, selectOption, selectLimiter, selectExclude) {
  if (field.searchType.includes('|') || field.selectLimiter || field.selectExclude || field.dateQuery) {
    searchType = field.searchType.split('|')[0];
    selectOption = field.searchType.split('|')[1];

    if (selectOption === 'searchOption' && typeof field.searchOption !== 'undefined') {
      selectOption = field.searchOption;
    }

    selectLimiter = (typeof field.selectLimiter !== 'undefined') ? field.selectLimiter : '';
    selectExclude = (typeof field.selectExclude !== 'undefined') ? field.selectExclude : '';
  }
  return {searchType, selectOption, selectLimiter, selectExclude};
}

function setSelectedDate(fieldsValue, field, _selectDate) {
  let selectedDate = typeof field.assignedValue !== 'undefined' ? field.assignedValue : '';

  if (fieldsValue && Object.entries(fieldsValue).length > 0) {
    Object.entries(fieldsValue).map(([selectDateKey, values]) => {
      if (selectDateKey === field.id) {
        selectedDate = values.value
      }

      return selectedDate;
    });
  }

  return selectedDate;
}

export default function DynamicTableFilter(props) {
  const {tableTitle, filter, addButton, columns, form, search, setSearch} = props;
  const [slide, setSlide] = useState(false);
  const [fieldsValue, setFieldsValue] = useState({});
  const [selectValue, setSelectValue] = useState([search]);
  const [selectDate, setSelectDate] = useState([search]);
  const {state} = useStore();

  useEffect(() => {
    if (state.search[tableTitle] && Object.keys(state.search[tableTitle]).length > 0) {
      setSlide(true);
      setSelectValue(search);
      setSelectDate(search);
      setSearch(state.search[tableTitle]);
    }
  }, []);

  const fieldSwitcher = (_key, field) => {
    let newField;
    let searchType = field.searchType;
    let searchWidth = (typeof (field.searchWidth) !== 'undefined') ? field.searchWidth : 4;
    let selectOption;
    let selectLimiter;
    let selectExclude;

    const __ret = setSelectOptions(field, searchType, selectOption, selectLimiter, selectExclude);
    searchType = __ret.searchType;
    selectOption = __ret.selectOption;
    selectLimiter = __ret.selectLimiter;
    selectExclude = __ret.selectExclude;

    switch (searchType) {
      case "select":
        const fieldDefault = (typeof field.assignedValue !== 'undefined') ? field.assignedValue : '';

        newField = <Grid key={field.id} item xs={6} md={searchWidth}>
          <SearchSelectField
            name={field.id}
            id={field.id}
            label={field.label}
            variant={'outlined'}
            option={
              (selectOption === 'searchOption' && typeof searchOption !== 'undefined')
                ? searchOption : selectOption
            }
            required={false}
            value={
              (typeof fieldsValue[field.id] !== 'undefined' && typeof selectValue !== 'object')
                ? fieldsValue[field.id].value : fieldDefault
            }
            className={'full'}
            limiter={selectLimiter}
            exclude={selectExclude}
            onChangeEvent={onChangeEvent('select')}
            onKeyDown={runSearchEnter}
          />
        </Grid>;
        break
      case "autocomplete":
        newField = <Grid key={field.id} item xs={6} md={searchWidth}>
          <SearchAutocompleteField
            name={field.id}
            id={field.id}
            label={field.label}
            variant={'outlined'}
            option={selectOption}
            required={false}
            className={'full'}
            limiter={selectLimiter}
            exclude={selectExclude}
            value={
              (typeof state.search[tableTitle][field.id] != 'undefined')
                ? state.search[tableTitle][field.id].value : ''
            }
            onChangeEvent={onChangeEvent('autocomplete', field)}
            onKeyDown={runSearchEnter}
          />
        </Grid>;
        break
      case "date":
        newField = <Grid key={field.id} item xs={6} md={searchWidth}>
          <SearchDateField
            name={field.id}
            id={field.id}
            label={field.label}
            variant={'outlined'}
            required={false}
            value={setSelectedDate(fieldsValue, field, selectDate)}
            className={'full'}
            onChangeEvent={onChangeEvent('date', field)}
            onKeyDown={runSearchEnter}
          />
        </Grid>;
        break
      case "type_link":
        newField = <Grid key={field.id} item xs={6} md={searchWidth}>
          <p>Select first the Security Role Type.</p>
          <TextField
            id={field.id}
            label={field.label}
            fullWidth
            onChange={onChangeEvent('type_link')}
            variant={'outlined'}
            onKeyDown={runSearchEnter}
          />

        </Grid>;
        break
      default:

        newField = <Grid key={field.id} item xs={6} md={searchWidth}>
          <TextField
            id={field.id}
            label={field.label}
            onChange={onChangeEvent('text')}
            value={field.value}
            variant={'outlined'} fullWidth
            onKeyDown={runSearchEnter}
          />
        </Grid>;
    }

    return newField;
  }

  const runSearch = () => {
    setSearch(fieldsValue);
  };

  const runSearchEnter = (event) => {
    if (event.key === 'Enter') {
      setSearch(fieldsValue);
    }
  }

  const onChangeEvent = (type, field = {}) => (event) => {

    switch (type) {
      case "select":
        setFieldsValue({
          ...fieldsValue,
          [event.target.name]: {'row': event.target.name, 'value': event.target.value, 'search': '='}
        });

        setSelectValue(event.target.value);
        break;
      case "autocomplete":
        setFieldsValue({
          ...fieldsValue,
          [field.id]: {'row': field.id, 'value': event.id, 'search': '='}
        });

        break;
      case "date":
        let dateQuery = (typeof field.dateQuery !== 'undefined') ? field.dateQuery : '>';

        setFieldsValue({
          ...fieldsValue,
          [field.id]: {'row': field.id, 'value': (event !== null) ? event.toISOString() : '', 'search': dateQuery}
        });

        break;
      default:
        setFieldsValue({
          ...fieldsValue,
          [event.target.id]: {'row': event.target.id, 'value': '%' + event.target.value + '%', 'search': 'like'}
        })
    }
  };

  const buildFields = () => {
    let fields = [];

    columns.map((field, key) => {
      if (typeof field.searchable !== 'undefined' && field.searchable && typeof field.searchType !== 'undefined') {
        fields[key] = fieldSwitcher(key, field);
      }
    });

    return fields;
  };

  const handleChange = () => {
    setSlide(!slide);

    if (search && Object.keys(search).length > 0) {
      setSearch({});
    }
  };

  useEffect(() => {
    state.search[tableTitle] = search;
  }, [search]);

  return (
    <Box className={'searchFilter'}>
      <CustomGrid auto={false}>
        <CustomBox>
          <Typography variant={"h6"}> {tableTitle}</Typography>
        </CustomBox>
        <CustomBox align={'right'}>
          {
            (filter) && <Tooltip title={(!slide) ? 'Filter Records' : 'Close Filter'}>
              <IconButton className={(!slide) ? 'primary_color' : 'cellRed'} onClick={handleChange}>
                <Badge overlap="rectangular">
                  {(!slide) ? <FilterListIcon/> : <HighlightOffIcon/>}
                </Badge>
              </IconButton>
            </Tooltip>
          }
          {
            addButton && <DynamicModal
              buttonType={'Add'}
              buttonTitle={tableTitle ? 'Add New ' + tableTitle : 'Add New Record'}
              className={'primary_color'}
              size={'small'}
              modalWidth={'md'}
            >
              {form}
            </DynamicModal>
          }
        </CustomBox>
      </CustomGrid>
      {
        (filter && slide) &&
        <Collapse
          in={slide}
          style={{transformOrigin: '0 0 0'}}
          {...(slide ? {timeout: 1000} : {})}
        >
          <Divider/>
          <Box pt={1} pb={1} mt={1} mb={1}>
            <Grid container spacing={1}>
              {buildFields()}
              <Grid item xs={6} md={4} container spacing={3}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={"primary"}
                    type="submit"
                    size="large"
                    onClick={runSearch}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      }
    </Box>
  );
}
