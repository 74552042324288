import React, {useEffect, useState} from 'react';

//Custom Components
import AppContainer from "../../containers/Container/App/Main";
import CustomGrid from '../../containers/Grid/CustomGrid';
import CustomCard from "../../containers/Card/CustomCard";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import CompanyLogo from "./CompanyLogo/CompanyLogo";
import {Divider} from "@material-ui/core";
import CustomBox from "../../containers/Box/CustomBox";
import SystemFont from "./SystemFont/SystemFont";
import SystemColors from "./SystemColors/SystemColors";
import SystemBackground from "./SystemBackground/SystemBackground";
import UserDetails from "../../stores/action/User/UserDetails";
import SystemSettings from "../../stores/action/System/Settings";
import {useStore} from "../../stores/store";
import {useHistory} from "react-router-dom";

const AdminSettings = () => {
    const {state, dispatch} = useStore();
    const [initialValues, setInitialValues] = useState({});
    let history = useHistory();

    useEffect(() => {
        SystemSettings(dispatch);
    }, []);

    useEffect(() => {
        if (state.system_setting.settings_updated) {
            dispatch({type: "SYSTEM_UPDATED", settings_updated: false});
            setInitialValues(state.system_setting.system_setting);
        }
    }, [state.system_setting.settings_updated]);

    return (
        <AppContainer>
            <CustomGrid>
                <CustomCard
                    title={'Company Details'}
                >
                    <CustomBox>
                        {/*<CompanyLogo />*/}
                        {/*<Divider orientation={'vertical'} />*/}
                        <CompanyDetails fullwidth fields={initialValues.company_details} />
                    </CustomBox>
                </CustomCard>
            </CustomGrid>
            {/*<CustomGrid>*/}
            {/*    <CustomCard>*/}
            {/*        <SystemFont/>*/}
            {/*    </CustomCard>*/}
            {/*    <CustomCard>*/}
            {/*        <SystemColors />*/}
            {/*    </CustomCard>*/}
            {/*    <CustomCard>*/}
            {/*        <SystemBackground/>*/}
            {/*    </CustomCard>*/}
            {/*</CustomGrid>*/}
        </AppContainer>
    );
};

export default AdminSettings;