const organisationUnitReducer = (state, action) => {
  switch (action.type) {
    case "ORGANISATION_UNIT":
      return {
        ...state,
        organisation_unit: action.organisation_unit,
      }
    case "ORGANISATION_UNIT_UPDATED":
      return {
        ...state,
        update_organisation_unit: action.update_organisation_unit,
      }
    default:
      return state;
  }
}

export default organisationUnitReducer;