import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputLabel, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import MomentUtils from "@date-io/moment";

export default function DateRangeField (props) {
  const { label, variant, className, dateFields, dates, disabled } = props;

  const [colSize, setColSize] = useState();
  const [disablePastFrom, setDisablePastFrom] = useState(true);
  const [disablePastTo, setDisablePastTo] = useState(true);
  const [disable, setDisable] = useState(true);

  const fromDate = Object.entries(dates)[0][1];
  const toDate = Object.entries(dates)[1][1];

  const [minFromDate, setMinFromDate] = useState(moment().toISOString());
  const [minToDate, setMinToDate] = useState(moment().toISOString());

  const [selectedFromDate, setSelectedFromDate] = useState(fromDate.value);
  const [selectedToDate, setSelectedToDate] = useState(toDate);

  useEffect(() => {
    if (fromDate.value === "") {
      setSelectedFromDate(moment.now());
      fromDate.formik.setFieldValue(fromDate.id, moment().format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setSelectedFromDate(moment(fromDate.value).toISOString());
      setMinFromDate(moment(fromDate.value).format("YYYY-MM-DD HH:mm:ss"));
    }

    if (moment(fromDate.value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) {
      setDisablePastFrom(true);
    } else {
      setDisablePastFrom(false);
    }
  }, [fromDate.value]);

  useEffect(() => {
    if (toDate.value === "") {
      setSelectedToDate(null);
    } else {
      setSelectedToDate(moment(toDate.value).toISOString());
      setMinToDate(moment(fromDate.value).add(1, 'd').toISOString());
      setDisable(false);
      setDisablePastTo(false);
    }
  }, [toDate.value]);

  useEffect(() => {
    const numCol = (dateFields && Object.entries(dateFields).length > 0) ? Object.entries(dateFields).length : 0;
    setColSize(12 / numCol)
  }, [dateFields]);

  const handleDateChangeFrom = (date) => {
    if (date) {
      Object.entries(dates)[0][1]['formik'].setFieldValue(Object.entries(dates)[0][1]['id'], date.format("YYYY-MM-DD HH:mm:ss"));
      setSelectedFromDate(date.format("YYYY-MM-DD HH:mm:ss"));
      setMinToDate(date.add(1, 'd').format("YYYY-MM-DD HH:mm:ss"));
      setDisable(false);
    }
  };

  const handleDateChangeTo = (date) => {
    if (date) {
      setSelectedToDate(date.format("YYYY-MM-DD HH:mm:ss"));
      Object.entries(dates)[1][1]['formik'].setFieldValue(Object.entries(dates)[1][1]['id'], date.format("YYYY-MM-DD HH:mm:ss"));
    } else {
      Object.entries(dates)[1][1]['formik'].setFieldValue(Object.entries(dates)[1][1]['id'], '');
    }
  };

  return (
    (dates) && <FormControl className={className + " daterange"} variant={variant}>
      <Typography component={"legend"} className={"MuiFormLabel-root"}>
        {label}
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={colSize}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale('en-au')}>
            <KeyboardDatePicker
              className={className}
              inputVariant={'outlined'}
              id={Object.entries(dates)[0][0]}
              fullWidth={true}
              name={Object.entries(dates)[0][0]}
              required={Object.entries(dates)[0][1]['required']}
              label={Object.entries(dates)[0][1]['label']}
              minDate={minFromDate}
              disablePast={disablePastFrom}
              disabled={fromDate.disabled}
              format="DD/MM/YYYY"
              value={selectedFromDate}
              onChange={handleDateChangeFrom}
              KeyboardButtonProps={{
                'aria-label': {label} + ' change',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={colSize}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale('en-au')}>
            <KeyboardDatePicker
              className={className}
              inputVariant={'outlined'}
              id={Object.entries(dates)[1][0]}
              fullWidth={true}
              name={Object.entries(dates)[1][0]}
              required={Object.entries(dates)[1][1]['required']}
              label={Object.entries(dates)[1][1]['label']}
              format="DD/MM/YYYY"
              value={(selectedToDate !== 'Invalid date') ? selectedToDate : null}
              onChange={handleDateChangeTo}
              KeyboardButtonProps={{
                'aria-label': {label} + ' change',
              }}
              disabled={toDate.disabled}
              minDate={minToDate}
              disablePast={disablePastTo}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </FormControl>
  )
}

