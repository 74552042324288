import React, {useEffect, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import {useStore} from "../../stores/store";
import UserCompletions from "../../stores/action/User/UserCompletions";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import {Skeleton} from "@material-ui/lab";

const columnHeader = [
  {
    id: "competency_name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Competency",
    setWidth: '19%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "competency_code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "valid_from_date",
    renderType: 'date',
    align: 'left',
    disablePadding: false,
    label: "Effective Date",
    setWidth: '11%'
  },
  {
    id: "valid_to_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "Expiry",
    setWidth: '11%'
  },
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  // {
  //   id: "message",
  //   renderType: 'default',
  //   align: 'left',
  //   disablePadding: false,
  //   label: "Notes",
  //   setWidth: '19%'
  // }
];

const RecentCompetencies = (props) => {
  const {uid} = props
  const {state, dispatch} = useStore();
  const [userIsAllowed, setUserIsAllowed] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const buttonEndpoint = 'api/v1/completion/';
  let sortBy = "valid_from_date";

  useEffect(() => {
    UserCompletions(dispatch, uid);
  }, [state.global_update]);

  useEffect(() => {
    if (state.user_data) {
      setUserIsAllowed(true);
    }

    if(state.user_data.user_completions === '' || state.user_data.user_completions.length > 0){
      setShowTable(true);
    }
  }, [state.user_data]);

  const dataLimiter = [{
    column: 'status_id',
    equation: '=',
    value: 1
  }]

  return (
    <Box mt={1}>
      {
        showTable &&
        <>
          <Typography variant={"h6"}>My current competencies and compliances</Typography>
          <DynamicTable
            maxHeight={'35VH'}
            filter={false}
            headCells={columnHeader}
            sortBy={sortBy}
            addButton={false}
            editButton={false}
            deleteButton={false}
            buttonEndpoint={buttonEndpoint}
            modalWidth={'md'}
            user_id={uid}
            dataLimiter={dataLimiter}
          />
        </>
      }
    </Box>
  );
}
;

export default RecentCompetencies;
