import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import {Snackbar} from "@material-ui/core";

export default function TransitionAlerts({notification, state, modalOpen, setModalOpen}) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    if (open) { setOpen(false) };
    if (modalOpen) { setModalOpen(false) };
    state({});
  };

  return (
    <Snackbar open={open} autoHideDuration={1500} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center' }}>
      <Alert variant="filled" onClose={handleClose} severity={notification.type}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
}