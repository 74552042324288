import axiosApiInstance from "../../../helpers/axiosConfig";

const TeamCompetency = (dispatch, uid) => {
  const endpoint = (uid) ? "api/v1/my-teams-learning/activity-all-users/" + uid : "api/v1/my-teams-learning/activity-all-users";

  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "TEAM_COMPETENCY", team_competency: res.data});
      dispatch({type: "TEAM_COMPETENCY_UPDATED", updated_team_competency: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default TeamCompetency;