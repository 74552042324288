import React, {useState} from "react";

export default function PositionFields(data = {}) {

  const allowEdit = (Object.entries(data).length > 0 && data.type_id_update_type === 27);

  return {
    external_position_identifier: {
      field: "input",
      label: "External Position Identifier",
      required: true,
      disable: allowEdit,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0 && data.external_position_identifier !== null) ? data.external_position_identifier : '',
      className: 'full'
    },
    name: {
      field: "input",
      label: "Name",
      required: true,
      disable: allowEdit,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0 && data.name !== null) ? data.name : ''
    },
    code: {
      field: "input",
      label: "Code",
      required: true,
      disable: allowEdit,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0 && data.code !== null) ? data.code : ''
    },
    date_range: {
      field: "date_range",
      label: "Validity Date Range",
      className: "full",
      date_range_fields: {
        valid_from_date:{
          field: "date",
          label: "Valid From",
          required: true,
          validation: "date|required",
          disable: allowEdit,
          past: !(Object.entries(data).length > 0 && data.valid_from_date),
          initial_value: Object.entries(data).length > 0 ?  data.valid_from_date : '',
        },
        valid_to_date:{
          field: "date",
          label: "Valid To",
          required: true,
          validation: "date|required",
          disable: allowEdit,
          past: !(Object.entries(data).length > 0 && data.valid_to_date),
          initial_value: Object.entries(data).length > 0 ? data.valid_to_date : '',
        }
      },
    },
    organisation_unit_id: {
      field: "autocomplete",
      multi: false,
      option: "organisation_unit",
      limiter: 'type_id_organisation_unit_type|34',
      label: "Organisation Unit",
      required: true,
      disable: allowEdit,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.organisation_unit_id !== null) ? data.organisation_unit_id : '',
      className: 'full'
    },
    position_id_parent: {
      field: "autocomplete",
      multi: false,
      option: "position",
      label: "Parent Position",
      required: true,
      disable: allowEdit,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.position_id_parent !== null) ? data.position_id_parent : '',
      className: 'full'
    },
    type_id_update_type: {
      field: "select",
      multi: false,
      option: "types",
      limiter: 'category_id|11',
      label: "Update Type",
      required: true,
      disable: allowEdit,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.type_id_update_type !== null) ? data.type_id_update_type : '',
      className: 'full'
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        {id: 1, name: "Active", code: "ACTIVE"},
        {id: 2, name: "Disabled", code: "DISABLED"},
        {id: 3, name: "Archived", code: "ARCHIVED"},
        {id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED"},
      ],
      label: "Status",
      required: true,
      disable: allowEdit,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: 'full'
    },
  };
};