const accessMenusReducer = (state, action) => {
  switch (action.type) {
    case "MENUS":
      return {
        ...state,
        menus: action.menus,
      }
    case "MENUS_UPDATED":
      return {
        ...state,
        menus_updated: action.menus_updated,
      }
    default:
      return state;
  }
}

export default accessMenusReducer;