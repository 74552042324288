import * as React from 'react';
import {useStore} from "../../stores/store";
import {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";

import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

function priorityValue(id, setValue) {
  switch (id) {
    case 1:
      setValue('First');
      break;
    case 2:
      setValue('Second');
      break;
    case 3:
      setValue('Third');
      break;
    default:
      setValue('');
      break;
  }
}

function changeValue(type, id, setValue, state) {
  switch (type) {
    case 'boolean':
      if (id) {
        setValue(<DoneIcon className={'cellGreen'}/>);
      } else {
        setValue(<ClearIcon className={'cellRed'}/>);
      }
      break;
    case 'priority':
      priorityValue(id, setValue);
      break;
    default:
      const collection = state[type][type];

      if (collection && Object.entries(collection).length > 0) {
        Object.entries(collection).map(([key, item]) => {
          if (collection[key].id === id) {
            setValue(collection[key].name);
          }
        })
      }
      break;
  }
}

const RenderValue = (props) => {
  const {id, type} = props
  const {state} = useStore();
  const [value, setValue] = useState('');

  useEffect(() => {
    changeValue(type, id, setValue, state);
  }, []);

  return (
    <Typography component={'span'} variant={'body2'}>{value}</Typography>
  )
}

export default RenderValue;