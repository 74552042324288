import React from "react";
import setAlertMessage from '../Alert/alert';
import axiosApiInstance from "../../../helpers/axiosConfig";

export const Delete = (dispatch, id, endpoint, setState, history) => {
  const url = process.env.REACT_APP_API_DOMAIN + endpoint + id;

  axiosApiInstance
    .delete(url)
    .then(res => {
      //TO DO
      if (typeof res.data.access_token !== 'undefined') {
        history.push('/');

      } else {
        if (res.status === 200) {
          const msg = {
            type: 'success',
            message: res.data.message
          }
          dispatch({type: "GLOBAL_UPDATE", global_update: true});

          setAlertMessage(msg, setState);
        } else {
          setAlertMessage({type: 'error', message: res.data.message}, setState);
        }
      }
    })
    .catch((error) => {
      setAlertMessage(error.response, setState);
    });
};