import axiosApiInstance from "../../../helpers/axiosConfig";

const CompetencyDetails = (dispatch, id =null) => {

  const endpoint = (id) ? "api/v1/competency/" + id : "api/v1/competency";
  // const endpoint = "api/v1/competency/2/3";

  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "COMPETENCY", competency: res.data});
      dispatch({type: "COMPETENCY_UPDATED", competency_update: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default CompetencyDetails;