import moment from "moment";
import PdfTranscript from "./Transcript/PdfTranscript";
import CsvTranscript from "./Transcript/CsvTranscript";


const TeamsTranscript = (dispatch, uid, setState, version) => {
  const endpoint = (uid)
    ? "api/v1/my-teams-learning/transcript/" + uid
    : "api/v1/my-teams-learning/transcript";
  const apiLink = process.env.REACT_APP_API_DOMAIN + endpoint;
  const filename = "team_transcript_" + moment().format("DD_MM_YYYY_hh_mm_ss") + "." + version;

  if (version === 'pdf') {
    PdfTranscript(apiLink, filename, dispatch, setState);
  } else {
    CsvTranscript(version, apiLink, filename, dispatch, setState);
  }

};

export default TeamsTranscript;