import React from "react";

//Material Cores
import {Box, Container, Paper} from "@material-ui/core";

//Custom Components
import CustomContainer from "../../Container/Container"
import Footer from "../../../components/Footer/Footer";

// import axios from '../../axios';
// import jwt_decode from "jwt-decode";
// var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3RcL2FwaVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2MTA5NDYwOTEsImV4cCI6MTYxMDk0OTY5MSwibmJmIjoxNjEwOTQ2MDkxLCJqdGkiOiJ2S2xqUWVGa29EMUZnR3VrIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwidXNlciI6eyJmaXJzdF9uYW1lIjoiQ2VsbCIsInVzZXJuYW1lIjoiY2VsbC5tZWRpYSIsImxhc3RfbmFtZSI6Ik1lZGlhIiwiZW1haWwiOiJtb2JpbGlzZW1lQGNlbGwtbWVkaWEuY29tIiwicm9sZSI6IlN1cGVyQWRtaW4ifX0.S80gmBHGPV4pwDddgG_c5vqoa8AWCiYA5t1Pgyda5P";
// localStorage.setItem("token", token);
// import LMSButton from "../../components/UI/Button/LMSButton";

export default function AuthContainer(props) {
    return (
        <div className={'mainBackground auth'}>
            <Box component="main" className={'login'}>
                <Box display="flex" justifyContent="center" alignItems="center" className={"loginContainer"}>
                    <Container component="div" maxWidth="sm" align="center">
                        <Paper className={'paper'}>
                            {props.children}
                        </Paper>
                    </Container>
                </Box>
            </Box>
            <Footer/>
        </div>
    )
}