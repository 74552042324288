import React from "react";
import Box from "@material-ui/core/Box";
import DynamicTable from "../../../containers/DataTable/DynamicTable/DynamicTable";
import CompetencyCategoryForm from "../Form/CompetencyCategory/CompetencyCategoryForm";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '30%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Name",
    setWidth: '20%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "competency_id_award",
    renderType: 'render|competency',
    align: 'left',
    disablePadding: false,
    label: "Award",
    setWidth: '12%',
    searchable: true,
    searchType: 'select|competency',
    selectLimiter: 'type_id_competency_type|36'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
]

export default function CompetencyCategoryTable(props) {
  const {disableField, crudAllowed} = props;
  const buttonEndpoint = 'api/v1/competency-category/';
  let sortBy = "name";

  return (
    <Box mt={1} >
      <DynamicTable
        filter={!!(crudAllowed.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        addButton={!!(crudAllowed.create)}
        editButton={!!(crudAllowed.update)}
        deleteButton={!!(crudAllowed.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<CompetencyCategoryForm/>}
      />
    </Box>
  );
}