import axiosApiInstance from "../../../helpers/axiosConfig";

const TeamLastActivity = (dispatch, uid) => {
  const endpoint = (uid) ? "api/v1/my-teams-learning/activity/" + uid : "api/v1/my-teams-learning/activity";

  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "TEAM_LAST_ACTIVITY", team_last_activity: res.data});
      dispatch({type: "TEAM_LAST_ACTIVITY_UPDATED", updated_team_last_activity: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default TeamLastActivity;