import React from 'react';
import DynamicTable from "../../../containers/DataTable/DynamicTable/DynamicTable";
import CompetencyForm from "../Form/CompetencyForm";
import Box from "@material-ui/core/Box";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Name",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "validity_period",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Validity Period",
    setWidth: '10%'
  },
  {
    id: "valid_from_date",
    renderType: 'date',
    align: 'left',
    disablePadding: false,
    label: "Valid From",
    setWidth: '13%'
  },
  {
    id: "valid_to_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "Valid To",
    setWidth: '12%'
  },
  {
    id: "type_id_competency_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Competency Type",
    setWidth: '13%',
    searchable: true,
    searchType: 'select|types',
    selectLimiter: 'category_id|1'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const subRow = [
  {
    id: "evidence_required",
    renderType: 'boolean',
    align: 'left',
    disablePadding: false,
    label: "Evidence Required",
    setWidth: '50%'
  },
  {
    id: "transferable",
    renderType: 'boolean',
    align: 'left',
    disablePadding: false,
    label: "Transferable",
    setWidth: '50%'
  },
  {
    id: "self_enrol_available",
    renderType: 'boolean',
    align: 'left',
    disablePadding: false,
    label: "Self-enrol Available",
    setWidth: '50%'
  },
  {
    id: "waitlist_available",
    renderType: 'boolean',
    align: 'left',
    disablePadding: false,
    label: "Waitlist Available",
    setWidth: '50%'
  },
];

export default function CompetencyTable(props) {
  const {disableField, crudAllowed} = props;
  const buttonEndpoint = 'api/v1/competency/';
  let sortBy = "name";

  return (
    <Box mt={1} >
      <DynamicTable
        filter={!!(crudAllowed.read)}
        headCells={columnHeader}
        subRow={subRow}
        sortBy={sortBy}
        addButton={!!(crudAllowed.create)}
        editButton={!!(crudAllowed.update)}
        deleteButton={!!(crudAllowed.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<CompetencyForm/>}
      />
    </Box>
  );
}
