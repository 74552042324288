import React, {useEffect, useState} from "react";
import { Box} from "@material-ui/core";
import {useStore} from "../../../stores/store";
import FaceToFaceCompetencyForm from "../../Competencies/Form/FaceToFaceCompetencyForm/FaceToFaceCompetencyForm";
import DynamicTable from "../../../containers/DataTable/DynamicTable/DynamicTable";
import UserCompletions from "../../../stores/action/User/UserCompletions";
import jwt_decode from "jwt-decode";

const columnHeader = [
  {
    id: "competency_id",
    renderType: 'render|competency',
    align: 'left',
    disablePadding: false,
    label: "Competency",
    setWidth: '25%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "competency_code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '20%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "valid_from_date",
    renderType: 'date',
    align: 'left',
    disablePadding: false,
    label: "Effective Date",
    setWidth: '11%'
  },
  {
    id: "valid_to_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "Expiry",
    setWidth: '11%'
  },
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    searchable: true,
    searchType: 'select|status',
    selectLimiter: 'id|1,2,5,3,4,10,11',
    setWidth: '10%'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const UserCompetency = (props) => {
  const {uid, personnelId} = props;
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  const {state, dispatch} = useStore();
  const [userIsAllowed, setUserIsAllowed] = useState(false);
  const [crudAllowed, setCrudAllowed] = useState([]);
  const buttonEndpoint = 'api/v1/completion/';
  let sortBy = "id";

  useEffect(() => {
    UserCompletions(dispatch, uid);
  }, [state.global_update]);

  useEffect(() => {
    setUserIsAllowed(uid !== decoded.sub);

    if (typeof state.user_data.menu.settings !== 'undefined') {
      state.user_data.menu.settings.map((tab, _id) => {
        if (tab.code === 'USER-COMPETENCY-ACHIEVEMENT') {
          setCrudAllowed(tab.crud);
        }
      })
    }

  }, [state.user_data.menu_updated]);

  return (
    <Box mt={1} >
      <DynamicTable
        tableTitle={'Competency'}
        filter={!!(crudAllowed.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        addButton={userIsAllowed && !!(crudAllowed.create)}
        editButton={userIsAllowed && !!(crudAllowed.update)}
        deleteButton={userIsAllowed && !!(crudAllowed.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<FaceToFaceCompetencyForm personnelId={personnelId} />}
        user_id={uid}
      />
    </Box>
  );
};

export default UserCompetency;