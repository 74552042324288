import React from "react";
import {FormControl} from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {DropzoneArea} from "material-ui-dropzone";
import FormLabel from "@material-ui/core/FormLabel";


export default function FileUpload(props) {
    const {
        className,
        id,
        title,
        variant,
        margin,
        options,
        multi,
        defaultValue,
        required
    } = props;

    const useStyles = makeStyles(theme => createStyles({
        label: {
            marginBottom: '1rem',
        },
        previewChip: {
            minWidth: 160,
            maxWidth: 210
        },
    }));

    const classes = useStyles();

    return (
        <FormControl className={className} required={required} margin={margin}>
            <FormLabel component="legend" className={classes.label}>
                {title}
            </FormLabel>
            <DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                previewChipProps={{classes: { root: classes.previewChip } }}
                previewText="Selected files"
            />
        </FormControl>
    );
}



