import React from "react";

import setAlertMessage from '../Alert/alert';
import axiosApiInstance from "../../../helpers/axiosConfig";

export const Post = async (dispatch, fields, setState, endpoint, history) => {

  const url = process.env.REACT_APP_API_DOMAIN + endpoint;
  const fieldsData = {};

  fields && Object.entries(fields).map(([fieldKey]) => {
    if (fieldKey !== 'seperator' || fieldKey !== 'textinfo' || fieldKey !== 'date_range') {
      fieldsData[fieldKey] = fields[fieldKey];
    }
  })

  await axiosApiInstance
    .post(url, fieldsData)
    .then(res => {
      if (typeof res.data.access_token !== 'undefined') {
        history.push('/dashboard');
      } else {
        if (res.status === 200) {
          const msg = {
            type: 'success',
            message: res.data.message
          }
          dispatch({type: "GLOBAL_UPDATE", global_update: true});
          setAlertMessage(msg, setState);
        } else {
          const error = {
            type: 'error',
            message: 'Invalid token'
          }
          setAlertMessage(error.response.data, setState);
        }
      }
    })
    .catch((error) => {
      setAlertMessage(error.response, setState);
    });
};