const userReducer = (state, action) => {
  switch (action.type) {
    case "USER_DETAILS":
      return {
        ...state,
        user_details: action.user_details,
      }
    case "USER_DETAILS_UPDATED":
      return {
        ...state,
        user_details_updated: action.user_details_updated
      }
    case "EMERGENCY_CONTACT":
      return {
        ...state,
        emergency_contact: action.emergency_contact,
      }
    case "EMERGENCY_CONTACT_UPDATED":
      return {
        ...state,
        emergency_contact_updated: action.emergency_contact_updated
      }
    case "COMPANY":
      return {
        ...state,
        company: action.company,
      }
    case "COMPANY_UPDATED":
      return {
        ...state,
        company_updated: action.company_updated
      }
    case "USER_MENU":
      return {
        ...state,
        menu: action.menu,
      }
    case "USER_MENU_UPDATED":
      return {
        ...state,
        menu_updated: action.menu_updated
      }
    case "USER_COMPLETIONS":
      return {
        ...state,
        user_completions: action.user_completions,
      }
    case "USER_COMPLETIONS_UPDATED":
      return {
        ...state,
        user_completions_updated: action.user_completions_updated
      }
    case "USER_REQUIREMENTS":
      return {
        ...state,
        user_requirements: action.user_requirements,
      }
    case "USER_REQUIREMENTS_UPDATED":
      return {
        ...state,
        user_requirements_updated: action.user_requirements_updated
      }
    case "USER_TRANSCRIPT":
      return {
        ...state,
        user_transcript: action.user_transcript,
      }
    case "USER_TRANSCRIPT_UPDATED":
      return {
        ...state,
        user_transcript_updated: action.user_transcript_updated
      }
    case "NOTIFICATION_USER":
      return {
        ...state,
        notification_user: action.notification_user,
      }
    case "NOTIFICATION_USER_UPDATED":
      return {
        ...state,
        update_notification_user: action.update_notification_user,
      }
    case "NOTIFICATION_TEAM":
      return {
        ...state,
        notification_team: action.notification_team,
      }
    case "NOTIFICATION_TEAM_UPDATED":
      return {
        ...state,
        update_notification_team: action.update_notification_team,
      }
    default:
      return state;
  }
}

export default userReducer;