import React, {useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function AccordionForm(props) {
  const {header} = props;
  const [expanded, setExpanded] = useState(false);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion className={'customAccordionForm'} elevation={0} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          className={'accordionSummary'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {header}
        </AccordionSummary>
        <AccordionDetails
          className={'accordionDetails'}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}