import moment from "moment";

export function dateFormat(value, type='') {
  let typeToRender = type;

  if (type.includes("|")) {
    typeToRender = type.split("|")[1];
  }

  switch(typeToRender) {
    case 'short':
      return moment(value).format('Do MMMM YYYY');
    case 'long':
      return moment(value).format('Do MMMM YYYY, h:mm:ss a');
    default:
      return moment(value).format('DD/MM/YYYY');
  }
}