import * as React from 'react';
import AppContainer from "../../containers/Container/App/Main";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import OrganisationUnitForm from "../OrganisationUnit/Form/OrganisationUnitForm";
import {useEffect, useState} from "react";
import {setupCrud} from "../../helpers/setupCrud";
import {useStore} from "../../stores/store";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '10%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Name",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "type_id_organisation_unit_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Organisation Type",
    setWidth: '15%',
    searchable: true,
    searchType: 'select|types',
    selectLimiter: 'category_id|3'
  },
  {
    id: "organisation_unit_id_parent",
    renderType: 'render|organisation_unit',
    align: 'left',
    disablePadding: false,
    label: "Parent Organisation",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "type_id_update_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Update Type",
    setWidth: '10%',
    searchable: true,
    searchType: 'select|types',
    selectlimiter: 'category_id||11'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const subRow = [
  {
    id: "parent_external_organisation_unit_identifier",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Parent External Organisation Unit Identifier",
    setWidth: '10%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "external_organisation_unit_identifier",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "External Organisation Unit Identifier",
    setWidth: '10%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "valid_from_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "Valid From",
    setWidth: '10%',
  },
  {
    id: "valid_to_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "Valid To",
    setWidth: '10%',
  },
];

const AdminOrganisationUnit = (props) => {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const buttonEndpoint = 'api/v1/organisation-unit/';
  let sortBy = "id";
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));
  }, [state.user_data.menu_updated]);

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <DynamicTable
        tableTitle={'Organisations'}
        filter={!!(crud.read)}
        headCells={columnHeader}
        subRow={subRow}
        sortBy={sortBy}
        addButton={!!(crud.create)}
        editButton={!!(crud.update)}
        deleteButton={!!(crud.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<OrganisationUnitForm/>}
      />
    </AppContainer>
  );
}

export default AdminOrganisationUnit;