import React, {useEffect, useState} from 'react';
import AppContainer from "../../containers/Container/App/Main";
import ProfileDetails from '../ProfileDetails/ProfileDetails/ProfileDetails';
import EmergencyContacts from '../EmergencyContact/EmergencyContacts';
import SimpleTabs from "../../containers/Tab/SimpleTabs";
import TitleButtons from "../UI/Title/TitleButtons";
import {useStore} from "../../stores/store";
import UserDetails from "../../stores/action/User/UserDetails";
import Loading from "../UI/Loading/Loading";
import jwt_decode from "jwt-decode";
import UserCompetency from "../User/Competency/UserCompetency";
import PersonalDetails from "../ProfileDetails/PersonalDetails/PersonalDetails";
import EmploymentDetails from "../ProfileDetails/PersonalDetails/EmploymentDetails";
import {allowEdit} from "../../helpers/allowEdit";
import UserNotification from "../Notification/UserNotification";
import {allowManager} from "../../helpers/allowManager";
import TranscriptButton from "../Transcript/TranscriptButton";
import {Box, Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const Profile = (props) => {
  const {id} = props
  const {state, dispatch} = useStore();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  const [accountHolder, setAccountHolder] = useState(true);
  const [isManager, setIsManager] = useState(false);
  const [isManagerPosition, setIsManagerPosition] = useState(decoded.is_manager);
  const [crudAllowed, setCrudAllowed] = useState([]);
  const [sectionAllowed, setSectionAllowed] = useState([]);
  let history = useHistory();
  let uid = id ? id : decoded.user.user_detail;
  let newTab = [];
  let newSection = [];

  useEffect(() => {
    UserDetails(dispatch, uid);
  }, [state.global_update]);


  useEffect(() => {

    if (state.user_data.user_details_updated) {
      dispatch({type: "USER_DETAILS_UPDATED", user_details_updated: false});
      setUserData(state.user_data.user_details);
      setLoading(false);
      setIsManagerPosition(decoded.is_manager);
    }

    if (state.user_data.user_details.information) {
      setAccountHolder(state.user_data.user_details.information.user_id === decoded.sub);
      setIsManager(state.user_data.user_details.show_tabs);

      if (typeof state.user_data.menu.settings !== 'undefined') {
        state.user_data.menu.settings.map((tab) => {
          if (tab.menu_id_parent === 4) {
            newTab[tab.code] = tab;
            setCrudAllowed(newTab);
          }
        })
      }

      if (typeof state.user_data.menu.section !== 'undefined') {
        state.user_data.menu.section.map((tab) => {
          if (tab.menu_id_parent === 14 || tab.menu_id_parent === 16) {
            newSection[tab.code] = tab;
            setSectionAllowed(newSection);
          }
        })
      }
    }
  }, [state.user_data.user_details_updated]);

  return (
    <AppContainer>
      <TitleButtons title={"Profile"}>
        {!loading && !accountHolder &&  <Box mt={'5px'} mr={'15px'}><Button key={id} variant={"contained"} color={'primary'} onClick={() => {
          history.push('/admin/users');
        }}> Back </Button></Box>}
        {!loading && !accountHolder && <TranscriptButton id={userData.information.user_id}/>}
      </TitleButtons>
      {loading && <Loading/>}
      {!loading &&
        <SimpleTabs>
          <ProfileDetails
            uid={uid}
            title={'User Details'}
            userInformation={userData.information}
            disableField={!allowEdit(accountHolder, (typeof crudAllowed['USER-DETAILS'] !== 'undefined') ? crudAllowed['USER-DETAILS'].crud.update : 0)}
            visible={
              allowEdit(
                accountHolder,
                allowManager((typeof crudAllowed['USER-DETAILS'] !== 'undefined') ? crudAllowed['USER-DETAILS'].crud.read : 0, isManagerPosition, isManager),
                isManager
              )
            }
          />
          <PersonalDetails
            uid={uid}
            accountHolder={accountHolder}
            title={'Personal Details'}
            userInformation={userData.information}
            disableField={!allowEdit(accountHolder, (typeof crudAllowed['PERSONAL-DETAILS'] !== 'undefined') ? crudAllowed['PERSONAL-DETAILS'].crud.update : 0)}
            visible={
              allowEdit(
                accountHolder,
                allowManager((typeof crudAllowed['PERSONAL-DETAILS'] !== 'undefined') ? crudAllowed['PERSONAL-DETAILS'].crud.read : 0, isManagerPosition, isManager, 'PERSONAL-DETAILS'),
                isManager
              )
            }
            sectionCrudAllowed={sectionAllowed}
          />
          <EmergencyContacts
            title={'Emergency Contacts'}
            emergencyContacts={userData.emergency_contacts}
            userId={userData.information.user_id}
            disableField={!allowEdit(accountHolder, (typeof crudAllowed['EMERGENCY-CONTACTS'] !== 'undefined') ? crudAllowed['EMERGENCY-CONTACTS'].crud.update : 0)}
            visible={
              allowEdit(
                accountHolder,
                allowManager((typeof crudAllowed['EMERGENCY-CONTACTS'] !== 'undefined') ? crudAllowed['EMERGENCY-CONTACTS'].crud.read : 0, isManagerPosition, isManager, 'EMERGENCY-CONTACTS'),
                isManager
              )
            }
          />
          <EmploymentDetails
            title={'Employment Details'}
            uid={userData.information.user_id}
            cmid={userData.information.cm_id}
            workDetail={userData.work_detail}
            positions={userData.work_detail.positions}
            roles={userData.work_detail.roles}
            personnelId={userData.work_detail.personnel_id}
            personnel={userData.work_detail.personnel}
            disableField={!allowEdit(accountHolder, (typeof crudAllowed['EMPLOYMENT-DETAILS'] !== 'undefined') ? crudAllowed['EMPLOYMENT-DETAILS'].crud.update : 0)}
            visible={
              allowEdit(
                accountHolder,
                allowManager((typeof crudAllowed['EMPLOYMENT-DETAILS'] !== 'undefined') ? crudAllowed['EMPLOYMENT-DETAILS'].crud.read : 0, isManagerPosition, isManager, 'EMPLOYMENT-DETAILS'),
                isManager
              )
            }
            sectionCrudAllowed={sectionAllowed}
          />
          <UserCompetency
            title={'Competencies'}
            uid={userData.information.user_id}
            personnelId={userData.work_detail.personnel_id}
            disableField={!allowEdit(accountHolder, (typeof crudAllowed['USER-COMPETENCY-ACHIEVEMENT'] !== 'undefined') ? crudAllowed['USER-COMPETENCY-ACHIEVEMENT'].crud.update : 0)}
            visible={
              allowEdit(accountHolder, (typeof crudAllowed['USER-COMPETENCY-ACHIEVEMENT'] !== 'undefined') ? crudAllowed['USER-COMPETENCY-ACHIEVEMENT'].crud.read : 1)
            }
          />
          <UserNotification
            title={'User Notifications'}
            uid={userData.information.user_id}
            visible={
              allowEdit(
                accountHolder,
                allowManager((typeof crudAllowed['USER-NOTIFICATIONS'] !== 'undefined') ? crudAllowed['USER-NOTIFICATIONS'].crud.read : 0, isManagerPosition, isManager, 'USER-NOTIFICATIONS')
              ) && !accountHolder
            }
            crudAllowed={crudAllowed}
            accountHolder={accountHolder}
          />
        </SimpleTabs>
      }
    </AppContainer>
  );
};

export default Profile;
