import React from "react";

import {ThemeProvider} from "@material-ui/styles";
import apiTheme from "../../stores/action/System/DefaultTheme";
import CssBaseline from "@material-ui/core/CssBaseline";

export default function CustomContainer(props) {

    return (
        <ThemeProvider theme={apiTheme()} >
            <CssBaseline/>
            {props.children}
        </ThemeProvider>
    )
}