const searchReducer = (state, action) => {
  switch (action.type) {
    case "USERS_SEARCH":
      return {
        ...state,
        users_search: action.users_search
      }
    default:
      return state;
  }
}

export default searchReducer;