import React from 'react';

//Material Cores
import {Box, Tooltip, Menu, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//Material Icons
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsIcon from '@material-ui/icons/Settings';

//Custom Components
import NavigationMobile from '../../Navigation/NavigationMobile'

//Custom Values


const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function HeaderBadge(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={menuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => window.location = "/profile"}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle/>
          </IconButton>
          <p>My Profile</p>
        </MenuItem>
        <MenuItem onClick={() => window.location = "/logout"}>
          <IconButton
            aria-label="logout current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <ExitToAppIcon/>
          </IconButton>

          <p>Log out</p>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <div className={classes.grow}/>
        <div className={classes.sectionDesktop}>
          <IconButton color="inherit" onClick={() => window.location = "/settings"}>
            <SettingsIcon/>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle/>
            <Typography m={3} variant={'body2'}>{props.fullName}</Typography>
          </IconButton>

        </div>
        <div className={classes.sectionMobile}>
          <Box display="flex" justifyContent="flex-end">
            <div className={classes.sectionDesktop}>
              <Tooltip title="Logout of the system" placement="left">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => window.location = "/logout"}
                  color="inherit"
                >
                  <ExitToAppIcon/>
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.sectionMobile}>
              <NavigationMobile/>
            </div>
          </Box>
        </div>
        {renderMenu}
      </Box>
    </React.Fragment>
  );
}