import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip, Typography
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import React, {Children, cloneElement, isValidElement, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CustomBox from "../../Box/CustomBox";
import {Delete} from "../../../stores/action/Form/Delete";
import Alert from "../../../components/UI/Alert/Alert";
import {useStore} from "../../../stores/store";
import {useHistory} from "react-router-dom";

export default function DynamicModal(props) {
  const {buttonType, buttonTitle, buttonEndpoint, className, size, row, modalWidth, link} = props;

  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  let history = useHistory();

  const switchButtons = (buttonType = null) => {
    switch (buttonType) {
      case 'Add':
        return <Tooltip title={(!modalOpen) ? buttonTitle : 'Close'}>
          <IconButton className={(!modalOpen) ? className : 'cellRed'} onClick={handleClickModal}>
            <Badge overlap="rectangular">
              {(!modalOpen) ? <AddCircleOutlineIcon/> : <HighlightOffIcon/>}
            </Badge>
          </IconButton>
        </Tooltip>
      case 'Link':
        return <Tooltip title={buttonTitle}>
          <IconButton size={size} className={className} onClick={openLink}>
            <VisibilityIcon/>
          </IconButton>
        </Tooltip>;
      case 'Edit':
        return <Tooltip title={buttonTitle}>
          <IconButton size={size} className={className} onClick={handleClickModal}>
            <EditIcon/>
          </IconButton>
        </Tooltip>
      case 'View':
        return <Tooltip title={buttonTitle}>
          <IconButton size={size} className={className} onClick={handleClickModal}>
            <VisibilityIcon/>
          </IconButton>
        </Tooltip>
      case 'Delete':
        return <Tooltip title={buttonTitle}>
          <IconButton size={size} className={className} onClick={handleClickModal}>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
      default:
        return <Button variant={"contained"} className={className} onClick={handleClickModal}>{buttonTitle}</Button>
    }
  }

  const switchRender = (buttonType = null, output = null) => {
    switch (output) {
      case 'button':
        return switchButtons(buttonType)
      default:
        return <Button variant={"contained"} className={className} onClick={handleClickModal}>{buttonTitle}</Button>
    }
  }

  const switchTitle = (buttonType = null) => {
    switch (buttonType) {
      case 'Add':
        return <CustomBox className={'noMargin'}>
          <Typography variant={'h6'}>{'Add new'}</Typography>
          <IconButton className={'cellRed'} component="span" onClick={handleClickClose} >
            <CancelIcon />
          </IconButton>
        </CustomBox>
      case 'Edit':
        return <CustomBox className={'noMargin'}>
          <Typography variant={'h6'}>
            {'Update the details below and click save to update this record.'}</Typography>
          <IconButton className={'cellRed'} component="span" onClick={handleClickClose} >
            <CancelIcon />
          </IconButton>
        </CustomBox>
      case 'View':
        return <CustomBox className={'noMargin'}>
          <Typography variant={'h6'}>
            {'This record is not editable.'}</Typography>
          <IconButton className={'cellRed'} component="span" onClick={handleClickClose} >
            <CancelIcon />
          </IconButton>
        </CustomBox>
      case 'Delete':
        return <CustomBox direction={'row'} align={'left'}>
          <Typography variant={'body2'}>{'Are you sure you want to delete this record?'}</Typography>
        </CustomBox>
      default:
        return <CustomBox direction={'row'} align={'left'}>
          <Typography component={'p'}>{''}</Typography>
          <IconButton className={'cellRed'} component="span" onClick={handleClickClose} >
            <CancelIcon />
          </IconButton>
        </CustomBox>
    }
  }

  const openLink = () => {
    history.push(link);
  };

  const handleClickClose = () => {
    setModalOpen(false);
  };

  const handleClickModal = () => {
    setModalOpen(true);
  };

  const handleDeleteRecord = () => {
    Delete(dispatch, row.id, buttonEndpoint, setstateLocal)
  };

  return (
    <>
      {switchRender(buttonType, 'button')}
      <Dialog fullScreen={(buttonType === 'Edit' || buttonType === 'Add' || buttonType === 'View') ? true : false}
              open={modalOpen}
              onClose={handleClickClose}
              maxWidth={(modalWidth) ? modalWidth : 'sm'}
              className={'modalForm'}
      >
        {
          (stateLocal.message) ? (
            <Alert notification={stateLocal} state={setstateLocal} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
          ) : <>
            <DialogTitle disableTypography={true}>
              {switchTitle(buttonType)}
            </DialogTitle>
            <DialogContent>
              {
                (buttonType === 'Edit' || buttonType === 'Add' || buttonType === 'View' ) && Children.map(props.children, child => {

                  if ( isValidElement(child) && typeof (row) !== 'undefined') {
                    return cloneElement(child, {id: row.id, data: row})
                  }

                  return cloneElement(child);
                })
              }
            </DialogContent>
            {
              (buttonType === 'Delete') &&
              <DialogActions>
                <Button onClick={handleDeleteRecord} color="primary" size="large" variant="contained"
                        disableElevation>
                  YES
                </Button>
                <Button onClick={handleClickClose} size="large" disableElevation variant="contained">
                  NO
                </Button>
              </DialogActions>
            }
          </>
        }
      </Dialog>
    </>
  )
}