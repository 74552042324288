import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const NotificationUser = (dispatch, uid, team = false) => {

  let url = process.env.REACT_APP_API_DOMAIN + 'api/v1/notification/notification-by-user-id';
  if (team) {
    url = process.env.REACT_APP_API_DOMAIN + 'api/v1/notification/team';
  }
  const requestBody = {
    user_id: uid,
  }
  axiosApiInstance
    .post(url, requestBody)
    .then(res => {
      if (team) {
        dispatch({type: "NOTIFICATION_TEAM", notification_team: res.data});
        dispatch({type: "NOTIFICATION_TEAM_UPDATED", update_notification_team: true});
      } else {
        dispatch({type: "NOTIFICATION_USER", notification_user: res.data});
        dispatch({type: "NOTIFICATION_USER_UPDATED", update_notification_user: true});
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default NotificationUser;