const accessLevelPositionReducer = (state, action) => {
  switch (action.type) {
    case "ACCESS_LEVEL_POSITION":
      return {
        ...state,
        access_level_position: action.access_level_position,
      }
    case "ACCESS_LEVEL_POSITION_UPDATED":
      return {
        ...state,
        access_level_position_updated: action.access_level_position_updated,
      }
    default:
      return state;
  }
}

export default accessLevelPositionReducer;