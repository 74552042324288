const relationshipReducer = (state, action) => {
  switch (action.type) {
    case "RELATIONSHIP":
      return {
        ...state,
        relationship: action.relationship,
      }
    case "RELATIONSHIP_UPDATED":
      return {
        ...state,
        relationship_update: action.relationship_update,
      }
    default:
      return state;
  }
}

export default relationshipReducer;