import * as React from 'react';
import {DataGrid, GridOverlay, GridToolbar} from '@material-ui/data-grid';

const filterModel = {
  items: [
    { columnField: 'first_name',
      operatorValue: 'contains',
      value: '' }
  ],
};

function CustomNoRowsOverlay() {
  return (
    <GridOverlay>
      <div>No Latest Activity</div>
    </GridOverlay>
  );
}

export default function DataTable(props) {

  const {rows, columns, pageSize, disableSelect, hideFooter, loading} = props;

  return (

      <DataGrid
        loading={loading}
        autoHeight={true}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        disableSelectionOnClick={disableSelect}
        rowsPerPageOptions={[5, 10, 20, 30]}
        hideFooter={hideFooter}
        // filterModel={filterModel}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay
        }}
        // onPageChange={
        //   (params) => {
        //     console.log(params)
        //   }
        // }
      />


  );
}
