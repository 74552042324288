import React from "react";

export default function ProfileDetailsFields(uid = null, data = {}, disableField = false, crudAllowed = []) {

  //TODO ALLOW south32.gemco to edit first_name and last_name

  return {
    "cm_id": {
      "field": "input",
      "label": "CMID",
      "required": false,
      "disable": true,
      "hidden": true,
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.cm_id : ''
    },
    "user_name": {
      "field": "input",
      "label": "username",
      "required": false,
      "disable": true,
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.user_name : '',
    },
    "first_name": {
      "field": "input",
      "label": "Name",
      "required": true,
      "disable": true,
      "validation": "string|required",
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.first_name : ''
    },
    "middle_name": {
      "field": "input",
      "label": "Middle Name",
      "required": false,
      "validation": "string",
      "disable": disableField,
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.middle_name : ''
    },
    "preferred_name": {
      "field": "input",
      "label": "Preferred Name",
      "required": false,
      "disable": disableField,
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.preferred_name : ''
    },
    "last_name": {
      "field": "input",
      "label": "Last Name",
      "required": true,
      "disable": true,
      "validation": "string|required",
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.last_name : ''
    },
    "previous_last_name": {
      "field": "input",
      "label": "Previous Surname",
      "required": false,
      "disable": disableField,
      "className": "half",
      "initial_value": (Object.entries(data).length > 0) ? data.previous_last_name : ''
    },
    "email_address": {
      "field": "email",
      "label": "Email",
      "required": true,
      "disable": disableField,
      "validation": "string|email|required",
      "initial_value": (Object.entries(data).length > 0) ? data.email_address : '',
      "className": "half"
    },
    "phone": {
      "field": "phone",
      "label": "Phone",
      "required": false,
      "disable": disableField,
      "validation": "integer|phone",
      "initial_value": (Object.entries(data).length > 0) ? data.phone : '',
      "className": "half",
    },
    "mobile": {
      "field": "mobile",
      "label": "Mobile",
      "required": true,
      "disable": disableField,
      "validation": "integer|phone|required",
      "initial_value": (Object.entries(data).length > 0) ? data.mobile : ''
    },
    "gender_id": {
      "field": "select",
      "multi": false,
      "option": "gender",
      "label": "Gender",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.gender_id : ''
    },
    "date_of_birth": {
      "field": "date",
      "label": "Date of Birth",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.date_of_birth : ''
    },
  };
};