import React from "react";

export default function AccessLevelFields(data = {}) {
  return {
    menu_id: {
      field: "select",
      multi: false,
      option: 'menus',
      label: "Menus",
      required: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.menu_id !== null) ? data.menu_id : '',
      className: 'half'
    },
    access_level_id: {
      field: "select",
      multi: false,
      option: 'access_level',
      exclude: 'id|1',
      label: "Security Role",
      required: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.access_level_id !== null) ? data.access_level_id : '',
      className: 'half'
    },
    create: {
      field: "checkbox",
      label: "Add",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.create),
    },
    read: {
      field: "checkbox",
      label: "View",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.read),
    },
    update: {
      field: "checkbox",
      label: "Edit",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.update),
    },
    delete: {
      field: "checkbox",
      label: "Delete",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.delete),
    },
    // status_id: {
    //   field: "select",
    //   multi: false,
    //   option: [
    //     {id: 1, name: "Active", code: "ACTIVE"},
    //     {id: 2, name: "Disabled", code: "DISABLED"},
    //     {id: 3, name: "Archived", code: "ARCHIVED"},
    //     {id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED"},
    //   ],
    //   label: "Status",
    //   required: true,
    //   validation: "integer|required",
    //   initial_value: Object.entries(data).length > 0 ? data.status_id : '',
    //   className: 'full'
    // },
  };
};