import React from "react";
import { useStore } from "../../store";

const Option = (option, limiter = null, exclude = null, ) => {
  const { state } = useStore();
  let keyField;
  let keyValue;
  let keyValueArray = [];
  let options = option;
  let newOptions = [];

  if (typeof (option) === 'string') {
    options = state[option+'_data'][option];
  }

  if (limiter) {
    if (limiter.includes("|")) {
      keyField = limiter.split("|")[0];
      keyValue = limiter.split("|")[1];

      if(keyValue.includes(",")){
        let keyArray = keyValue.split(",");

        keyArray.map((key, id) => {
          keyValueArray[id] = parseInt(key)
        })
      }
    }

    options.map((data, key) => {
      if (data[keyField] === parseInt(keyValue)) {
        newOptions[key] = data;
      }

      if (keyValueArray && keyValueArray.includes(data[keyField])) {
        newOptions[key] = data;
      }
    })

    options = newOptions;
  }

  if (exclude) {
    if (exclude.includes("|")) {
      keyField = exclude.split("|")[0];
      keyValue = parseInt(exclude.split("|")[1]);
    }

    options.map((data, key) => {
      if (data[keyField] !== keyValue) {
        newOptions[key] = data;
      }
    })

    options = newOptions;
  }

  return options;
};

export default Option;
