import {FieldArray, Formik} from "formik";
import React from "react";
import {Button, ButtonGroup, FormControl, Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function CustomFieldsDestination(props) {
  const {variant, required, className, formik} = props;

  const handleDelete = (index) => {
    let items = [];
    let countTest = 0;

    for (let j = 0; j <= formik.values.travel_to.length; j++) {
      if (j !== index && typeof formik.values.travel_to[j] != "undefined") {

        items[countTest] = formik.values.travel_to[j];
        countTest++;
      }
    }

    formik.setFieldValue('travel_to', items);
  };

  return (
    <Formik
      initialValues={{travel_to: formik.values.travel_to}}
      enableReinitialize={true}
    >
      {
        (values) => (
          <FieldArray
            name={'travel_to'}
          >
            {(arrayHelpers) => (
              <>
                {
                  values.values.travel_to && values.values.travel_to.length > 0 ? (
                    values.values.travel_to.map((fields, index) => (
                      <FormControl
                        className={className + ' flexRowAuto'}
                        variant={variant} required={required}
                        key={index}
                      >
                        {/*<CustomDestination {...props} count={index} />*/}
                        <ButtonGroup
                          className={'buttonGroup'}
                          size="small"
                          aria-label="small outlined button group"
                        >
                          {
                            index > 0 && <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                handleDelete(index);
                              }}
                            >
                              <RemoveIcon/>
                            </Button>
                          }

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => arrayHelpers.insert(index, [])}
                          >
                            <AddIcon/>
                          </Button>
                        </ButtonGroup>
                        {
                          formik.errors['travel_to'] && (
                            <div className={'error'}>
                              <Typography
                                component="p"
                                color={'error'}
                                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"
                              >
                                Please select one option.
                              </Typography>
                            </div>
                          )
                        }
                      </FormControl>
                    ))
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => arrayHelpers.insert(0, [0])}
                    >
                      <AddIcon/>
                    </Button>
                  )
                }
              </>)}

          </FieldArray>
        )
      }
    </Formik>
  );
};