import React from "react";

export default function CompetencyCompetencyCategoryFields(data = {}) {
  return {
    competency_id: {
      field: "select",
      multi: false,
      option: "competency",
      exclude: 'type_id_competency_type|36',
      label: "Competency",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.competency_id : '',
    },
    competency_category_id: {
      field: "select",
      multi: false,
      option: "competency_category",
      label: "Competency Category",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.competency_category_id : '',
    },

  };
}