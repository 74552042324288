import React from 'react';
import {Divider, Typography} from "@material-ui/core";
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import CustomCard from "../../containers/Card/CustomCard";
import AccordionForm from "../../containers/Accordion/AccordionForm";
import DynamicValue from "../../containers/DataTable/DynamicTable/DynamicValue";
import PersonnelPositionForm from "./Form/PersonnelPositionForm";

const header = (fields) => {

  return (
    <CustomGrid auto={false}>
      <CustomBox className={'noMargin'} direction={'column'}>
        <Typography variant={'body2'}>
          Position: <DynamicValue type={'render|position'} value={fields.position_id}/>
        </Typography>
        <Typography variant={'body2'}>
          Department: {fields.organisation_unit_id ?
          <DynamicValue type={'render|organisation_unit'} value={fields.organisation_unit_id}/> : 'Not defined'}
        </Typography>
        <Typography variant={'body2'}>
          Leader Once Removed: {fields.top_line_leader ?
          <DynamicValue type={'default'} value={fields.top_line_leader}/>: 'Not defined'}
        </Typography>
        <Typography variant={'body2'}>
          Line Leader: {fields.line_leader ?
          <DynamicValue type={'default'} value={fields.line_leader}/>: 'Not defined'}
        </Typography>
        <Typography variant={'body2'}>
          Start Date: <DynamicValue type={'date'} value={fields.start_date}/>
          {' '}
          End Date: <DynamicValue type={'date|no_expiry'} value={fields.end_date}/>
        </Typography>

      </CustomBox>
      <CustomBox className={'noMargin'} align={'right'}>
        <DynamicValue type={'render|status'} value={fields.status_id}/>
      </CustomBox>
    </CustomGrid>
  )
}

const PersonnelPositionItems = (props) => {
  const {fields, uid, personnelId, sectionCrudAllowed} = props;

  return (
    <CustomCard
      variant={'outlined'}
    >
      <AccordionForm
        header={header(fields)}
        addButton={true}
        title={'Personnel'}
        form={<PersonnelPositionForm
          data={{}} personnelId={personnelId} uid={uid} sectionCrudAllowed={sectionCrudAllowed}
        />}
      >
        <Divider/>
        <PersonnelPositionForm
          data={fields} id={fields.id} uid={uid} personnelId={personnelId}
          sectionCrudAllowed={sectionCrudAllowed}
        />
      </AccordionForm>
    </CustomCard>
  )
}

export default PersonnelPositionItems;