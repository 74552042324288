import React from "react";
import {allowEdit} from "../../../helpers/allowEdit";

export default function PersonalDetailsFields(uid = null, accountHolder, data = {}, disableField = true, crudAllowed = [], sectionCrudAllowed = []) {

  return {
    "cm_id": {
      "field": "input",
      "label": "CMID",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.cm_id : ''
    },
    "user_name": {
      "field": "input",
      "label": "username",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.user_name : '',
    },
    "first_name": {
      "field": "input",
      "label": "Name",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.first_name : ''
    },
    "middle_name": {
      "field": "input",
      "label": "Middle Name",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.middle_name : ''
    },
    "preferred_name": {
      "field": "input",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.preferred_name : ''
    },
    "last_name": {
      "field": "input",
      "label": "Last Name",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.last_name : ''
    },
    "previous_last_name": {
      "field": "input",
      "label": "Previous Surname",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.previous_last_name : ''
    },
    "email_address": {
      "field": "email",
      "label": "Email",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.email_address : ''
    },
    "phone": {
      "field": "phone",
      "label": "Phone",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.phone : ''
    },
    "mobile": {
      "field": "mobile",
      "label": "Mobile",
      "hidden": true,
      "initial_value": (Object.entries(data).length > 0) ? data.mobile : ''
    },
    "seperator2": {
      field: "seperator",
      label: "Personal Information",
      className: "full seperator",
      hidden: (!sectionCrudAllowed['PERSONAL-INFORMATION'].crud.read),
    },
    "gender_id": {
      "field": "select",
      "multi": false,
      "option": "gender",
      "label": "Gender",
      "required": true,
      "validation": "integer|required",
      "initial_value": (Object.entries(data).length > 0) ? data.gender_id : '',
      disable: (!sectionCrudAllowed['PERSONAL-INFORMATION'].crud.update),
      hidden: (!sectionCrudAllowed['PERSONAL-INFORMATION'].crud.read),
    },
    "date_of_birth": {
      "field": "date",
      "label": "Date of Birth",
      "required": true,
      "past": false,
      "future": true,
      "validation": "date|required",
      "initial_value": (Object.entries(data).length > 0) ? data.date_of_birth : '',
      disable: (!sectionCrudAllowed['PERSONAL-INFORMATION'].crud.update),
      hidden: (!sectionCrudAllowed['PERSONAL-INFORMATION'].crud.read),
    },
    "seperator": {
      field: "seperator",
      label: "Residential Address",
      className: "full seperator",
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "address_id": {
      "field": "input",
      "hidden": true,
      "required": false,
      "disable": disableField,
      "initial_value": (Object.entries(data).length > 0) ? data.address.id : ''
    },
    "address_line_1": {
      "field": "input",
      "label": "Unit or Street Number",
      "required": false,
      "validation": "string|nullable",
      "initial_value": (Object.entries(data).length > 0) ?  data.address.address_line_1 : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "address_line_2": {
      "field": "input",
      "label": "Street Name",
      "required": true,
      "validation": "string|required",
      "initial_value": (Object.entries(data).length > 0) ? data.address.address_line_2 : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "suburb": {
      "field": "input",
      "label": "Suburb",
      "required": true,
      "validation": "string|required",
      "initial_value": (Object.entries(data).length > 0) ? data.address.suburb : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "postcode": {
      "field": "input",
      "label": "Postcode",
      "required": true,
      "validation": "integer|required",
      "initial_value": (Object.entries(data).length > 0) ? data.address.postcode : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "state_id": {
      "field": "select",
      "multi": false,
      "option": "state",
      "label": "State",
      "required": true,
      "dynamic": true,
      "validation": "integer|required",
      "initial_value": (Object.entries(data).length > 0) ? data.address.state_id : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
    "country_id": {
      "field": "select",
      "multi": false,
      "option": "country",
      "label": "Country",
      "dynamic": true,
      "required": true,
      "validation": "integer|required",
      "initial_value": (Object.entries(data).length > 0) ? data.address.country_id : '',
      disable: !allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update),
      hidden: (!sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.read),
    },
  };
};