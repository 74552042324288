import React, {useEffect, useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {FormControl, InputLabel} from "@material-ui/core";

export default function SwitchField(props) {
  const {name, id, label, variant, value, className, formik, disabled} = props;
  const [check, setCheck] = useState((value) ? (typeof value === "boolean" ? value : value === 1) : false);

  useEffect(() => {
    setCheck((value) ? (typeof value === "boolean" ? value : value === 1) : false);
  }, [value]);

  const handleChange = (event) => {
    setCheck(event.target.checked);

    formik.setFieldValue(id, (event.target.checked) ? 1 : 0);
  };

  return (
    <FormControl className={className} variant={variant}>
      <FormControlLabel
        control={
          <Switch
            checked={check}
            onChange={handleChange}
            id={id}
            name={name}
          />}
        label={label}
      />
    </FormControl>
  );
}
