import React from "react";

import {TextField} from "@material-ui/core";
import DateField from "../Date/DateField";
import DateRangeField from "../Date/DateRangeField";
import SelectField from "../Select/SelectField";
import AutocompleteField from "../Select/AutocompleteField";
import SwitchField from "../Switch/SwitchField";
import CheckboxField from "../Checkbox/CheckboxField";
import RadioField from "../Radio/RadioField";
import FileUpload from "../FileUpload/FileUpload";
import ColorPickerUi from "../ColorPickerUi/ColorPickerUi";
import CustomFieldArray from "../../CustomField/CustomFieldArray";

function capitalizeFirstLetter(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

export default function BuildField(element, field) {

    let inputElement = null;
    let selectOptions = '';
    let toggleOptions = '';

    let disabled = false;
    let width = 'auto';
    let variant = 'outlined';
    let margin = 'normal';
    let multi = 'false';
    let labelPlacement = 'start';
    let elements = [];

    if (typeof (element.elementConfig) !== 'undefined') {
        if(typeof (element.elementConfig.options) !== 'undefined' && element.elementConfig.options.length > 0) {
            selectOptions = element.elementConfig.options
        }

        if(typeof (element.elementConfig.toggle) !== 'undefined' && element.elementConfig.toggle.length > 0) {
            toggleOptions = element.elementConfig.toggle
        }

    }

    if (typeof (element.elementItems) !== 'undefined' && element.elementItems > 0) {
        elements = element.elementItems
    }

    if (typeof (element.disabled) !== 'undefined' && element.disabled > 0) {
        disabled = element.disabled
    }

    if (typeof (element.margin) !== 'undefined' && element.margin.length > 0) {
        margin = element.margin
    }

    if (typeof (element.multi) !== 'undefined' && element.multi.length > 0) {
        multi = element.multi
    }

    if (typeof (element.width) !== 'undefined' && element.width) {
        width = element.width
    }

    if (typeof (element.labelPlacement) !== 'undefined' && element.labelPlacement) {
        labelPlacement = element.labelPlacement
    }

    switch ( element.elementType ) {
        case ( 'input' ):
            inputElement = <TextField
                className={width}
                disabled={disabled}
                variant={variant}
                margin={margin}

                key={element.name}
                id={element.name}
                name={element.name}
                label={capitalizeFirstLetter(element.label)}
                required={element.required}

                value={field.values[element.name]}
                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'password' ):
            inputElement = <TextField
                className={width}
                disabled={disabled}
                variant={variant}
                margin={margin}

                key={element.name}
                id={element.name}
                type="password"
                name={element.name}
                label={capitalizeFirstLetter(element.label)}
                required={element.required}

                value={field.values[element.name]}
                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'textarea' ):
            inputElement = <TextField
                className={width}
                disabled={disabled}
                multiline
                rows={4}
                variant={variant}
                margin={margin}

                key={element.name}
                id={element.name}
                name={element.name}
                label={capitalizeFirstLetter(element.label)}
                required={element.required}

                value={field.values[element.name]}
                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'date' ):
            inputElement = <DateField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'daterange' ):
            inputElement = <DateRangeField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'select' ):
            inputElement = <SelectField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'autocomplete' ):
            inputElement = <AutocompleteField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}
                multi={multi}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'switch' ):
            inputElement = <SwitchField
                className={width}
                variant={variant}
                margin={margin}

                key={element.name}
                id={element.name}
                name={element.name}
                label={capitalizeFirstLetter(element.name)}
                required={element.required}
                toggleOptions={toggleOptions}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'checkbox' ):
            inputElement = <CheckboxField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}
                labelPlacement={labelPlacement}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'radio' ):
            inputElement = <RadioField
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'fileupload' ):
            inputElement = <FileUpload
                className={width}
                variant={variant}
                margin={margin}
                disabled={disabled}

                key={element.name}
                id={element.name}
                name={element.name}
                title={capitalizeFirstLetter(element.label)}
                options={selectOptions}
                defaultValue={field.values[element.name]}
                required={element.required}

                onChange={field.handleChange}
                error={field.touched[element.name] && Boolean(field.errors[element.name])}
                helperText={field.touched[element.name] && field.errors[element.name]}
            />;
            break;
        case ( 'colorpicker' ):
            inputElement = <ColorPickerUi
                className={width}
                title={capitalizeFirstLetter(element.name)}
                defaultValue={field.values[element.name]}
            />;
            break;
        case ( 'customfields' ):
            inputElement = <CustomFieldArray
                className={width}
            />;
            break;
        default:
            inputElement = <TextField
                className={width}
                variant={variant}
                margin={setMargin(margin)}
                id={name}
                name={name}
                label={capitalizeFirstLetter(name)}
                type={type}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
            />;
    }

    return (
        inputElement
    )
}