import axiosApiInstance from "../../../helpers/axiosConfig";

const CompetencyCategory = (dispatch, id = null) => {

  const endpoint = (id) ? "api/v1/competency-category/" + id : "api/v1/competency-category";

  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "COMPETENCY_CATEGORY", competency_category: res.data});
      dispatch({type: "COMPETENCY_CATEGORY_UPDATED", competency_category_update: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default CompetencyCategory;