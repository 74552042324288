import React, {useEffect, useState} from 'react';
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import {useStore} from "../../stores/store";
import {setupCrud} from "../../helpers/setupCrud";
import AccessLevelPositionForm from "./Forms/AccessLevelPositionForm";

const menu = [
  // {id: 1, name: "My Learning"},
  // {id: 2, name: "My Profile"},
  {id: 3, name: "People@GEMCO"},
  // {id: 4, name: "My Team"},
  {id: 5, name: "Competencies"},
  {id: 6, name: "Positions"},
  {id: 7, name: "Vendors"},
  {id: 8, name: "Organisations"},
  {id: 9, name: "Roles"},
  {id: 10, name: "MobiliseMe"},
];

const access_level = [
  // {id: 1, name: "Developer", code: "DEV"},
  {id: 2, name: "Super User", code: "SUPER"},
  {id: 3, name: "LMS Global Administrator", code: "GLOBAL-ADMIN"},
  {id: 4, name: "LMS L&D Administrator - Operation", code: "LD-ADMIN"},
  {id: 5, name: "LMS Department Administrator", code: "DEP-ADMIN"},
  {id: 6, name: "LMS Contractor Administrator", code: "CON-ADMIN"},
  {id: 7, name: "LMS Manager", code: "LMS-MANAGER"},
  {id: 8, name: "LMS Instructor", code: "INSTRUCTOR"},
  {id: 9, name: "Site Reporter", code: "REPORTER"},
  // {id: 10, name: "User", code: "USER"},
];

const columnHeader = [
  {
    id: "access_level_id",
    renderType: 'render|access_level',
    align: 'left',
    disablePadding: false,
    label: "Security Role",
    setWidth: '20%',
    searchable: true,
    searchType: 'select|access_level',
    searchWidth: 6,
  },
  {
    id: "type_id",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Security Role Type",
    setWidth: '20%',
    searchable: true,
    selectLimiter: 'category_id|17',
    searchType: 'autocomplete|types',
    searchWidth: 6,
  },
  {
    id: "type_link_id",
    renderType: 'link_id|type_id_VALUE',
    align: 'left',
    disablePadding: false,
    label: "Security Role Link",
    setWidth: '20%',
    searchable: true,
    searchType: 'type_link',
    searchInfo: 'text',
    searchWidth: 12,
  },
  {
    id: "start_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "Start Date",
    searchable: false,
    searchType: 'date',
    searchWidth: 6,
  },
  {
    id: "end_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "End Date",
    searchable: false,
    searchType: 'date',
    searchWidth: 6,
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const AccessLevelTypes = (props) => {
  const {title, disableField, crudAllowed} = props;
  const buttonEndpoint = 'api/v1/access-level-types/';
  let sortBy = "id";

  return (
    <DynamicTable
      tableTitle={title}
      filter={!!(crudAllowed.read)}
      headCells={columnHeader}
      sortBy={sortBy}
      addButton={!!(crudAllowed.create)}
      editButton={!!(crudAllowed.update)}
      deleteButton={!!(crudAllowed.delete)}
      buttonEndpoint={buttonEndpoint}
      form={<AccessLevelPositionForm/>}
      modalWidth={'md'}
    />
  );
};

export default AccessLevelTypes;