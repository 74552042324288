import React, {useState} from "react";
import {Skeleton} from "@material-ui/lab";
import {useHistory} from "react-router-dom";
import PersonnelPositionFields from "./PersonnelPositionFields";
import {useStore} from "../../../stores/store";
import {Update} from "../../../stores/action/Form/Update";
import {Post} from "../../../stores/action/Form/Post";
import Validation from "../../../stores/action/Form/Validation";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import jwt_decode from "jwt-decode";

const PersonnelPositionForm = (props) => {

  let history = useHistory();
  const {uid, id, data, personnelId, sectionCrudAllowed} = props;
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  let disable = (uid === decoded.sub);
  const endpoint = (id && id !== "create") ? 'api/v1/personnel-position/' + id : 'api/v1/personnel-position';
  const fields = PersonnelPositionFields(data, personnelId, disable, sectionCrudAllowed);
  const personnelPositionCustomFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );
  const formik = Validation(fields, personnelPositionCustomFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        })
      }

      {
        (!disable && !!sectionCrudAllowed['USER_POSITION'].crud.update) &&
        <GroupSubmit
          className={'GroupSubmit'} reset={false} auto={false} right={true}
          submitText={(id && id !== "create" ? 'Update' : 'Save')} formik={formik}
        />
      }

    </Form>
  )
}

export default PersonnelPositionForm;