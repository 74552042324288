import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const Vendor = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/vendor")
    .then(res => {
      dispatch({type: "VENDOR", vendor: res.data});
      dispatch({type: "VENDOR_UPDATED", update_vendor: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default Vendor;