import React, {useEffect} from "react";
import Alert from "../UI/Alert/Alert";

const Form = (props) => {
  const {stateLocal, setstateLocal, className, onSubmit} = props;

  let formClass = 'baseForm';

  if (className) {
    formClass = 'baseForm ' + className;
  }

  return (
    <>
      {(stateLocal.message) && (<Alert notification={stateLocal} state={setstateLocal} />)}
      <form
        noValidate
        autoComplete="off"
        className={formClass}
        onSubmit={onSubmit}
      >
        {props.children}
      </form>
    </>
  )
};

export default Form;