import * as React from 'react';
import AppContainer from "../../containers/Container/App/Main";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import PositionForm from "../Position/Form/PositionForm";
import {useEffect, useState} from "react";
import {useStore} from "../../stores/store";
import {setupCrud} from "../../helpers/setupCrud";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Name",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  // {
  //   id: "external_position_identifier",
  //   renderType: 'default',
  //   align: 'left',
  //   disablePadding: false,
  //   label: "External Position Identifier",
  //   setWidth: '15%',
  //   searchable: true,
  //   searchType: 'text'
  // },
  {
    id: "code",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Code",
    setWidth: '10%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "organisation_unit_id",
    renderType: 'render|organisation_unit',
    align: 'left',
    disablePadding: false,
    label: "Organisation Unit",
    setWidth: '17%',
    searchable: true,
    searchType: 'select|organisation_unit',
    selectLimiter: 'type_id_organisation_unit_type|34'
  },
  {
    id: "position_id_parent",
    renderType: 'render|position',
    align: 'left',
    disablePadding: false,
    label: "Parent Position",
    setWidth: '25%',
    searchable: true,
    searchType: 'select|position',
  },
  {
    id: "type_id_update_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Update Type",
    setWidth: '9%',
    searchable: true,
    searchType: 'select|types',
    selectlimiter: 'category_id||11'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];

const subRow = [
  {
    id: "valid_from_date",
    renderType: 'date',
    align: 'left',
    disablePadding: false,
    label: "Valid From",
    setWidth: '13%'
  },
  {
    id: "valid_to_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "Valid To",
    setWidth: '12%'
  },
];


const AdminPosition = (props) => {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const buttonEndpoint = 'api/v1/position/';
  let sortBy = "id";
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));
  }, [state.user_data.menu_updated]);

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <DynamicTable
        tableTitle={'Positions'}
        filter={!!(crud.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        subRow={subRow}
        addButton={!!(crud.create)}
        editButton={!!(crud.update)}
        deleteButton={!!(crud.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<PositionForm/>}
      />
    </AppContainer>
  );
}

export default AdminPosition;