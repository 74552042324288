import axiosApiInstance from "../../../helpers/axiosConfig";
import {Chip} from "@material-ui/core";
import {stringReplace} from "../../../helpers/stringReplace";
import React from "react";

const UserMenu = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/user/menu")
    .then(res => {

      let menus = [];

      res.data.map((menu, id) => {
        switch (id) {
          case 0:
            menus['main'] = menu['MAIN-MENU'];
          case 1:
            menus['admin'] = menu['ADMIN-MENU'];
          case 2:
            menus['settings'] = menu['TAB'];
          case 3:
            menus['section'] = menu['TAB_SECTION'];
        }
      })

      dispatch({type: "USER_MENU", menu: menus});
      dispatch({type: "USER_MENU_UPDATED", menu_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default UserMenu;