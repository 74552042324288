import React, {useEffect, useState} from "react";
import {inputBuilder} from "../../../helpers/inputBuilder"

export default function FieldBuilder(props) {
    const {field, fieldAttributes, validation, onChangeFunction} = props;
    let formFields = '';

    if (typeof (fieldAttributes) !== 'undefined') {
        if (fieldAttributes && Object.entries(fieldAttributes).length > 0) {
            formFields = inputBuilder(field, fieldAttributes, validation, onChangeFunction);
        }
    }

    return (formFields);
}