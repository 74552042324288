const systemSettingReducer = (state, action) => {
    switch (action.type) {
        case "SETTINGS":
            return {
                ...state,
                system_setting: {
                    company_details: action.system_setting[0].company_details,
                    branding_font: action.system_setting[1].branding_font,
                    branding_color: action.system_setting[2].branding_color,
                }
            }
        case "SYSTEM_UPDATED":
            return {
                ...state,
                settings_updated: action.settings_updated
            }
        default:
            return state;
    }
}

export default systemSettingReducer;