import React from 'react';
import {Box} from "@material-ui/core";

function itemWidth(fullWidth) {
  let newItemWidth = 'auto';

  if (fullWidth) {
    newItemWidth = 'fullWidth';
  }

  return newItemWidth;
}

function itemDirection(column  = null) {
  switch (column) {
    case 'row':
      return 'row';
    case 'column':
      return 'column';
    default:
      return 'row'
  }
}

function itemAlign(align = null) {
  switch (align) {
    case 'right':
      return 'alignRight';
    case 'left':
      return 'alignLeft';
    default:
      return ''
  }
}

const CustomBox = (props) => {

  const {children, direction, align} = props;
  let className = '';

  if (typeof (props.className) !== 'undefined') {
    className = props.className;
  }

  return (
    <Box
      className={'CustomBox ' + className + ' ' + itemDirection(direction) + ' ' + itemAlign(align)}
    >
      {
        children && Array.isArray(children) ? (
          children && children.map((child, id) => {
            return <Box key={id}>
              {child}
            </Box>
          })
        ) : (
          <Box>
            {children}
          </Box>
        )
      }
    </Box>
  );
};


export default CustomBox;
