import React from 'react';

//Material Cores
import {Container, makeStyles} from "@material-ui/core";

//Custom Components
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import Navigation from "../../../components/Navigation/Navigation";

//Custom CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    offset:{
        ...theme.mixins.toolbar,
    },
}));

const AppContainer = (props) => {
    const classes = useStyles();

    return (
        <div className={'mainBackground'}>
            <div className={classes.root}>
                <Header/>
                <Navigation/>
                <main className={'app'}>
                    <div className={classes.offset} />
                    <div className={'mainContent'}>
                        <Container fixed maxWidth="xl">
                        {props.children}
                        </Container>
                    </div>
                    <Footer/>
                </main>
            </div>
        </div>
    );
};

export default AppContainer;
