import React, {useState} from "react";
import {Skeleton} from "@material-ui/lab";
import {Post} from '../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import {Update} from "../../../stores/action/Form/Update";
import {useStore} from "../../../stores/store";
import RoleFields from './RoleFields';

function getString(id) {
  return id && id !== "create" ? 'Update' : 'Save';
}

function setRoleFormFields(fields, formik) {
  return <>
    {
      fields && Object.entries(fields).map(([fieldKey, _field]) => {
        return <FieldBuilder
          key={fieldKey}
          field={fieldKey}
          fieldAttributes={fields[fieldKey]}
          validation={formik}
        />
      })
    }
  </>;
}

const RoleForm = (props) => {

  let history = useHistory();
  const {id, data} = props
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (id && id !== "create") ? 'api/v1/role/' + id : 'api/v1/role';
  const fields = RoleFields(data);
  const roleCustomFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );
  const formik = Validation(fields, roleCustomFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {setRoleFormFields(fields, formik)}

      {
        fields && Object.entries(fields).length > 0
          ? <GroupSubmit
            className={'GroupSubmit'} reset={false} auto={false} right={true}
            submitText={getString(id)} formik={formik}
          />
          : <Skeleton variant="rect" width={'100%'}/>
      }

    </Form>
  )
}

export default RoleForm;