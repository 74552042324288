// import React from "react";
import axios from "axios";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async config => {

    config.headers = {
      'Accept': 'application/pdf',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/pdf'
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }

    config.responseType = "blob"

    if (localStorage.getItem('token') != '') {
      const token = JSON.parse(localStorage.getItem('token'));
      config.headers['Authorization'] = `Bearer ${token.access_token}`;
      // config.headers['Content-Type'] ='application/x-www-form-urlencoded';
    }

    return config;
  },
  error => {
    // this.router('/');
    Promise.reject(error)
  });


// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    // const access_token = await refreshAccessToken();
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

export default axiosApiInstance;