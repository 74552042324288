import React, {createContext, useContext, useReducer} from 'react';
import combineReducers from 'react-combine-reducers';

import globalUpdateReducer from '../stores/reducer/globalUpdateReducer';
import systemSettingReducer from '../stores/reducer/systemSettingReducer';
import navigationDrawerReducer from '../stores/reducer/navigationDrawerReducer';
import messageReducer from '../stores/reducer/messageReducer';
import genderReducer from './reducer/genderReducer';
import relationshipReducer from './reducer/relationshipReducer';
import countryReducer from './reducer/countryReducer';
import stateReducer from './reducer/stateReducer';
import statusReducer from './reducer/statusReducer';
import accessMenusReducer from './reducer/accessMenusReducer';
import accessLevelReducer from './reducer/accessLevelReducer';
import accessLevelPositionReducer from './reducer/accessLevelPositionReducer';
import typeReducer from './reducer/typeReducer';

import competencyReducer from '../stores/reducer/competencyReducer';
import competencyCategoryReducer from '../stores/reducer/competencyCategoryReducer';
import userReducer from '../stores/reducer/userReducer';
import usersReducer from '../stores/reducer/usersReducer';
import vendorReducer from './reducer/vendorReducer';
import roleReducer from './reducer/roleReducer';
import personnelReducer from './reducer/personnelReducer';
import positionReducer from './reducer/positionReducer';
import organisationUnitReducer from './reducer/organisationUnitReducer';
import personnelPositionReducer from './reducer/personnelPositionReducer';
import PersonnelRoleReducer from './reducer/PersonnelRoleReducer';

import teamLastActivityReducer from '../stores/reducer/teamLastActivityReducer';
import teamCompetencyReducer from '../stores/reducer/teamCompetencyReducer';
import cmidReducer from '../stores/reducer/cmidReducer';

import initialGlobalUpdate from '../stores/initialization/initialGlobalUpdate';
import initialSystemSetting from '../stores/initialization/initialSystemSetting';
import initialNavigationDrawer from './initialization/initialNavigationDrawer';
import initialStateMessage from '../stores/initialization/initialStateMessage';
import initialStateGender from '../stores/initialization/initialStateGender';
import initialStateRelationship from '../stores/initialization/initialStateRelationship';
import initialStateCountry from '../stores/initialization/initialStateCountry';
import initialStateState from '../stores/initialization/initialStateState';
import initialStateStatus from '../stores/initialization/initialStateStatus';
import initialStateAccessLevel from '../stores/initialization/initialStateAccessLevel';
import initialStateMenus from '../stores/initialization/initialStateMenus';
import initialStateAccessLevelPosition from '../stores/initialization/initialStateAccessLevelPosition';
import initialStateType from '../stores/initialization/initialStateType';
import initialStateCmid from '../stores/initialization/initialStateCmid';

import initialStateCompetency from '../stores/initialization/initialStateCompetency';
import initialStateCompetencyCategory from '../stores/initialization/initialStateCompetencyCategory';
import initialStateUser from '../stores/initialization/initialStateUser';
import initialStateUsers from '../stores/initialization/initialStateUsers';
import initialStateVendor from './initialization/initialStateVendor';
import initialStateRole from './initialization/initialStateRole';
import initialStatePersonnel from './initialization/initialStatePersonnel';
import initialStatePosition from './initialization/initialStatePosition';
import initialStateOrganisationUnit from './initialization/initialStateOrganisationUnit';
import initialStatePersonnelPosition from './initialization/initialStatePersonnelPosition';
import initialStatePersonnelRole from './initialization/initialStatePersonnelRole';

import initialTeamLastActivity from '../stores/initialization/initialTeamLastActivity';
import initialTeamCompetency from '../stores/initialization/initialTeamCompetency';
import searchReducer from "./reducer/searchReducer";
import initialStateSearch from "./initialization/initialStateSearch";

const StoreContext = createContext({});

const [rootReducer, initialSettings] = combineReducers({
    global_update: [globalUpdateReducer, initialGlobalUpdate],
    system_setting: [systemSettingReducer, initialSystemSetting],
    navigation_drawer: [navigationDrawerReducer, initialNavigationDrawer],
    search: [searchReducer, initialStateSearch],
    information: [messageReducer, initialStateMessage],
    status_data: [statusReducer, initialStateStatus],
    gender_data: [genderReducer, initialStateGender],
    relationship_data: [relationshipReducer, initialStateRelationship],
    country_data: [countryReducer, initialStateCountry],
    state_data: [stateReducer, initialStateState],
    menus_data: [accessMenusReducer, initialStateMenus],
    access_level_data: [accessLevelReducer, initialStateAccessLevel],
    access_level_position_data: [accessLevelPositionReducer, initialStateAccessLevelPosition],
    types_data: [typeReducer, initialStateType],
    user_data: [userReducer, initialStateUser],
    users_data: [usersReducer, initialStateUsers],
    cmid_data: [cmidReducer, initialStateCmid],
    competency_data: [competencyReducer, initialStateCompetency],
    competency_category_data: [competencyCategoryReducer, initialStateCompetencyCategory],
    team_last_activity_data: [teamLastActivityReducer, initialTeamLastActivity],
    team_competency_data: [teamCompetencyReducer, initialTeamCompetency],
    vendor_data: [vendorReducer, initialStateVendor],
    role_data: [roleReducer, initialStateRole],
    personnel_data: [personnelReducer, initialStatePersonnel],
    position_data: [positionReducer, initialStatePosition],
    personnel_position_data: [personnelPositionReducer, initialStatePersonnelPosition],
    personnel_role_data: [PersonnelRoleReducer, initialStatePersonnelRole],
    organisation_unit_data: [organisationUnitReducer, initialStateOrganisationUnit],
});

export const StoreProvider = ({children}) => {

    const [state, dispatch] = useReducer(rootReducer, initialSettings);

    return (
        <StoreContext.Provider value={{state, dispatch}}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStore = () => useContext(StoreContext);