import React from 'react';

export function setupCrud(path= '', menu_state = null) {
  let crud = [];

  if(menu_state && menu_state.length > 0) {
    menu_state.map((menu, id) => {
      if (menu.url === path) {
        crud = menu.crud;
      }
    })
  }

  return crud;
}