const genderReducer = (state, action) => {
  switch (action.type) {
    case "GENDER":
      return {
        ...state,
        gender: action.gender,
      }
    case "GENDER_UPDATED":
      return {
        ...state,
        update_gender: action.update_gender,
      }
    default:
      return state;
  }
}

export default genderReducer;