import React, {useEffect, useState} from "react";
import {Post} from '../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import {Update} from "../../../stores/action/Form/Update";
import {useStore} from "../../../stores/store";
import UserNotificationFields from "./UserNotificationFields";
import TeamNotificationFields from "./TeamNotificationFields";

const TeamNotificationForm = (props) => {

  let history = useHistory();
  const {data, id, crudAllowed, accountHolder} = props
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const [override, setOverride] = useState(false);
  const [showFields, setShowFields] = useState([]);
  const endpoint = (id && id !== "create") ? 'api/v1/notification/team/' + id : 'api/v1/notification/team';
  const fields = TeamNotificationFields(data, crudAllowed, accountHolder);
  const teamNotificationFieldsCustomFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );

  const formik = Validation(fields, teamNotificationFieldsCustomFunc);

  useEffect(() => {
    if (override) {
      setShowFields(['status_id', 'type_id_notification_type', 'notification_type', 'days_before_expiry', 'days', 'repeat']);
    } else {
      setShowFields(['status_id']);
    }
  }, [override]);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return showFields.includes(fieldKey) && <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
            onChangeFunction={setOverride}
          />
        })
      }

      {
        (Object.entries(fields).length > 0) &&
        <GroupSubmit
          className={'GroupSubmit'} reset={false} auto={false} right={true}
          submitText={(id && id !== "create" ? 'Update' : 'Save')} formik={formik}
        />
      }

    </Form>
  )
}

export default TeamNotificationForm;