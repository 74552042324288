import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {FormControl} from "@material-ui/core";

export default function AutocompleteField(props) {
    const {
        className,
        id,
        title,
        variant,
        margin,
        options,
        multi,
        defaultValue,
        required
    } = props;

    return (
        <FormControl className={className} required={required} margin={margin}>
            <Autocomplete
                multiple={multi}
                id="tags-outlined"
                options={options}
                getOptionLabel={(options) => options.displayValue}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        variant={variant}
                        label={title}
                        placeholder={title}
                    />
                )}
            />
        </FormControl>
    );
}