import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {FormControl} from "@material-ui/core";
import Option from "../../../../stores/action/Form/Option";

export default function AutocompleteField(props) {
  const {name, id, label, variant, option, multi, required, value, className, formik, limiter, exclude, disabled} = props;
  let initialValueType = null;
  if (multi) {
    initialValueType = [];
  }
  const [selectedValue, setSelectedValue] = React.useState(initialValueType);
  const [inputValue, setInputValue] = React.useState("");

  const options = Option(option, limiter, exclude);

  let newOptions = [];
  let optionId = initialValueType;

  options && Object.entries(options).map(([fieldKey, field]) => {
    newOptions.push(field);
    if (multi) {
      if (value.includes(options[fieldKey].id)) {
        optionId.push(field);
      }
    } else {
      if (options[fieldKey].id === value) {
        optionId = options[fieldKey];
      }
    }
  });

  useEffect(() => {
    if (Object.entries(options).length > 0) {
      setSelectedValue(optionId);
    }
  }, [Object.entries(options).length]);

  return (
    <FormControl required={required} className={className}>
      <Autocomplete
        multiple={multi}
        id={id}
        name={name}
        disabled={disabled}
        value={selectedValue}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
          (newValue !== null) ? formik.setFieldValue(id, newValue.id) : '';
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={newOptions}
        getOptionLabel={(newOptions) => newOptions.name}
        // TODO getOptionsSelected multiple not yet working
        getOptionSelected={(optionId) => optionId.code}
        renderInput={(params) => <TextField {...params} required={required} variant={variant} label={label}
                                            placeholder={label}/>}
      />
    </FormControl>
  );
}
