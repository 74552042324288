import {Grid, Link} from "@material-ui/core";
import React from "react";

export default function LoginLinks(prop) {
    let rightLink = <Link href="/auth/help" variant="body2"> {"Need help logging in?"} </Link>;

    if (prop.page === 'help') {
        rightLink = <Link href="/" variant="body2"> {"Back to login"} </Link>;
    }

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                {rightLink}
            </Grid>
            {/*<Grid item>*/}
            {/*    <Link href="/auth/register" variant="body2">*/}
            {/*        {"Don't have an account? Sign Up"}*/}
            {/*    </Link>*/}
            {/*</Grid>*/}
        </Grid>
    )
}