import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CellLogo from '../../assets/logo/Cell-media-White.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#0d0f0f',
    color: '#cccccc',
    padding: theme.spacing(0),
    marginTop: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  typography: {
    fontSize: 13,
    fontWeight: 400
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Container component="footer" maxWidth={false} className={classes.footer}>
      <div className={'topBorder'}>&nbsp;</div>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center" className={classes.content}>
        <Typography align="left" variant="subtitle2" className={classes.typography}>{'Copyright © '}  {new Date().getFullYear()} Cell-media | V1.0.0</Typography>
        <Typography align="right" variant="subtitle2" className={classes.typography}>Powered by  <Link href="http://www.cell-media.com" target="_blank" variant="body2">
          <img src={CellLogo} alt="MobiliseMe™ express" className={classes.logo}/></Link>
        </Typography>
      </Box>
    </Container>
  )
}