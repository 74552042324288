import axios from "axios";

const axiosConfig = axios.create();

axiosConfig.interceptors.request.use(
  async config => {
    config.headers = {
      common: {
        "Authorization": (JSON.parse(localStorage.getItem('token')).access_token ? `Bearer ${JSON.parse(localStorage.getItem("token")).access_token}` : undefined)
      },
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Content-Type': 'application/json',
      "Authorization": (JSON.parse(localStorage.getItem('token')).access_token ? `Bearer ${JSON.parse(localStorage.getItem("token")).access_token}` : undefined)
    };

    return config;
  },
  error => {
    return Promise.reject(error)
  });

axiosConfig.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;

  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    return axiosConfig(originalRequest);
  }

  return Promise.reject(error);
});

export default axiosConfig;