import React, {useState} from "react";
import {Post} from '../../../stores/action/Form/Post';
import {Update} from "../../../stores/action/Form/Update";
import {useHistory} from "react-router-dom";
import {useStore} from "../../../stores/store";
import ProfileDetailsFields from "../ProfileDetails/ProfileDetailsFields";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import Address from "../../Address/Address";
import PersonalDetailsFields from "./PersonalDetailsFields";
import {allowEdit} from "../../../helpers/allowEdit";

const PersonalDetails = (props) => {
  let history = useHistory();
  const {dispatch} = useStore();
  const {uid, accountHolder, userInformation, disableField, crudAllowed, sectionCrudAllowed} = props
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (uid && uid !== 'create') ? 'api/v1/user-detail/' + uid : 'api/v1/user-detail';
  const fields = PersonalDetailsFields(uid, accountHolder, userInformation, disableField, crudAllowed, sectionCrudAllowed);
  const profileFunc = values => (
    uid && uid !== 'create'
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );

  const formik = Validation(fields, profileFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldThird'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return (
            <FieldBuilder
              key={fieldKey}
              field={fieldKey}
              fieldAttributes={fields[fieldKey]}
              validation={formik}
            />
          )
        })
      }
      {
        (allowEdit(accountHolder, sectionCrudAllowed['PERSONAL-INFORMATION'].crud.update) || allowEdit(accountHolder, sectionCrudAllowed['RESIDENTIAL-ADDRESS'].crud.update)) &&
        <GroupSubmit
          className={'GroupSubmit'} reset={false} auto={true}
          align={'right'} submitText={'Update'} formik={formik}
        />
      }
    </Form>
  )
}

export default PersonalDetails;