import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, makeStyles, Toolbar, useScrollTrigger} from '@material-ui/core';
import Logo from '../Logo/Logo';
import HeaderBadge from './HeaderBadge/HeaderBadge';
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

function ElevationScroll(props) {
    const {children, window} = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const Header = (props) => {
    const classes = useStyles();
    const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
    const decoded = jwt_decode(login_data.access_token);

    return (
        <React.Fragment>
            <ElevationScroll {...props}>
                <AppBar
                    className={classes.appBar}
                    component="header"
                    position="fixed"
                    color="primary"
                >
                    <Toolbar>
                        <Logo height={'43px'} width={'auto'} />
                        <Box style={{width: '100%'}} >
                            <HeaderBadge fullName={decoded.user.username}/>
                        </Box>
                    </Toolbar>

                </AppBar>
            </ElevationScroll>
        </React.Fragment>
    );
};

export default Header;