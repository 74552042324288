import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const OrganisationUnit = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/organisation-unit")
    .then(res => {
      dispatch({type: "ORGANISATION_UNIT", organisation_unit: res.data});
      dispatch({type: "ORGANISATION_UNIT_UPDATED", update_organisation_unit: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default OrganisationUnit;