import React, {useEffect, useState} from 'react';
import AppContainer from "../../containers/Container/App/Main";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import {useStore} from "../../stores/store";
import {setupCrud} from "../../helpers/setupCrud";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "user_status",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%',
    searchable: true,
    searchType: 'select|status',
    assignedValue: 1,
    selectLimiter: 'id|1,10',
    searchWidth: 4,
  },
  {
    id: "external_personnel_identifier",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "External Personnel ID",
    setWidth: '10%',
  },
  {
    id: "first_name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "First Name",
    setWidth: '9%',
    searchable: true,
    searchType: 'text',
    searchWidth: 4,
  },
  {
    id: "last_name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Last Name",
    setWidth: '9%',
    searchable: true,
    searchType: 'text',
    searchWidth: 4,
  },
  {
    id: "organisation_unit_id",
    renderType: 'render|organisation_unit',
    align: 'left',
    disablePadding: false,
    label: "Department",
    setWidth: '16%',
    searchable: true,
    searchType: 'autocomplete|organisation_unit',
    selectLimiter: 'type_id_organisation_unit_type|34',
    searchWidth: 6,
  },
  {
    id: "vendor_id",
    renderType: 'render|vendor',
    align: 'left',
    disablePadding: false,
    label: "Company",
    setWidth: '9%',
    searchable: true,
    searchType: 'autocomplete|vendor',
    selectExclude: 'id|1',
    searchWidth: 6,
  },
  {
    id: "start_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "Start Date",
    setWidth: '10%',
    searchable: true,
    searchType: 'date',
    assignedValue: null,
    dateQuery: '>',
    searchWidth: 6,
  },
  {
    id: "end_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "End Date",
    setWidth: '10%',
    searchable: true,
    searchType: 'date',
    assignedValue: null,
    dateQuery: '<',
    searchWidth: 6,
  },
  {
    id: "type_id_resource_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Personnel Type",
    setWidth: '10%',
    searchable: true,
    searchType: 'select|types',
    selectLimiter: 'category_id|6',
    searchWidth: 4,
  },
  {
    id: "personnel_status",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Personnel Status",
    searchable: true,
    searchType: 'select|status',
    assignedValue: 1,
    selectLimiter: 'id|1,2,5,10',
    searchWidth: 4,
  },
  {
    id: "cm_id",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "CMID",
    setWidth: '9%',
    searchable: true,
    searchType: 'text',
    searchWidth: 4,
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '6%'},
];

const subRow = [
  {
    id: "username",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Username",
  },

  {
    id: "type_id_resource_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Personnel Type",
  },
  {
    id: "top_line_leader",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Leader Once Removed",
  },
  {
    id: "line_leader",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Line Leader",
  },
  {
    id: "start_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "end_date",
    renderType: 'date|no_expiry',
    align: 'left',
    disablePadding: false,
    label: "End Date",
  },
];

export default function UsersTable(props) {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const buttonEndpoint = 'api/v1/users/';
  let sortBy = "cm_id";
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));
  }, [state.user_data.menu_updated]);

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <DynamicTable
        tableTitle={'Users'}
        filter={!!(crud.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        subRow={subRow}
        addButton={false}
        editButton={!!(crud.update) || !!(crud.read) }
        deleteButton={false}
        buttonEndpoint={buttonEndpoint}
        link={"/admin/user/profile/"}
        modalWidth={'md'}
      />
    </AppContainer>
  );
}
