import React, {useEffect, useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DynamicValue from "./DynamicValue";
import DynamicTableButton from "./DynamicTableButton";
import {Collapse, TableBody} from "@material-ui/core";
import CustomBox from "../../Box/CustomBox";
import Box from '@material-ui/core/Box';

const columnValue = (rowValues, value) => {
  let rendered_value = rowValues[value];

  if (value.includes(".")) {
    const values = value.split(".");
    let value1 = values[0];
    let value2 = values[1];
    // let value3 = values[2];

    for(let key in rowValues) {
      if(value1 === key) {
        if(typeof rowValues[value1] !== 'object') {
          rendered_value = rowValues[value1];
        } else {
          for(let key2 in rowValues[value1]) {
            if(value2 === key2) {
              if(typeof rowValues[value1][value2] !== 'object') {
                rendered_value = rowValues[value1][value2];
              }
            }
          }
        }
      }
    }
  }

  return rendered_value;

}

export default function DynamicRow(props) {
  const {columns, row, subRow, editButton, deleteButton, buttonEndpoint, modalWidth, link, form, linkUpValue} = props;
  const [rowValue, setRowValue] = useState({});
  const [expandOpen, setExpandOpen] = useState(false);

  useEffect(() => {
    setRowValue(row);
  }, [row]);

  return (
    <>
      <TableBody component={'div'}>
        <TableRow component={'div'}>
          {
            columns && columns.length > 0 ?
              columns.map((value, cid) => {
                return (typeof value.setWidth !== "undefined") && <React.Fragment key={cid}>
                  <TableCell className={'mobileTableLabel'}
                             component={'div'}> {value.label && value.label + ':'} </TableCell>
                  <TableCell
                    component={'div'}
                    align={value.align}
                    padding={value.disablePadding ? 'none' : 'normal'}
                    style={{width: (value.setWidth !== 'auto') ? value.setWidth : 'auto', wordWrap: 'break-word', wordBreak: 'break-word'}}
                  >
                    {
                      (value.id !== 'action')
                        ? <DynamicValue linkUpValue={linkUpValue} type={value.renderType} value={columnValue(rowValue,value.id)}/>
                        : <DynamicTableButton
                          editButton={editButton}
                          link={link}
                          deleteButton={deleteButton}
                          row={row}
                          subRow={subRow}
                          buttonEndpoint={buttonEndpoint}
                          expandOpen={expandOpen}
                          setExpandOpen={setExpandOpen}
                          modalWidth={modalWidth}
                          form={form}
                        >
                          {props.children}
                        </DynamicTableButton>
                    }
                  </TableCell></React.Fragment>
              }) :
              <TableCell> No data </TableCell>
          }
        </TableRow>
      </TableBody>

      {
        subRow && <div className={"tableDetail"}>
          <div className={"tableDetailInfo"}>
            <Collapse in={expandOpen} timeout="auto" unmountOnExit>
              <CustomBox direction={'column'} className={"tableDetailExpanded"}>
                {
                  subRow.map((subRowValue, subId) => {
                    return <Box key={subRowValue.id + '_' + subId}>
                      {subRowValue.label}{subRowValue.label && ":"} <DynamicValue
                      type={subRowValue.renderType} value={rowValue[subRowValue.id]}/>
                    </Box>
                  })
                }
              </CustomBox>
            </Collapse>
          </div>
        </div>
      }
    </>
  );
}
