import axiosApiInstance from "../../../helpers/axiosConfig";

const Type = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/type")
    .then(res => {
      dispatch({type: "TYPE", types: res.data});
      dispatch({type: "TYPE_UPDATED", types_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default Type;