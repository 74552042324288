export function allowEdit(accountHolder, allowAdmin) {

  let allowed = false;

  if (allowAdmin && !accountHolder) {
    allowed = true;
  }

  if (accountHolder) {
    allowed = true;
  }

  return allowed;
}

