import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";

export default function DynamicTableHead(props) {

  const {order, orderBy, onRequestSort, headCells} = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead component={'div'} className={"DynamicTableHead"} >
        <TableRow component={'div'}>
          {headCells.map((headCell) => (
            (typeof headCell.setWidth !== "undefined") && <TableCell
              key={headCell.id}
              component={'div'}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{width: (headCell.setWidth !== 'auto') ? headCell.setWidth : 'auto', wordWrap: 'break-word'}}
            >
              <TableSortLabel
                component={'div'}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );

}