import moment from "moment";
import PdfTranscript from "./Transcript/PdfTranscript";
import CsvTranscript from "./Transcript/CsvTranscript";


const UserTranscript = (dispatch, id, setState, version) => {
  const endpoint = (id) ? "api/v1/user/transcript/" + id : "api/v1/user/transcript";
  const apiLink = process.env.REACT_APP_API_DOMAIN + endpoint;
  const filename = "transcript_" + moment().format("DD_MM_YYYY_hh_mm_ss") + "." + version;

  if (version === 'pdf') {
    PdfTranscript(apiLink, filename, dispatch, setState);
  } else {
    CsvTranscript(version, apiLink, filename, dispatch, setState);
  }

};

export default UserTranscript;