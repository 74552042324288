import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";

export default function CheckboxField(props) {
    const {
        className,
        id,
        title,
        variant,
        margin,
        labelPlacement,
        defaultValue,
        required
    } = props;


    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (event) => {
        setValue({ ...value, [event.target.name]: event.target.checked });
    };

    return (
        <FormControl className={className} required={required} margin={margin}>
            <FormControlLabel
                variant={variant}
                value={defaultValue}
                control={
                    <Checkbox
                        id={id}
                        color="primary"
                    />}
                label={title}
                labelPlacement={labelPlacement}
            />
        </FormControl>
    );
}
