import React, {useEffect} from "react";
import {Box, Typography} from "@material-ui/core";
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import DynamicModal from "../../containers/DataTable/DynamicTable/DynamicModal";
import PersonnelForm from "../Personnel/Form/PersonnelForm";
import UserPersonnelItems from "../Personnel/UserPersonnelItems";
import UserDetails from "../../stores/action/User/UserDetails";
import {useStore} from "../../stores/store";
import PersonnelPosition from "../../stores/action/Personnel/PersonnelPosition";
import PesonnelRoleForm from "./Form/PesonnelRoleForm";
import PesonnelRoleItems from "./PesonnelRoleItems";
import jwt_decode from "jwt-decode";

const PesonnelRoles = (props) => {
  const {uid, roles, personnelId, sectionCrudAllowed} = props

  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  let disable = (uid === decoded.sub);

  return (
    <Box component={'div'} p={3}>
      <Box component={'div'} className={'titleBackground primary_color'}>
        <CustomGrid auto={false} alignItems={'center'}>
          <CustomBox className={'noMargin'}>
            <Typography color={'secondary'} variant={'caption'}> {'Roles'}</Typography>
          </CustomBox>
          <CustomBox className={'noMargin'} align={'right'}>
            {(roles && roles.length > 0 && sectionCrudAllowed['USER_ROLES'].crud.create && !disable) ? <DynamicModal
                buttonType={'Add'}
                buttonTitle={'Add New Role'}
                className={'secondary_color'}
                size={'small'}
                modalWidth={'md'}
              >
                <PesonnelRoleForm data={{}} personnelId={personnelId} sectionCrudAllowed={sectionCrudAllowed}/>
              </DynamicModal>
              : <span></span>
            }
          </CustomBox>
        </CustomGrid>
      </Box>
      {
        (roles && roles.length > 0) ?
          roles.map((role, id) => {
            return <CustomGrid key={id}>
              <PesonnelRoleItems fields={role} personnelId={personnelId} uid={uid}
                                 sectionCrudAllowed={sectionCrudAllowed}/>
            </CustomGrid>
          }) :
          (roles.length === 0)
            ? <PesonnelRoleForm data={{}} personnelId={personnelId} uid={uid} sectionCrudAllowed={sectionCrudAllowed}/>
            : <PesonnelRoleForm data={roles[0]} id={roles[0].id} personnelId={personnelId} uid={uid}
                                sectionCrudAllowed={sectionCrudAllowed}/>
      }
    </Box>
  )
}

export default PesonnelRoles;