import React, {useEffect, useState} from 'react';
import CustomCard from "../../containers/Card/CustomCard";
import TeamLastActivity from "../../stores/action/Team/TeamLastActivity";
import {useStore} from "../../stores/store";
import DataTable from "../../containers/DataTable/DataTable";
import jwt_decode from "jwt-decode";

const columnHeader = [
  {id: 'member_name', field: 'member_name', headerName: 'Name', flex: 0.2},
  {id: 'competency_name', field: 'competency_name', headerName: 'Competency', flex: 0.2},
  {id: 'valid_from_date', field: 'valid_from_date', headerName: 'Effective Date', flex: 0.2},
];

const LastActivity = (props) => {
  const {state, dispatch} = useStore();
  const [tableRows, setTableRows] = useState([]);
  const login_data = jwt_decode(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    TeamLastActivity(dispatch, login_data.user.id);
  }, []);


  useEffect(() => {
    if (state.team_last_activity_data.updated_team_last_activity) {
      dispatch({type: "TEAM_LAST_ACTIVITY_UPDATED", updated_team_last_activity: false});
      setTableRows(state.team_last_activity_data.team_last_activity);
    }

    if(tableRows.length > 0 || tableRows.length === 0) {
      setLoading(false);
    }
  }, [state.team_last_activity_data.updated_team_last_activity]);

  return (
    <CustomCard title={props.title}>
      <DataTable
        columns={columnHeader}
        rows={tableRows}
        pageSize={10}
        checkboxSelection
        disableSelect={true}
        hideFooter={true}
        loading={loading}
      />
    </CustomCard>
  )
};

export default LastActivity;