import React from "react";

export default function CompetencyCategoryFields(data = {}) {
  return {
    name: {
      field: "input",
      label: "Name",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.name : ''
    },
    code: {
      field: "input",
      label: "Code",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.code : ''
    },
    type_id_resource_type: {
      field: "select",
      multi: false,
      option: "types",
      label: "Resource Type",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.type_id_resource_type : '',
    },
    competency_id_award: {
      field: "select",
      multi: false,
      option: "competency",
      limiter: 'type_id_competency_type|36',
      label: "Competency Award",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.competency_id_award : '',
    },
    status_id: {
      field: "select",
      multi: false,
      // option: "status",
      option: [
        { id: 1, name: "Active", code: "ACTIVE" },
        { id: 2, name: "Disabled", code: "DISABLED" },
        { id: 3, name: "Archived", code: "ARCHIVED" },
        { id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED" },
      ],
      label: "Status",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
    },
  };
}