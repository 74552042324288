import React , {useEffect, useState} from "react";
import { Button, Typography } from '@material-ui/core';
import {dateFormat} from "../../../helpers/dateFormat";
import {booleanFormat} from "../../../helpers/booleanFormat";
import {RenderStateValue} from "../../../helpers/RenderStateValue";
import {RenderLinkIdValue} from "../../../helpers/RenderLinkIdValue";

const DynamicValue = (props) => {
  const {linkUpValue, type, value} = props
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {

    let typeToRender = type;

    if (type.includes("|")) {
      typeToRender = type.split("|")[0];
    }

    switch (typeToRender) {
      case "boolean":
        setNewValue(booleanFormat(value, type));
        break;
      case "date":
        setNewValue(dateFormat(value, type));

        if (type.includes("|")) {
          const secondType = type.split("|")[1];

          if (secondType === 'no_expiry' && value === null) {
            setNewValue('No Expiry');
          }

          if (secondType === 'no_start' && value === null) {
            setNewValue('No Start Date');
          }
        }

        break;
      case "render":
        setNewValue(<RenderStateValue type={type} value={value}/>);
        break;
      case "link":
        setNewValue(<Button href={value} target="_blank" rel="noopener" rel="noreferrer"  variant="contained" color="secondary" disableElevation>Visit Website</Button>);
        break;
      case "chip":
        setNewValue(<RenderStateValue type={'render|chip'} value={value}/>);
        break;
      case "link_id":
        setNewValue(<RenderLinkIdValue type={type} value={value} linkUpValue={linkUpValue}/>);
        break;
      case "override_status":

        setNewValue('Yes');

        if (value === 2) {
          setNewValue('No');
        }

        break
      case "default":
        setNewValue(value);
        break;
      default:
        setNewValue(value);
        break;
    }
  }, [type, value]);

  return (
    <Typography component={'span'} variant={'body2'}>{newValue}</Typography>
  )
}

export default DynamicValue;