import React from 'react';
import {useStore} from '../stores/store';
import {Chip} from '@material-ui/core';
import {stringReplace} from './stringReplace';

export function RenderLinkIdValue(props) {
  const {type, value, linkUpValue} = props;
  let stateToRender = type;
  let valueRender = value;
  const {state} = useStore();

  let linkUpValueName

  switch (linkUpValue)  {
    case 56:
      linkUpValueName = 'organisation_unit'
      break;
    case 57:
      linkUpValueName = 'vendor'
      break;
    case 58:
      linkUpValueName = 'position'
      break;
    case 59:
      linkUpValueName = 'role'
      break;
    case 60:
      linkUpValueName = 'cmid'
      break;
    default:
      linkUpValueName = ''
  }

  if (state[linkUpValueName + '_data'] && state[linkUpValueName + '_data'][linkUpValueName].length > 0) {
    state[linkUpValueName + '_data'][linkUpValueName].map((thisState, key) => {

      if (thisState.id === value) {
        valueRender = thisState.name;
      }

      if(valueRender === value) {
        valueRender = '';
      }
    });
  }

  if (!valueRender || typeof (valueRender) === 'undefined'  || typeof (valueRender) === 'number') {
    valueRender = 'N/A';
  }


  return valueRender;
}