import CustomCard from "../../../containers/Card/CustomCard";
import {
  Box,
  Button,
  CircularProgress, Divider,
  LinearProgress,
  List,
  ListItem,
  makeStyles,
  Typography,
  withStyles
} from "@material-ui/core";
import ProfileDetails from "../../ProfileDetails/ProfileDetails/ProfileDetails";
import React from "react";
import CustomGrid from "../../../containers/Grid/CustomGrid";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import TranscriptButton from "../../Transcript/TranscriptButton";

const ProgressPercentage = Math.floor(Math.random() * 100) + 1  ;

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: 15,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function LastActivity(props) {

  const classes = useStyles();

  return (
    <CustomCard
      title={''}
    >
      {/*<Box display={'flex'} justifyContent={'center'} alignItems={'center'} >*/}
      {/*  <Box m={1}>*/}
      {/*    <img src='../images/induction_1.jpg' alt="" width={'auto'} height={'90px'}/>*/}
      {/*  </Box>*/}
      {/*  <Box m={1} width="90%">*/}
      {/*    <h2>ATW007 Confined Space Entry Site Procedure</h2>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      color="primary"*/}
      {/*      endIcon={<PlayCircleOutlineIcon/>}*/}
      {/*    >*/}
      {/*      Resume*/}
      {/*    </Button>*/}
      {/*    <BorderLinearProgress variant="determinate" value={ProgressPercentage}/>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <Divider/>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >

        <Box m={1} width="90%">
          <h3>Download My Transcript</h3>
        </Box>
        <Box m={1} >
          <TranscriptButton id={props.id}/>
        </Box>
      </Box>
    </CustomCard>
  );
}