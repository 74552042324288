import React, {useState} from "react";
import moment from "moment";

export default function CompetencyFields(data = {}) {

  return {
    "name": {
      field: "input",
      label: "Name",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.name : ''
    },
    "code": {
      field: "input",
      label: "Code",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.code : ''
    },
    "type_id_competency_type": {
      field: "select",
      multi: false,
      option: "types",
      label: "Competency Type",
      required: true,
      dynamic: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.type_id_competency_type : '',
      className: "full",
    },
    "evidence_required": {
      field: "checkbox",
      label: "Evidence Required",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.evidence_required),
    },
    "self_enrol_available": {
      field: "checkbox",
      label: "Self-enrol Available",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.self_enrol_available),
    },
    "transferable": {
      field: "checkbox",
      label: "Transferable",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.transferable),
    },
    waitlist_available: {
      field: "checkbox",
      label: "Waitlist Required",
      validation: "boolean",
      initial_value: !!(Object.entries(data).length > 0 && data.waitlist_available),
    },
    date_range: {
      field: "date_range",
      label: "Validity Date Range",
      className: "full",
      date_range_fields: {
        valid_from_date:{
          field: "date",
          label: "Valid From",
          required: true,
          validation: "date|required",
          past: Object.entries(data).length > 0 && data.valid_from_date ? false : true,
          initial_value: Object.entries(data).length > 0 ?  data.valid_from_date : '',
        },
        valid_to_date:{
          field: "date",
          label: "Valid To",
          required: false,
          validation: "date",
          past: Object.entries(data).length > 0 && data.valid_to_date ? false : true,
          initial_value: Object.entries(data).length > 0 ? data.valid_to_date : '',
        }
      },
    },
    "validity_period": {
      field: "number_only",
      label: "Validity Period",
      required: true,
      validation: "integer|required|min:0",
      initial_value: Object.entries(data).length > 0 ? data.validity_period : 0,
    },
    status_id: {
      field: "select",
      multi: false,
      // option: "status",
      option: [
        { id: 1, name: "Active", code: "ACTIVE" },
        { id: 2, name: "Disabled", code: "DISABLED" },
        { id: 3, name: "Archived", code: "ARCHIVED" },
        { id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED" },
      ],
      label: "Status",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
    },
  };
};