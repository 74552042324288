const PersonnelRoleReducer = (state, action) => {
  switch (action.type) {
    case "PERSONNEL_ROLE":
      return {
        ...state,
        personnel_role: action.personnel_role,
      }
    case "PERSONNEL_ROLE_UPDATED":
      return {
        ...state,
        update_personnel_role: action.update_personnel_role,
      }
    default:
      return state;
  }
}

export default PersonnelRoleReducer;