import {FieldArray, Formik} from "formik";
import React from "react";
import {FormControl} from "@material-ui/core";

export default function CustomFieldArray(props) {
    const {
        className,
        // id,
        // title,
        // variant,
        // margin,
        // elements,
        // defaultValue,
        // required
    } = props;


    return (
        <Formik
            initialValues={{selected: ['',]}}

            render={({values}) => (
                <FieldArray
                    name="selected"
                    render={arrayHelpers => (
                        <>
                            {values.selected && values.selected.length > 0 ? (
                                values.selected.map((friend, index) => (
                                    <FormControl className={className + ' flexRowAuto'} variant={'outlined'} required={true} margin={'normal'} key={index}>
                                            <CustomDestination/>
                                            <button
                                                className={'buttonMinus'}
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                                -
                                            </button>
                                            <button
                                                className={'buttonAdd'}
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                            >
                                                +
                                            </button>
                                    </FormControl>
                                ))
                            ) : (
                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                    Add a Destination
                                </button>
                            )}
                        </>
                    )}
                />
            )}
        />
    );
};