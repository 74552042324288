import React, {useState} from "react";
import BaseForm from "../../UI/Form/FormType/BaseForm";
import Field from "../../UI/Form/Field/Field";
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";

//TO BUILD A FORM
import SystemSettings from "../../../stores/action/System/Settings";
import SystemColorsFields from "./SystemColorsFields";
import Validation from '../../../stores/action/Form/Validation';
import {Post} from '../../../stores/action/Form/Post';

const SystemColors = (props) => {
    const [stateLocal, setstateLocal] = useState({});
    const form = SystemColorsFields();
    const customFunc = (values) => (Post(form.fields, setstateLocal, form.endpoint));
    const field = Validation(customFunc, form, SystemSettings);

    return (
        <BaseForm
            stateLocal={stateLocal}
            setstateLocal={setstateLocal}
            onSubmit={field.handleSubmit}
        >
            {Field(form.fields, field)}
            <GroupSubmit/>

        </ BaseForm>
    );
};

export default SystemColors;