import React from 'react';
import {Grid} from "@material-ui/core";

function autoAlign(align = null) {
  let alignment;

  switch (align) {
    case 'right':
      alignment = 'flex-end';
      break
    case 'left':
      alignment = 'flex-start';
      break
    case 'center':
      alignment = 'space-around';
      break
    case 'even':
      alignment = 'space-evenly';
      break
    default:
      alignment = 'space-between';
      break
  }

  return alignment;

}

const CustomGrid = (props) => {
  const {children, className, auto, align, spacing, alignItems} = props;
  const autoAlignment = () => {return auto ? auto : true}
  return (
    <Grid
      className={className}
      container
      direction="row"
      justifyContent={autoAlign(align)}
      alignItems={alignItems ? alignItems : "stretch"}
      spacing={(spacing) ? spacing : 3}
    >
      {
        children && Array.isArray(children) ? (
            children && children.map((child, id) => {
              return <Grid key={id} item xs={ autoAlignment() }>
                {child}
              </Grid>
            })
          ) :
          (<Grid item xs={ autoAlignment() }>{children}</Grid>)
      }
    </Grid>
  );
};

export default CustomGrid;
