import React from "react";
import {isEmptyChildren} from "formik";
import {Button, Typography} from "@material-ui/core";
import CustomBox from "../../../containers/Box/CustomBox";
import CustomGrid from "../../../containers/Grid/CustomGrid";
import {useHistory} from "react-router-dom";

const TitleButtons = (props) => {
  const {title, button} = props;
  let history = useHistory();

  return (
    <CustomGrid auto={false}>
      <CustomBox>
        <Typography variant={"h6"}> {title}</Typography>
      </CustomBox>
      <CustomBox align={'right'}>
        {
          isEmptyChildren(props.children)
            ? button && button.map((child, id) => {
            return (
              <Button key={id} variant={"contained"} color={child.color} onClick={() => {
                history.push(child.link);
              }}>
                {child.title}
              </Button>
            );
          })
            : props.children
        }
      </CustomBox>
    </CustomGrid>
  );
};

export default TitleButtons;
