const usersReducer = (state, action) => {
    switch (action.type) {
        case "USERS":
            return {
                ...state,
                users: action.users
            }
        case "USERS_UPDATED":
            return {
                ...state,
                users_updated: action.users_updated
            }
        default:
            return state;
    }
}

export default usersReducer;