import React, {useEffect, useState} from "react";
import {Post} from '../../../stores/action/Form/Post';
import {Update} from "../../../stores/action/Form/Update";
import {useHistory} from "react-router-dom";
import {useStore} from "../../../stores/store";
import ProfileDetailsFields from "./ProfileDetailsFields";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import Address from "../../Address/Address";
import jwt_decode from "jwt-decode";

const ProfileDetails = (props) => {
  let history = useHistory();
  const {state, dispatch} = useStore();
  const {uid, userInformation, disableField, crudAllowed} = props
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (uid && uid !== 'create') ? 'api/v1/user-detail/' + uid : 'api/v1/user-detail';
  const fields = ProfileDetailsFields(uid, userInformation, disableField, crudAllowed);
  const profileFunc = values => (
    uid && uid !== 'create'
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );

  const formik = Validation(fields, profileFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldThird'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return (
            <FieldBuilder
              key={fieldKey}
              field={fieldKey}
              fieldAttributes={fields[fieldKey]}
              validation={formik}
            />
          )
        })
      }
      {
        (!disableField) &&
        <GroupSubmit
          className={'GroupSubmit'} reset={false}
          auto={true} align={'right'} submitText={'Update'}
          formik={formik}
        />
      }
    </Form>
  )
}

export default ProfileDetails;