import axiosApiInstance from "../../../helpers/axiosConfig";

const TableData = (endpoint, orderBy, order, perPage, page, setTableCollection,  user_id = null, search = {}, limiter ={}) => {
  let apiLink = process.env.REACT_APP_API_DOMAIN + endpoint + 'search';

  if(page > 0) {
    const newPage = (page + 1);
    apiLink = process.env.REACT_APP_API_DOMAIN + endpoint + 'search?page=' + newPage;
  }

  const requestBody = {
    pagination: {
      orderBy:orderBy,
      order:order,
      perPage:perPage,
      page:page
    },
    search: search,
    limiter: limiter,
    user_id: user_id
  }

  axiosApiInstance
    .post(apiLink, requestBody)
    .then(res => {

      if (res.status === 204 || res.status === 404) {
        setTableCollection('no data');
      } else {
        setTableCollection(res.data);
      }

    })
    .catch((error) => {
      setTableCollection('no data');
      console.error(error.message);
    });
};

export default TableData;