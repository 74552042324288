const statusReducer = (state, action) => {
  switch (action.type) {
    case "STATUS":
      return {
        ...state,
        status: action.status,
      }
    case "STATUS_UPDATED":
      return {
        ...state,
        status_updated: action.status_updated,
      }
    default:
      return state;
  }
}

export default statusReducer;