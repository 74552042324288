import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AuthContainer from "../../containers/Container/Auth/Auth";
import Logo from "../Logo/LogoAuth";
import Submit from "../UI/Button/SubmitButton";
import {LoginDetails} from "../../stores/action/Login/Login";
import Form from "../Form/Form";
import FieldBuilder from "../Form/Fields/FieldBuilder";
import Validation from "../../stores/action/Form/Validation";
import {useStore} from "../../stores/store";

const loginFields = () => {
  return {
    "username": {
      "field": "input",
      "label": "username",
      "hidden": false,
      "required": true,
      "validation": "string|required|min:3|max:50",
      "initial_value": ""
    },
    "password": {
      "field": "password",
      "label": "password",
      "hidden": false,
      "required": true,
      "validation": "string|required|min:5|max:12",
      "initial_value": ""
    }
  }
};

export default function Login() {
  const fields = loginFields();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = "api/auth/login";
  let history = useHistory();

  const customFunc = (values) => LoginDetails(values, setstateLocal, endpoint, history);
  const formik = Validation(fields, customFunc);

  return (
    <AuthContainer containerSize={"sm"}>
      <Logo width={`auto`} height={'100px'}/>
      <Form
        stateLocal={stateLocal}
        setstateLocal={setstateLocal}
        className={'fieldFull'}
        onSubmit={formik.handleSubmit}
      >
        {fields &&
        Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder key={fieldKey} field={fieldKey} fieldAttributes={fields[fieldKey]} validation={formik} />;
        })}

        <div className={"MuiFormControl-root"}>
          <Submit fullwidth>Login</Submit>
        </div>

      </Form>

    </AuthContainer>
  )
}