import * as React from 'react';
import AppContainer from "../../containers/Container/App/Main";
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import PersonnelForm from "../Personnel/Form/PersonnelForm";
import {useStore} from "../../stores/store";
import {useEffect, useState} from "react";
import {setupCrud} from "../../helpers/setupCrud";
import {useHistory} from "react-router-dom";

const columnHeader = [
  {
    id: "status_id",
    renderType: 'render|status',
    align: 'left',
    disablePadding: false,
    label: "Status",
    setWidth: '10%'
  },
  {
    id: "external_personnel_identifier",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "External Personnel Identifier",
    setWidth: '20%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "first_name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "First Name",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "last_name",
    renderType: 'default',
    align: 'left',
    disablePadding: false,
    label: "Last Name",
    setWidth: '15%',
    searchable: true,
    searchType: 'text'
  },
  {
    id: "start_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "Start Date",
    setWidth: '10%',
  },
  {
    id: "end_date",
    renderType: 'date|default',
    align: 'left',
    disablePadding: false,
    label: "End Date",
    setWidth: '10%',
  },
  {
    id: "type_id_resource_type",
    renderType: 'render|types',
    align: 'left',
    disablePadding: false,
    label: "Resource",
    setWidth: '15%',
    searchable: true,
    searchType: 'select|types',
    selectLimiter: 'category_id|6'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
];


const AdminPersonnel = (props) => {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const buttonEndpoint = 'api/v1/personnel/';
  let sortBy = "status_id";
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));
  }, [state.user_data.menu_updated]);

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <DynamicTable
        tableTitle={'Personnel'}
        filter={!!(crud.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        addButton={!!(crud.create)}
        editButton={!!(crud.update)}
        deleteButton={!!(crud.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<PersonnelForm/>}
      />
    </AppContainer>
  );
}

export default AdminPersonnel;