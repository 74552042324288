const competencyReducer = (state, action) => {
    switch (action.type) {
        case "COMPETENCY":
            return {
                ...state,
                competency: action.competency
            }
        case "COMPETENCY_UPDATED":
            return {
                ...state,
                competency_update: action.competency_update,
            }
        case "COMPETENCY_COMPETENCY_CATEGORY":
            return {
                ...state,
                competency_competency_category: action.competency_competency_category
            }
        case "COMPETENCY_COMPETENCY_CATEGORY_UPDATED":
            return {
                ...state,
                competency_competency_category_update: action.competency_competency_category_update,
            }
        default:
            return state;
    }
}

export default competencyReducer;