import React from "react";
import UserPersonnel from "../../Personnel/UserPersonnel";
import PersonnelPositions from "../../PesonnelPositions/PersonnelPositions";
import PesonnelRoles from "../../PesonnelRoles/PesonnelRoles";

const EmploymentDetails = (props) => {
  const {uid, cmid, positions, roles, personnelId, personnel, sectionCrudAllowed} = props;

  return (
    <>
      {
        (!!sectionCrudAllowed['USER_PERSONNEL'].crud.read) &&
        <UserPersonnel uid={uid} personnel={personnel} cmid={cmid} sectionCrudAllowed={sectionCrudAllowed}/>
      }
      {
        (!!sectionCrudAllowed['USER_POSITION'].crud.read) &&
        <PersonnelPositions uid={uid} positions={positions} personnelId={personnelId}
                            sectionCrudAllowed={sectionCrudAllowed}/>
      }
      {
        (!!sectionCrudAllowed['USER_ROLES'].crud.read) &&
        <PesonnelRoles uid={uid} roles={roles} personnelId={personnelId} sectionCrudAllowed={sectionCrudAllowed}/>
      }
    </>
  )
};

export default EmploymentDetails;