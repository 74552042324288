import React from 'react';
import MainLogo from '../../assets/logo/South32_logo.svg';
import {Box} from '@material-ui/core';

const logo = (props) => {
  return (
    <Box my={1}>
      <img src={MainLogo} alt="LMS" width={props.width} height={props.height} color={'white'}/>
    </Box>
  );
};

export default logo;