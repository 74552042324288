import React, {useState} from "react";
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import CompanyDetailsFields from "./CompanyDetailsFields";
import Validation from '../../../stores/action/Form/Validation';
import {Post} from '../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import {Skeleton} from "@material-ui/lab";

const CompanyDetails = (props) => {
  let history = useHistory();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = '/api/v1/system/setting';
  const fields = CompanyDetailsFields(props.fields);
  const settingFunc = (values) => (Post(values, setstateLocal, endpoint, history));
  const formik = Validation(fields, settingFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldThird'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        })
      }

      {
        fields && Object.entries(fields).length > 0 ?
          <GroupSubmit
            className={'GroupSubmit'} auto={true} right={true} submitText={'Update'} reset={true}
            resetText={'Delete'} formik={formik}
          /> :
          <Skeleton variant="rect" width={'100%'}/>
      }
    </Form>
  );
};

export default CompanyDetails;