import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(props) {
  const {color, height, width, background, size, thickness } = props;

  const loaderColor = (color) ? color : 'primary';
  const loaderHeight = (height) ? height : 150;
  const loaderWidth = (width) ? width : '100%';
  const loaderBackground = (background) ? background : 'rgba(0, 0, 0, 0)';
  const loaderThickness = (thickness) ? thickness : 4;
  const loaderSize = (size) ? size : 70;

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: loaderHeight,
      width: loaderWidth,
      background: loaderBackground
    }}>
      <CircularProgress size={loaderSize} thickness={loaderThickness} color={loaderColor}/>
    </div>
  );
}