import axiosApiInstance from "../../../../helpers/axiosConfig";
import setAlertMessage from "../../Alert/alert";

const CsvTranscript = (version, apiLink, filename, dispatch, setState) => {
  const requestBody = {
    version: version,
  }

  axiosApiInstance
    .post(apiLink, requestBody)
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch({type: "GLOBAL_UPDATE", global_update: true});
    })
    .catch((_error) => {
      const msg = {
        type: 'error',
        message: 'An error has occurred while trying to generate the transcript.'
      }
      dispatch({type: "GLOBAL_UPDATE", global_update: true});

      setAlertMessage(msg, setState);
    });
}

export default CsvTranscript;