import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import NavigationItem from './NavigationItem/NavigationItem';
import {useStore} from "../../../stores/store";
import {stringReplace} from "../../../helpers/stringReplace";

function renderMenu(menu, className) {
  let menuItems = [];

  if (typeof (menu) !== 'undefined') {
    menuItems = menu.map((item, id) => {
      return (item.crud.read === 1) && <NavigationItem
        key={stringReplace(item.url, 'lowercase', true)}
        className={className}
        link={item.url}
      >
        {item.name}
      </NavigationItem>
    })
  }

  return menuItems;
}

function renderAdminMenu(menu, className) {
  let isAdmin = false
  let adminMenu = null

  if (typeof (menu) !== 'undefined') {
    menu.map((item) => {
      if (item.crud.read === 1) {
        isAdmin = true;
      }
    })

    adminMenu = isAdmin && <React.Fragment>
        <Divider/>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader className={className} component="div" id="nested-list-subheader">
              Administrator
            </ListSubheader>
          }
        >

          {
            menu.map((item, id) => {
              return (item.crud.read === 1) && <NavigationItem
                key={stringReplace(item.url, 'lowercase', true)}
                className={className}
                link={item.url}
              >
                {item.name}
              </NavigationItem>
            })
          }
        </List>
      </React.Fragment>
  }

  return adminMenu;
}

const NavigationItems = (props) => {
  const {state} = useStore();
  const [menuItem, setMenuItem] = useState([]);

  useEffect(() => {
    if (state.user_data.menu_updated) {
      setMenuItem(state.user_data.menu);
    }
  }, [state.user_data]);

  return (
    <div className={'main_background'}>
      <List component="nav">
        {
          renderMenu(menuItem['main'], props.className)
        }
      </List>

      {
        renderAdminMenu(menuItem['admin'], props.className)
      }
    </div>
  );
};

export default NavigationItems;