const roleReducer = (state, action) => {
  switch (action.type) {
    case "ROLE":
      return {
        ...state,
        role: action.role,
      }
    case "ROLE_UPDATED":
      return {
        ...state,
        update_role: action.update_role,
      }
    default:
      return state;
  }
}

export default roleReducer;