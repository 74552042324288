import React, {useEffect, useState} from 'react';
import DynamicTable from "../../containers/DataTable/DynamicTable/DynamicTable";
import AccessLevelForm from "./Forms/AccessLevelForm";

const columnHeader = [
  // {
  //   id: "id",
  //   renderType: 'default',
  //   align: 'left',
  //   disablePadding: false,
  //   label: "ID",
  //   setWidth: '5%',
  // },
  {
    id: "menu_id",
    renderType: 'render|menus',
    align: 'left',
    disablePadding: false,
    label: "Menu",
    setWidth: '25%',
    searchable: true,
    searchType: 'select|menus',
    selectExclude: 'status_id|12',
    searchWidth: 6,
  },
  {
    id: "access_level_id",
    renderType: 'render|access_level',
    align: 'left',
    disablePadding: false,
    label: "Security Role",
    setWidth: '25%',
    searchable: true,
    searchType: 'select|access_level',
    selectExclude: 'status_id|12',
    searchWidth: 6,
  },
  {
    id: "read",
    renderType: 'boolean|icon',
    align: 'left',
    disablePadding: false,
    label: "View",
    setWidth: '10%',
  },
  {
    id: "create",
    renderType: 'boolean|icon',
    align: 'left',
    disablePadding: false,
    label: "Add",
    setWidth: '10%',
  },
  {
    id: "update",
    renderType: 'boolean|icon',
    align: 'left',
    disablePadding: false,
    label: "Edit",
    setWidth: '10%',
  },
  {
    id: "delete",
    renderType: 'boolean|icon',
    align: 'left',
    disablePadding: false,
    label: "Delete",
    setWidth: '10%',
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '10%'},
];

const AccessLevels = (props) => {
  const {title, disableField, crudAllowed} = props;
  const buttonEndpoint = 'api/v1/access-allocation/';
  let sortBy = "id";

  return (
    <DynamicTable
      tableTitle={title}
      filter={!!(crudAllowed.read)}
      headCells={columnHeader}
      sortBy={sortBy}
      addButton={!!(crudAllowed.create)}
      editButton={!!(crudAllowed.update)}
      deleteButton={!!(crudAllowed.delete)}
      buttonEndpoint={buttonEndpoint}
      form={<AccessLevelForm/>}
      modalWidth={'md'}
    />
  );
};

export default AccessLevels;