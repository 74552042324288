import React, {useEffect, useState} from 'react';
import CustomCard from "../../containers/Card/CustomCard";
import {useStore} from "../../stores/store";
import TeamCompetency from "../../stores/action/Team/TeamCompetency";
import {Paper, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {setValidationColor} from "../../helpers/setValidationColor";
import TeamTranscriptButton from "../Transcript/TeamTranscriptButton";

const tableRows = (data) => {
  let users_competencies = [];

  Object.values(data.result) && Object.entries(data.result).map(([user_id, rowValue]) => {
    users_competencies[user_id] = {
      "user_name": rowValue.member_name,
      "competencies": rowValue.competencies,
    };
  });

  return users_competencies;
};

function getString(column) {

  let prefix = column.competency_prefix;

  if (typeof column.expires_in === 'number') {
    return prefix + 'Expires in ' + column.expires_in + ' days'
  }

  if (typeof column.expires_in === 'string') {
    return prefix + column.expires_in
  }
}

const TeamCompetencies = (props) => {

  const {state, dispatch} = useStore();
  const [teamCompetencies, setTeamCompetencies] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    TeamCompetency(dispatch);
  }, []);

  useEffect(() => {
    if (state.team_competency_data.updated_team_competency) {
      dispatch({type: "TEAM_COMPETENCY_UPDATED", updated_team_competency: false});
      setTeamCompetencies(state.team_competency_data.team_competency);
    }

    if (Object.entries(teamCompetencies).length > 0) {
      setLoading(false);
    }
  }, [loading, state.team_competency_data.updated_team_competency]);

  return (

    <CustomCard title={props.title} className={'teamCompetencyTable'}>
      {
        (!loading && Object.entries(teamCompetencies).length > 0) &&
        <Paper style={{maxWidth: '100%', overflow: 'auto'}}>
          {!loading && <TeamTranscriptButton />}
          <Grid container direction={'row'} wrap={'nowrap'}>
            <Grid container item xs={false} style={{border: '1px solid #000', minWidth: '150px'}}>
            </Grid>
            {
              teamCompetencies.header && Object.entries(teamCompetencies.header).map(([key, header_data]) => {
                return <Grid key={key}>
                  <Grid container item xs={false} justifyContent={'space-around'}
                        style={{
                          borderTop: '1px solid #000',
                          borderLeft: '0',
                          borderRight: '1px solid #000',
                          minWidth: '250px',
                          maxHeight: '50px'
                        }}>
                    <Typography style={{
                      margin: '5px',
                    }}> {key.toUpperCase()}</Typography>
                  </Grid>
                  <Grid container wrap={'nowrap'}>
                    {
                      header_data && Object.entries(header_data).map(([id, header]) => {

                        return <Grid key={id + header} item xs={false} style={{
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                          borderRight: '1px solid #000',
                          width: '250px',
                          minHeight: '90px'
                        }}>
                          <Typography style={{
                            margin: '5px',
                          }}>
                            {header}
                          </Typography>
                        </Grid>
                      })
                    }
                  </Grid>
                </Grid>
              })
            }
          </Grid>
          {
            tableRows(teamCompetencies) && tableRows(teamCompetencies).map((data_rows, key) => {
              let competencies = data_rows.competencies;

              return <Grid key={key} container direction={'row'} wrap={'nowrap'}>
                <Grid item xs={false} style={{border: '1px solid #000', minWidth: '150px'}}>
                  {data_rows.user_name}
                </Grid>
                {
                  competencies && Object.entries(competencies).map(([_id, data_columns]) => {
                    return Object.entries(data_columns).map(([ref, column]) => {

                      return <Grid
                        key={ref}
                        container
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        className={'cellBg ' + setValidationColor(column.expires_in)}
                        style={{
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                          borderRight: '1px solid #000',
                          minWidth: '250px',
                        }}
                      >
                        <Typography>
                          {getString(column)}
                        </Typography>
                      </Grid>
                    })
                  })
                }
              </Grid>
            })
          }
        </Paper>
      }
    </CustomCard>
  )
};

export default TeamCompetencies;