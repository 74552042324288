const globalUpdateReducer = (state, action) => {


  switch (action.type) {

    case "GLOBAL_UPDATE":
      return {
        ...state,
        global_update: action.global_update
      }
    default:
      return state;
  }
}

export default globalUpdateReducer;