import {useFormik} from "formik";
import * as yup from "yup";

const Test = (customFunc) => {

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email address')
            .email('Invalid email address')
            .required('Email address is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (values) => {
            customFunc(values);
        },
    });

    return formik;
};

export default Test;
