const typeReducer = (state, action) => {
  switch (action.type) {
    case "TYPE":
      return {
        ...state,
        types: action.types,
      }
    case "TYPE_UPDATED":
      return {
        ...state,
        types_updated: action.types_updated
      }
    default:
      return state;
  }
}

export default typeReducer;