import React from 'react';
import AppContainer from "../../containers/Container/App/Main";
import CustomGrid from '../../containers/Grid/CustomGrid';
import RecentCompetencies from "../Competencies/RecentCompetencies";
import FaceToFace from '../UI/Card/FaceToFace';
import AvailableEnrollments from '../UI/Card/AvailableEnrollments';
import LastActivity from "../UI/Card/LastActivity";
import Loading from "../UI/Loading/Loading";
import CustomChart from "../UI/Chart/Chart";
import NewOutstandingRequirements from "../UI/Card/NewOutstandingRequirements";
import {useStore} from "../../stores/store";
import jwt_decode from "jwt-decode";
import CustomBox from "../../containers/Box/CustomBox";
import RequiredCompetencies from "../Competencies/RequiredCompetencies";

const Dashboard = () => {
  const {dispatch} = useStore();
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  let decoded = jwt_decode(login_data.access_token);

  return (
    <AppContainer>
      {!login_data && <Loading/>}
      {login_data && <>
        <CustomGrid>
          {(decoded.sub) && <LastActivity id={decoded.sub}/>}
        </CustomGrid>
        {<CustomGrid>
          <CustomBox direction={'column'}>
            <RequiredCompetencies uid={decoded.sub}/>
            <RecentCompetencies uid={decoded.sub}/>
            {/*<FaceToFace/>*/}
            {/*<AvailableEnrollments/>*/}
          </CustomBox>
        </CustomGrid>}
        {/*<NewOutstandingRequirements/>*/}
        {/*<h4>Available Enrollments</h4>*/}
        {/*<CustomChart/>*/}
      </>}
    </AppContainer>
  )
}
;

export default Dashboard;
