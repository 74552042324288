import React from "react";

export default function CompanyDetailsFields(data) {
    return {
        "company_name": {
            "field": "input",
            "label": "Company Name",
            "required": true,
            "validation": "string|required",
            "initial_value": (typeof (data) === 'undefined') ?  '' : data.company_name
        },
        "support_number": {
            "field": "input",
            "label": "Support Number",
            "required": true,
            "validation": "string|required",
            "initial_value": (typeof (data) === 'undefined') ?  '' : data.support_number
        },
        "support_email": {
            "field": "email",
            "label": "Support Email",
            "required": true,
            "validation": "string|required",
            "initial_value": (typeof (data) === 'undefined') ?  '' : data.support_email
        },
    };

};