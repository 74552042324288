import React from 'react';

import mainLogo from '../../assets/logo/south32.svg';
import {Box} from '@material-ui/core';

const logo = (props) => (
  <Box my={1}>
    <img src={mainLogo} alt="LMS" width={props.width} height={props.height}/>
  </Box>
);

export default logo;