import React from "react";

export default function PersonnelFields(data = {}, uid=null, cmid, disable = true, sectionCrudAllowed = []) {
  return {
    user_id: {
      field: "input",
      label: "user_id",
      disable: disable,
      hidden: true,
      initial_value: uid,
    },
    cm_id: {
      field: "input",
      label: "CMID",
      initial_value: cmid ? cmid : '',
      className: "half",
      disable: true,
      hidden: (!sectionCrudAllowed['USER_PERSONNEL'].crud.read),
    },
    location_id: {
      field: "input",
      label: "location_id",
      required: false,
      disable: true,
      hidden: true,
      initial_value: null,
    },
    external_personnel_identifier: {
      field: "external_personnel_identifier",
      label: "Personnel Identifier",
      initial_value: (Object.entries(data).length > 0) ? data.external_personnel_identifier : '',
      className: 'half',
      disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
    },
    type_id_resource_type: {
      field: "select",
      multi: false,
      option: "types",
      limiter: 'category_id|6',
      label: "Personnel Type",
      required: true,
      disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.type_id_resource_type) ? data.type_id_resource_type : '',
      className: 'half'
    },
    vendor_id: {
      field: "autocomplete",
      multi: false,
      option: "vendor",
      label: "Company",
      required: true,
      disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0 && data.vendor_id) ? data.vendor_id : '',
      className: 'half'
    },
    date_range: {
      field: "date_range",
      label: "Date Range",
      className: "full",
      date_range_fields: {
        start_date:{
          field: "date",
          label: "Start Date",
          required: true,
          disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
          validation: "date|required",
          past: !(Object.entries(data).length > 0 && data.start_date),
          initial_value: Object.entries(data).length > 0 ?  data.start_date : '',
        },
        end_date:{
          field: "date",
          label: "End Date",
          required: true,
          disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
          past: !(Object.entries(data).length > 0 && data.end_date),
          validation: "date|nullable",
          initial_value: Object.entries(data).length > 0 ? data.end_date : '',
        }
      },
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        { id: 1, name: "Active", code: "ACTIVE" },
        { id: 2, name: "Disabled", code: "DISABLED" },
        { id: 3, name: "Archived", code: "ARCHIVED" },
        { id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED" },
      ],
      label: "Status",
      required: true,
      disable: (disable ? disable : !sectionCrudAllowed['USER_PERSONNEL'].crud.update || !sectionCrudAllowed['USER_PERSONNEL'].crud.create),
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: 'full'
    },
  };
};