import React, {useState} from "react";

export default function PesonnelRoleFields(data = {}, personnelId= null, disable = true, sectionCrudAllowed= []) {

  return {
    personnel_id: {
      field: "input",
      label: "personnel_id",
      disable: true,
      hidden: true,
      initial_value: (Object.entries(data).length > 0 && data.personnel_id) ? data.personnel_id : personnelId,
    },
    role_id: {
      field: "autocomplete",
      multi: false,
      option: "role",
      label: "Role",
      required: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.role_id) ? data.role_id : '',
      hidden: (!sectionCrudAllowed['USER_ROLES'].crud.read),
      disable: (disable ? disable : !sectionCrudAllowed['USER_ROLES'].crud.update || !sectionCrudAllowed['USER_ROLES'].crud.create),
      className: 'half'
    },
    // organisation_unit_id: {
    //   field: "select",
    //   multi: false,
    //   option: "organisation_unit",
    //   limiter: 'type_id_organisation_unit_type|34',
    //   label: "Department",
    //   disable: true,
    //   initial_value: (Object.entries(data).length > 0 && data.organisation_unit_id) ? data.organisation_unit_id : '',
    //   className: 'half'
    // },
    // type_id_position_type: {
    //   field: "select",
    //   multi: false,
    //   option: "types",
    //   limiter: 'category_id|5',
    //   label: "Department",
    //   disable: true,
    //   initial_value: (Object.entries(data).length > 0 && data.type_id_position_type) ? data.type_id_position_type : '',
    //   className: 'half'
    // },
    date_range: {
      field: "date_range",
      label: "Date Range",
      className: "full",
      date_range_fields: {
        start_date:{
          field: "date",
          label: "Start Date",
          required: true,
          disable: (disable ? disable : !sectionCrudAllowed['USER_ROLES'].crud.update || !sectionCrudAllowed['USER_ROLES'].crud.create),
          validation: "date|required",
          past: !(Object.entries(data).length > 0 && data.start_date),
          initial_value: Object.entries(data).length > 0 ?  data.start_date : '',
        },
        end_date:{
          field: "date",
          label: "End Date",
          required: true,
          disable: (disable ? disable : !sectionCrudAllowed['USER_ROLES'].crud.update || !sectionCrudAllowed['USER_ROLES'].crud.create),
          past: !(Object.entries(data).length > 0 && data.end_date),
          validation: "date|required",
          initial_value: Object.entries(data).length > 0 ? data.end_date : '',
        }
      },
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        { id: 1, name: "Active", code: "ACTIVE" },
        { id: 2, name: "Inactive", code: "INACTIVE" },
      ],
      label: "Status",
      required: true,
      disable: (disable ? disable : !sectionCrudAllowed['USER_ROLES'].crud.update || !sectionCrudAllowed['USER_ROLES'].crud.create),
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: 'full'
    },
  };
};