import React from 'react';
import CustomGrid from "../../containers/Grid/CustomGrid";
import Box from "@material-ui/core/Box";
import EmergencyContactItem from "./EmergencyContactItem";
import EmergencyContactEdit from "./EmergencyContactEdit";
import CustomBox from "../../containers/Box/CustomBox";
import {Typography} from "@material-ui/core";
import DynamicModal from "../../containers/DataTable/DynamicTable/DynamicModal";
import PesonnelRoleForm from "../PesonnelRoles/Form/PesonnelRoleForm";

const EmergencyContacts = (props) => {
  const {emergencyContacts, userId, disableField, crudAllowed} = props

  return (
    <Box component={'div'} p={3}>
      <Box component={'div'} className={'titleBackground primary_color'}>
        <CustomGrid auto={false} alignItems={'center'}>
          <CustomBox className={'noMargin'}>
            <Typography color={'secondary'} variant={'caption'}> {'Emergency Contacts'}</Typography>
          </CustomBox>
          <CustomBox className={'noMargin'} align={'right'}>
            {(emergencyContacts && emergencyContacts.length > 0 && emergencyContacts.length < 3 && !disableField) ? <DynamicModal
                buttonType={'Add'}
                buttonTitle={'Add Emergency Contacts'}
                className={'secondary_color'}
                size={'small'}
                modalWidth={'md'}
              >
                <EmergencyContactEdit data={{}} userId={userId} disableField={disableField}
                                      crudAllowed={crudAllowed}/>
              </DynamicModal>
              : <span></span>
            }
          </CustomBox>
        </CustomGrid>
      </Box>
      {
        emergencyContacts && emergencyContacts.length > 0 ?
          emergencyContacts.map((emergencyContact, id) => {
            return <CustomGrid key={id}>
              <EmergencyContactItem fields={emergencyContact} userId={userId} disableField={disableField}
                                    crudAllowed={crudAllowed}/>
            </CustomGrid>
          }) :
          <EmergencyContactEdit data={{}} userId={userId} disableField={disableField}
                                crudAllowed={crudAllowed}/>
      }
    </Box>
  );
}

export default EmergencyContacts;