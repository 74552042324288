import React from "react";
import moment from "moment";

export default function EmergencyContactFields(data = {}, userId, disableField = true, crudAllowed = []) {
    const userInformation = data.information;

    return {
        "user_id": {
            "field": "input",
            "hidden": true,
            "required": false,
            "initial_value": userId
        },
        "user_detail_id": {
            "field": "input",
            "hidden": true,
            "required": false,
            "initial_value": (Object.entries(data).length > 0) ? data.user_detail_id : ''
        },
        "priority": {
            "field": "select",
            "multi": false,
            "option": [{id:1,name:"First"}, {id:2,name:"Second"},{id:3,name:"Third"}],
            "label": "Priority",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "dynamic": true,
            "validation": "integer|required",
            "className": "half",
            "initial_value": (Object.entries(data).length > 0)? data.priority : ''
        },
        "relationship": {
            "field": "select",
            "option": 'relationship',
            "label": "Relationship",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "dynamic": true,
            "validation": "integer|required",
            "className": "half",
            "initial_value": (Object.entries(data).length > 0) ? data.relationship.id : ''
        },
        "first_name": {
            "field": "input",
            "label": "Name",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string|required",
            "className": "half",
            "initial_value": (Object.entries(data).length > 0)? userInformation.first_name : ''
        },
        "middle_name": {
            "field": "input",
            "label": "Middle Name",
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string",
            "className": "half",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.middle_name : ''
        },
        "preferred_name": {
            "field": "input",
            "label": "Preferred Name",
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "className": "half",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.preferred_name : ''
        },
        "last_name": {
            "field": "input",
            "label": "Surname",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string|required",
            "className": "half",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.last_name : ''
        },
        "email_address": {
            "field": "email",
            "label": "Email",
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string|email|nullable",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.email : ''
        },
        "phone": {
            "field": "phone",
            "label": "Phone",
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "integer|phone|nullable",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.phone : ''
        },
        "mobile": {
            "field": "mobile",
            "label": "Mobile",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "integer|phone",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.mobile : ''
        },
        "gender_id": {
            "field": "select",
            "option": 'gender',
            "label": "Gender",
            "required": false,
            "hidden": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string|nullable",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.gender_id : 3
        },
        "date_of_birth": {
            "field": "date",
            "label": "Date of birth",
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "past": false,
            "future": true,
            "validation": "date",
            "initial_value": (userInformation && userInformation.date_of_birth) ? userInformation.date_of_birth : ''
        },
        "seperator": {
            field: "seperator",
            label: "Address",
            className: "full seperator",
        },
        "address_id": {
            "field": "input",
            "hidden": true,
            "required": false,
            "disable": disableField && !(crudAllowed.edit),
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.id : ''
        },
        "address_line_1": {
            "field": "input",
            "label": "Unit or Street Number",
            "required": false,
            "validation": "string|nullable",
            "disable": disableField && !(crudAllowed.edit),
            "initial_value": (Object.entries(data).length > 0) ?  userInformation.address.address_line_1 : ''
        },
        "address_line_2": {
            "field": "input",
            "label": "Street Name",
            "required": true,
            "validation": "string|required",
            "disable": disableField && !(crudAllowed.edit),
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.address_line_2 : ''
        },
        "suburb": {
            "field": "input",
            "label": "Suburb",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "string|required",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.suburb : ''
        },
        "postcode": {
            "field": "input",
            "label": "Postcode",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "integer|required",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.postcode : ''
        },
        "state_id": {
            "field": "select",
            "multi": false,
            "option": "state",
            "label": "State",
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "dynamic": true,
            "validation": "integer|required",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.state_id : ''
        },
        "country_id": {
            "field": "select",
            "multi": false,
            "option": "country",
            "label": "Country",
            "dynamic": true,
            "required": true,
            "disable": disableField && !(crudAllowed.edit),
            "validation": "integer|required",
            "initial_value": (Object.entries(data).length > 0) ? userInformation.address.country_id : ''
        },
    };
}