import React, {useState} from 'react';

//Material Cores
import {Drawer, makeStyles, Toolbar, Tooltip} from "@material-ui/core";
import NavigationItems from './NavigationItems/NavigationItems';
import clsx from "clsx";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import {useStore} from "../../stores/store";

//Custom Values
const drawerWidth = 250;

//Custom CSS
const useStyles = makeStyles((theme) => ({
    desktopMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerToggle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
    },
    drawerToggleButton: {
        width: 'auto',
    },
}));

export default function Navigation() {
    const classes = useStyles();
    const {state, dispatch} = useStore();
    const [open, setOpen] = useState(state.navigation_drawer.open);

    const handleDrawerOpen = () => {
        setOpen(true);
        dispatch({type: "NAVIGATION_DRAWER", open: true});
    };

    const handleDrawerClose = () => {
        setOpen(false);
        dispatch({type: "NAVIGATION_DRAWER", open: false});
    };

    return (
      <Drawer
        variant="permanent"
        className={
            clsx(classes.desktopMobile, classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })
        }
        classes={{
            paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            }),
        }}
      >
          <Toolbar className={classes.toolbar}/>
          <div className={classes.drawerToggle + ' navMinimise'}>
              <Tooltip title="Minimised side menu" placement="left">
                  <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleDrawerClose}
                    edge="end"
                    className={clsx(classes.drawerToggleButton, {
                        [classes.hide]: !open,
                    })}
                  >
                      <ChevronLeftIcon/>
                  </IconButton>
              </Tooltip>
              <Tooltip title="Expand side menu" placement="left">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="end"
                    className={clsx(classes.drawerToggleButton, {
                        [classes.hide]: open,
                    })}
                  >
                      <ChevronRightIcon/>
                  </IconButton>
              </Tooltip>
          </div>
          <NavigationItems
            className={clsx({
                ['hide']: !open,
                ['show']: open,
            })}
          />
      </Drawer>
    );
}