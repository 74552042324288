import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useStore} from "../../../../stores/store";
import {Post} from "../../../../stores/action/Form/Post";
import FaceToFaceCompetencyFields from "./FaceToFaceCompetencyFields";
import Validation from "../../../../stores/action/Form/Validation";
import Form from "../../../Form/Form";
import FieldBuilder from "../../../Form/Fields/FieldBuilder";
import GroupSubmit from "../../../UI/Button/Group/GroupSubmit";
import {Skeleton} from "@material-ui/lab";
import {Update} from "../../../../stores/action/Form/Update";

const FaceToFaceCompetencyForm = (props) => {
  let history = useHistory();
  const {dispatch} = useStore();
  const {personnelId, data} = props;
  const [stateLocal, setstateLocal] = useState({});
  const fields = FaceToFaceCompetencyFields(data, personnelId);
  const endpoint = (typeof (data) !== 'undefined' && typeof (data.id) === 'number')
    ? 'api/v1/competency-achievements/' + data.id
    : 'api/v1/competency-achievements';

  const faceToFaceCompetencyFunc = values => (
    typeof (data) !== 'undefined' && typeof (data.id) === 'number'
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );

  const formik = Validation(fields, faceToFaceCompetencyFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return (
            <FieldBuilder
              key={fieldKey}
              field={fieldKey}
              fieldAttributes={fields[fieldKey]}
              validation={formik}
            />
          )
        })
      }
      {
        fields && Object.entries(fields).length > 0
          ? <GroupSubmit
            className={'GroupSubmit'} reset={false} auto={false} right={true}
            submitText={'Save'} formik={formik}
          />
          : <Skeleton variant="rect" width={'100%'}/>
      }
    </Form>
  )
}

export default FaceToFaceCompetencyForm;