import React, {useState} from 'react';
import {Post} from '../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import EmergencyContactFields from "./EmergencyContactFields";
import Form from "../Form/Form";
import FieldBuilder from "../Form/Fields/FieldBuilder";
import Validation from '../../stores/action/Form/Validation';
import GroupSubmit from "../UI/Button/Group/GroupSubmit";
import {Update} from "../../stores/action/Form/Update";
import {useStore} from "../../stores/store";

export default function EmergencyContactEdit(props) {
  let history = useHistory();
  const {dispatch} = useStore();
  const {data, userId, disableField, crudAllowed} = props;
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (data && typeof data.id !== 'undefined') ? 'api/v1/emergency-contact/' + data.id : 'api/v1/emergency-contact';
  const fields = EmergencyContactFields(data, userId, disableField, crudAllowed);

  const emergencyContactFunc = (values) => (
    data && typeof data.id !== 'undefined'
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );
  const formik = Validation(fields, emergencyContactFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldThird'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        })
      }

      {
        (!disableField && Object.entries(fields).length > 0) &&
        <GroupSubmit
          className={'GroupSubmit'}
          auto={false}
          right={true}
          reset={false}
          submitText={ (data && typeof data.id !== 'undefined'? 'Update' : 'Save') }
          formik={formik}
        />
      }
    </Form>
  );
}