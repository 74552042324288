const NavigationDrawerReducer = (state, action) => {
  switch (action.type) {
    case "NAVIGATION_DRAWER":
      return {
        ...state,
        open: action.open
      }
    default:
      return state;
  }
}

export default NavigationDrawerReducer;