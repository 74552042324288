import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import {Box, TablePagination, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DynamicTableHead from "./DynamicTableHead";
import DynamicRow from "./DynamicRow";
import Loading from "../../../components/UI/Loading/Loading";
import DynamicTableFilter from "./DynamicTableFilter";
import TableData from "../../../stores/action/TableData/TableData";
import {useStore} from "../../../stores/store";

export default function DynamicTable(props) {
  const {
    maxHeight,
    tableTitle,
    filter,
    headCells,
    subRow,
    sortBy,
    initialOrder,
    addButton,
    editButton,
    deleteButton,
    buttonEndpoint,
    link,
    modalWidth,
    form,
    user_id,
    dataLimiter,
  } = props;
  const {state} = useStore();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(initialOrder ? initialOrder : "desc");
  const [orderBy, setOrderBy] = useState(sortBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableCollection, setTableCollection] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [search, setSearch] = useState([]);
  const [noData, setNoData] = useState(true);
  let prevData = '';

  const updateStateSearch = () => {
    if (state.search[tableTitle] && Object.keys(state.search[tableTitle]).length > 0) {
      if (tableTitle === 'Users' && typeof state.search[tableTitle]['personnel_status'] === 'undefined') {
        state.search[tableTitle]['personnel_status'] = {
          row: "personnel_status",
          search: "=",
          value: 1
        }
      }

      setSearch(state.search[tableTitle]);
    }
  };

  useEffect(() => {
    updateStateSearch();
    TableData(buttonEndpoint, orderBy, order, rowsPerPage, page, setTableCollection, user_id, search, dataLimiter);
    state.global_update = false;
  }, [state.global_update]);

  useEffect(() => {
    if (tableCollection === 'no data') {
      setLoading(false);
      setNoData(true);
    }

    if (tableCollection !== 'no data' && Object.entries(tableCollection).length > 0) {
      setRowsPerPage(tableCollection.meta.per_page);
      setTotalRow(tableCollection.meta.total);
      setLoading(false);
    }

    if (typeof tableCollection.table !== 'undefined'){
      setNoData(true);

      if(tableCollection.table.length > 0 || tableCollection === 'no data') {
        setLoading(false);
        setNoData(false);
      }
    }
  }, [tableCollection]);

  useEffect(() => {
    setLoading(true);

    if (Object.entries(search).length <= 0) {
      setPage(0);
    }

    updateStateSearch();
    TableData(buttonEndpoint, orderBy, order, rowsPerPage, page, setTableCollection, user_id, search, dataLimiter);
  }, [search]);

  const handleRequestSort = (event, property) => {
    setLoading(true);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    state.global_update = true;
  };

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    updateStateSearch();
    TableData(buttonEndpoint, orderBy, order, rowsPerPage, newPage, setTableCollection, user_id, search, dataLimiter);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setLoading(true);
    state.global_update = true;
  };

  return (
    <Box className={'tableContainer'}>
      <DynamicTableFilter
        tableTitle={tableTitle} filter={filter} columns={headCells} addButton={addButton}
        endpoint={buttonEndpoint} form={form} search={search}
        setSearch={setSearch}
      />
      <Paper>
        <DynamicTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells}/>
        <TableContainer className={"recordTable"} style={{maxHeight: (maxHeight !== 'auto') ? maxHeight : '75VH'}}>
          {loading && <Loading/>}
          {
            (!loading && noData) &&
            <Box display="flex" justifyContent="center" m={1} p={1} height={'75px'}>
              <Typography variant={'h6'}>
                No record found
              </Typography>
            </Box>
          }
          {
            (!loading && !noData && tableCollection.table.length > 0) && tableCollection.table.map((row, index) => {

              if (row.type_id !== '') {
                prevData = row.type_id;
              }

              return <Table key={index} component={'div'} size="medium">
                <DynamicRow
                  columns={headCells}
                  row={row}
                  subRow={subRow}
                  editButton={editButton}
                  linkUpValue={prevData}
                  link={link + row.user_detail_id}
                  deleteButton={deleteButton}
                  buttonEndpoint={buttonEndpoint}
                  modalWidth={modalWidth}
                  form={form}
                >
                  {props.children}
                </DynamicRow>
              </Table>;
            })
          }
        </TableContainer>

        {!loading &&
          <TablePagination
            rowsPerPageOptions={rowsPerPage ? [10, 20, 50, 100] : [100]}
            component={"div"}
            count={totalRow}
            rowsPerPage={rowsPerPage ? rowsPerPage : 100}
            page={page ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
      </Paper>
    </Box>
  );
}
