import axiosApiInstance from "../../../helpers/axiosConfig";

const Gender = (dispatch) => {
    axiosApiInstance
        .get(process.env.REACT_APP_API_DOMAIN + "api/v1/gender")
        .then(res => {
            dispatch({type: "GENDER", gender: res.data});
            dispatch({type: "GENDER_UPDATED", update_gender: true});
        })
        .catch((error) => {
            console.error(error.message);
        });
};

export default Gender;