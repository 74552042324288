import React from "react";
import CustomGrid from "../../../../containers/Grid/CustomGrid";
import Submit from "../SubmitButton";
import ResetButton from "../Reset";
import CustomBox from "../../../../containers/Box/CustomBox";

const GroupSubmit = (props) => {

  const {className, auto, align, resetText, submitText, reset, stateLocal, formik} = props;

  return (
    <CustomGrid
      auto={(typeof (auto) === 'undefined') ? true : auto}
      align={(typeof (align) === 'undefined') ? true : align}
    >
      <CustomBox
        className={(typeof (className) === 'undefined') ? 'GroupSubmit' : className}
        align={'right'}
      >
        {reset && <ResetButton> {(typeof (resetText) === 'undefined') ? 'Reset' : resetText} </ResetButton>}

        <Submit stateLocal={stateLocal} formik={formik}> {(typeof (submitText) === 'undefined') ? 'Submit' : submitText} </Submit>
      </CustomBox>

    </CustomGrid>
  )
}

export default GroupSubmit;