import React, {useState} from "react";

export default function VendorFields(data = {}) {

  return {
    name: {
      field: "input",
      label: "Name",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.name : ''
    },
    code: {
      field: "input",
      label: "Code",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0) ? data.code : ''
    },
    abn: {
      field: "input",
      label: "ABN",
      required: false,
      validation: "string",
      initial_value: (Object.entries(data).length > 0) ? data.abn : ''
    },
    website: {
      field: "input",
      label: "Website",
      required: false,
      validation: "string",
      initial_value: (Object.entries(data).length > 0) ? data.website : ''
    },
    external_vendor_identifier: {
      field: "input",
      label: "External Vendor Identifier",
      required: true,
      validation: "string|required",
      initial_value: (Object.entries(data).length > 0 && data.external_vendor_identifier) ? data.external_vendor_identifier : ''
    },
    // location_id: {
    //   field: "input",
    //   label: "Location",
    //   required: false,
    //   hidden: true,
    //   initial_value: (Object.entries(data).length > 0 && data.location_id) ? data.location_id : 1
    // },
    // contact_detail_id: {
    //   field: "input",
    //   label: "Contact Detail",
    //   required: false,
    //   hidden: true,
    //   initial_value: (Object.entries(data).length > 0 && data.contact_detail_id) ? data.contact_detail_id : 1
    // },
    seperator: {
      field: "seperator",
      label: "Address",
      className: "full seperator",
    },
    address_id: {
      field: "input",
      hidden: true,
      required: false,
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.id : 1
    },
    address_line_1: {
      field: "input",
      label: "Address Line",
      required: false,
      initial_value: (data.address && Object.entries(data.address).length > 0) ?  data.address.address_line_1 : ''
    },
    address_line_2: {
      field: "input",
      label: "Address Line 2",
      required: false,
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.address_line_2 : ''
    },
    suburb: {
      field: "input",
      label: "Suburb",
      required: true,
      validation: "string|required",
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.suburb : ''
    },
    postcode: {
      field: "input",
      label: "Postcode",
      required: true,
      validation: "integer|required",
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.postcode : ''
    },
    state_id: {
      field: "select",
      multi: false,
      option: "state",
      label: "State",
      required: true,
      dynamic: true,
      validation: "integer|required",
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.state_id : ''
    },
    country_id: {
      field: "autocomplete",
      multi: false,
      option: "country",
      label: "Country",
      dynamic: true,
      required: true,
      validation: "integer|required",
      initial_value: (data.address && Object.entries(data.address).length > 0) ? data.address.country_id : ''
    },
    comments: {
      field: "textarea",
      label: "Comments",
      required: false,
      validation: "string",
      initial_value: (Object.entries(data).length > 0) ? data.comments : ''
    },
    status_id: {
      field: "select",
      multi: false,
      option: [
        {id: 1, name: "Active", code: "ACTIVE"},
        {id: 2, name: "Disabled", code: "DISABLED"},
        {id: 3, name: "Archived", code: "ARCHIVED"},
        {id: 4, name: "Soft Archived", code: "SOFT-ARCHIVED"},
      ],
      label: "Status",
      required: true,
      validation: "integer|required",
      initial_value: Object.entries(data).length > 0 ? data.status_id : '',
      className: 'full'
    },
  };
};