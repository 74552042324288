import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, {useEffect, useState} from "react";
import {FormControl, Typography} from "@material-ui/core";

export default function DateField(props) {

  const {name, id, label, variant, disabled, future, past, required, value, className, formik} = props;

  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    switch (value) {
      case "":
      case null:

        if (id === 'date_of_birth' || id === 'valid_to_date') {
          setSelectedDate(null);
        } else {
          setSelectedDate(moment().format("YYYY-MM-DD"));
          handleDateChange(moment());
        }

        break;
      default:
        setSelectedDate(moment(value).format("YYYY-MM-DD"));
        break;
    }
  }, [value]);

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date.format("YYYY-MM-DD HH:mm:ss"));
      formik.setFieldValue(id, date.format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setSelectedDate(null);
      formik.setFieldValue(id, '');
    }
  };


  return (
    <FormControl className={(formik.errors[id]) ? className + ' Mui-error' : className} variant={variant}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale('en-au')}>
        <KeyboardDatePicker
          className={className}
          inputVariant={variant}
          id={id}
          fullWidth={true}
          name={name}
          required={required}
          label={label}
          disabled={disabled}
          disablePast={past}
          disableFuture={future}
          format="DD/MM/YYYY"
          value={(selectedDate === 'Invalid date') ? null : selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': {label} + ' change',
          }}
          error={(formik.errors[id]) ? true : false}
          helperText={(formik.errors[id]) ? "Can not be empty" : ""}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}
