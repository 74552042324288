import React, {useState, useEffect} from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Option from "../../../../stores/action/Form/Option";
import {TextField, Typography} from "@material-ui/core";
import AutocompleteField from "../Select/AutocompleteField";

const switchAutoCompleteValue = (value, autocompleteFields) => {
  let attributes = {};
  let options =  '';
  let newLabel =  '';
  let fieldKey = Object.keys(autocompleteFields)[0];
  let validation = autocompleteFields[fieldKey].formik;

  switch (value)  {
    case 56:
      options = 'organisation_unit'
      newLabel = 'Organisation'
      break;
    case 57:
      options = 'vendor'
      newLabel = 'Vendor'
      break;
    case 58:
      options = 'position'
      newLabel = 'Position'
      break;
    case 59:
      options = 'role'
      newLabel = 'Role'
      break;
    case 60:
      options = 'cmid'
      newLabel = 'CMID'
      break;
    default:
      options = ''
  }

  attributes = {
    id: autocompleteFields[fieldKey].id,
    name: autocompleteFields[fieldKey].name,
    variant: "outlined",
    multi: false,
    dynamic: true,
    option: options,
    label: newLabel,
    required: true,
    validation: "integer|required",
    className: 'half',
    formik: autocompleteFields[fieldKey].formik,
    disabled: typeof autocompleteFields[fieldKey].disable === "undefined" ? false : autocompleteFields[fieldKey].disable,
    initial_value: (autocompleteFields.entries(autocompleteFields[fieldKey]).length > 0 && autocompleteFields[fieldKey].initial_value !== null) ? autocompleteFields[fieldKey].initial_value : '',
    value: typeof validation.values[autocompleteFields[fieldKey].id] === "undefined" ? "" : validation.values[autocompleteFields[fieldKey].id],
    // onChange: validation.handleChange,
    // error: validation.touched[autocompleteFields[fieldKey].id] && Boolean(validation.errors[autocompleteFields[fieldKey].id]),
    // helperText: validation.touched[autocompleteFields[fieldKey].id] && validation.errors[autocompleteFields[fieldKey].id],
  };

  if (options !== '') {
    return <AutocompleteField {...attributes} />
  }
};

export default function RadioAutoCompleteField(props) {
  let i = 0;
  const {
    name,
    id,
    label,
    variant,
    option,
    required,
    value,
    className,
    formik,
    limiter,
    exclude,
    disabled,
    autocompleteFields,
    changeFunctionValue
  } = props;

  const [selectedOption, setSelectedOption] = useState("");

  const options = Option(option, limiter, exclude);

  useEffect(() => {

    if (options && options.length > 0) {
      let tempValue;
      options.map((items) => {
        if (value == items.id) {
          tempValue = items.id;
        }
      });

      setSelectedOption(tempValue);
    }
  }, [options]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    formik.setFieldValue(id, event.target.value);

    if (typeof onChangeFunction !== 'undefined') {
      onChangeFunction(event);
    }
  };

  return (
    <>
      <FormControl className={className} variant={variant} required={required}>
        <FormLabel component="legend" className={(formik.errors[id]) ? 'Mui-error' : ''}>{label}</FormLabel>
        <RadioGroup aria-label={id} name={id} value={selectedOption ? selectedOption : " "}
                    onChange={handleChange}
                    className={(formik.errors[id]) ? 'Mui-error' : ''}>
          {options.map((items) => (
            <FormControlLabel key={items.id} name={items.code} value={items.id} control={<Radio/>} label={items.name}
                              disabled={disabled}/>
          ))}
        </RadioGroup>
        {
          formik.errors[id] && (
            <Typography component="p" color={'error'}
                        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
              Please select one option.
            </Typography>
          )
        }
      </FormControl>

      {switchAutoCompleteValue(selectedOption, autocompleteFields)}
    </>
  );
}
 