import React from 'react';
import clsx from 'clsx';

//Material Cores
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//Material Icons
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

//Custom Components
import NavigationItems from './NavigationItems/NavigationItems';
import Avatar from '../UI/Avatar/Avatar'
import ProfileAvatar from "../User/ProfileAvatar";
import SettingsIcon from "@material-ui/icons/Settings";

//Custom Values
const drawerWidth = 250;
const anchor = 'right';

//Custom CSS
const useStyles = makeStyles({
  drawer: {
    width: drawerWidth,
  },
  fullList: {
    width: drawerWidth,
  },
});

export default function NavigationMobile() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <IconButton color="inherit" onClick={() => window.location = "/settings"} style={{paddingRight:'15px'}}>
        <SettingsIcon/>
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer(anchor, true)}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
        <div
          className={clsx(classes.drawer, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ProfileAvatar/>
          <NavigationItems />
          <Divider />
          <List>

              <ListItem button key={'Logout'} onClick={() => window.location = "/logout"}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItem>

          </List>
        </div>
      </Drawer>
    </div>
  );
}
