const personnelPositionReducer = (state, action) => {
  switch (action.type) {
    case "PERSONNEL_POSITION":
      return {
        ...state,
        personnel_position: action.personnel_position,
      }
    case "PERSONNEL_POSITION_UPDATED":
      return {
        ...state,
        update_personnel_position: action.update_personnel_position,
      }
    default:
      return state;
  }
}

export default personnelPositionReducer;