import React, { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FormControl, InputLabel } from '@material-ui/core';

export default function ShowHideField (props) {
  const { onChangeFunction, name, id, label, variant, value, className, formik, disabled } = props;
  const [check, setCheck] = useState((value) ? (typeof value === "boolean" ? value : value === 1) : false);

  useEffect(() => {
    onChangeFunction((value) ? true : false);
    setCheck((value) ? true : false);
  }, [value]);

  const handleChange = (event) => {
    let checkedVal = event.target.checked;
    onChangeFunction(event.target.checked);
    setCheck(event.target.checked);

    if(id == 'status_id') {
      checkedVal = (event.target.checked) ? 1 : 2;
    }

    formik.setFieldValue(id, checkedVal);
  };

  return (
    <FormControl className={className} variant={variant}>
      <FormControlLabel
        control={
          <Switch
            checked={check}
            onChange={handleChange}
            id={id}
            name={name}
            disabled={disabled}
          />}
        label={label}
      />
    </FormControl>
  );
}
