import React, {useState} from "react";
import {Post} from '../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import {Update} from "../../../stores/action/Form/Update";
import {useStore} from "../../../stores/store";
import OrganisationUnitFields from "./OrganisationUnitFields";

function getString(id) {
  return id && id !== "create" ? 'Update' : 'Save';
}

function setFields(fields, formik) {
  return <>
    {
      fields && Object.entries(fields).map(([fieldKey, _field]) => {
        return <FieldBuilder
          key={fieldKey}
          field={fieldKey}
          fieldAttributes={fields[fieldKey]}
          validation={formik}
        />
      })
    }
  </>;
}

const OrganisationUnitForm = (props) => {

  let history = useHistory();
  const {id, data} = props
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (id && id !== "create") ? 'api/v1/organisation-unit/' + id : 'api/v1/organisation-unit';
  const fields = OrganisationUnitFields(data);
  const allowEdit = (fields.type_id_update_type.initial_value !== 27);
  const positionCustomFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );

  const formik = Validation(fields, positionCustomFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {setFields(fields, formik)}
      {
        (fields && Object.entries(fields).length > 0 && allowEdit) &&
        <GroupSubmit
          className={'GroupSubmit'} reset={false} auto={false} right={true}
          submitText={getString(id)} formik={formik}
        />

      }

    </Form>
  )
}

export default OrganisationUnitForm;