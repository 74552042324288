import React from "react";

import {stringReplace} from "./stringReplace";
import {TextField, Typography} from "@material-ui/core";
import DateField from "../components/Form/Fields/Date/DateField";
import SelectField from "../components/Form/Fields/Select/SelectField";
import AutocompleteField from "../components/Form/Fields/Select/AutocompleteField";
import DateRangeField from "../components/Form/Fields/Date/DateRangeField";
import SwitchField from "../components/Form/Fields/Switch/SwitchField";
import ShowHideField from "../components/Form/Fields/Switch/ShowHideField";
import CheckboxField from "../components/Form/Fields/Checkbox/CheckboxField";
import RadioField from "../components/Form/Fields/Radio/RadioField";
import FileUpload from "../components/Form/Fields/FileUpload/FileUpload";
import ColorPickerUi from "../components/Form/Fields/ColorPickerUi/ColorPickerUi";
import CustomFieldArray from "../components/Form/Fields/CustomField/CustomFieldArray";
import GroupCheckboxField from "../components/Form/Fields/Checkbox/GroupCheckboxField";
import RadioAutoCompleteField from "../components/Form/Fields/Radio/RadioAutoCompleteField";

function validateRequired(value) {
  let error;

  if (!value) {
    error = "Required";
  }

  return error;
}

function setAttributes(fieldName, fieldAttributes, validation) {
  return {
    id: fieldName,
    name: fieldName,
    placeholder: fieldAttributes.label,
    variant: "outlined",
    minRows: fieldAttributes.field === "textarea" ? 4 : 1,
    multiline: fieldAttributes.field === "textarea" ? true : false,
    disabled: typeof fieldAttributes.disable === "undefined" ? false : fieldAttributes.disable,
    multi: typeof fieldAttributes.multi === "undefined" ? "false" : fieldAttributes.multi,
    past: typeof fieldAttributes.past === "undefined" ? "" : fieldAttributes.past,
    future: typeof fieldAttributes.future === "undefined" ? "" : fieldAttributes.future,
    label: fieldAttributes.label ? stringReplace(fieldAttributes.label, '', true) : stringReplace(fieldName, '', true),
    required: typeof fieldAttributes.required === "undefined" ? false : fieldAttributes.required,
    value: typeof validation.values[fieldName] === "undefined" ? "" : validation.values[fieldName],
    checked: typeof validation.values[fieldName] === "undefined" ? "" : validation.values[fieldName],
    option: typeof fieldAttributes.option === "undefined" ? "" : fieldAttributes.option,
    dynamic: typeof fieldAttributes.dynamic === "undefined" ? undefined : fieldAttributes.dynamic,
    onChange: validation.handleChange,
    error: validation.touched[fieldName] && Boolean(validation.errors[fieldName]),
    helperText: validation.touched[fieldName] && validation.errors[fieldName],
    className: fieldAttributes.className ? fieldAttributes.className : "",
  };
}

function addFormik(fieldAttributes, attributes, validation) {
  if (fieldAttributes.field === 'date'
    || fieldAttributes.field === 'radio'
    || fieldAttributes.field === 'select'
    || fieldAttributes.field === 'autocomplete'
    || fieldAttributes.field === 'checkbox'
    || fieldAttributes.field === 'switch'
    || fieldAttributes.field === 'showhide'
    || fieldAttributes.field === 'group_checkbox'
    || fieldAttributes.field === 'radio_autocomplete'
    || fieldAttributes.field === 'custom_fields_destination') {
    attributes['formik'] = validation
  }
}

function setAutoCompletion(autocompleteFields, autocompleteFieldsAttributes, validation) {
  autocompleteFields && Object.entries(autocompleteFields).map(([key, autocompleteField], _id) => {
    autocompleteFieldsAttributes[key] = {
      id: key,
      name: key,
      variant: "outlined",
      disabled: typeof autocompleteField.disable === "undefined" ? false : autocompleteField.disable,
      required: typeof autocompleteField.required === "undefined" ? false : autocompleteField.required,
      value: typeof validation.values[key] === "undefined" ? "" : validation.values[key],
      checked: typeof validation.values[key] === "undefined" ? "" : validation.values[key],
      onChange: validation.handleChange,
      error: validation.touched[key] && Boolean(validation.errors[key]),
      helperText: validation.touched[key] && validation.errors[key],
      className: autocompleteField.className ? autocompleteField.className : "",
      formik: validation
    }

    return autocompleteFieldsAttributes;
  })
}

function setRadioAutoCompletion(fieldAttributes, autocompleteFields, autocompleteFieldsAttributes, validation) {
  if (fieldAttributes.field === 'radio_autocomplete') {
    autocompleteFields = fieldAttributes.autocomplete_field;
    setAutoCompletion(autocompleteFields, autocompleteFieldsAttributes, validation);
  }
}

function setDates(dateFields, dateFieldsAttributes, validation) {
  {
    dateFields && Object.entries(dateFields).map(([key, dateField], _id) => {
      dateFieldsAttributes[key] = {
        id: key,
        name: key,
        placeholder: dateField.label,
        variant: "outlined",
        disabled: typeof dateField.disable === "undefined" ? false : dateField.disable,
        past: typeof dateField.past === "undefined" ? "" : dateField.past,
        future: typeof dateField.future === "undefined" ? "" : dateField.future,
        label: dateField.label ? stringReplace(dateField.label, '', true) : stringReplace(key, '', true),
        required: typeof dateField.required === "undefined" ? false : dateField.required,
        value: typeof validation.values[key] === "undefined" ? "" : validation.values[key],
        checked: typeof validation.values[key] === "undefined" ? "" : validation.values[key],
        onChange: validation.handleChange,
        error: validation.touched[key] && Boolean(validation.errors[key]),
        helperText: validation.touched[key] && validation.errors[key],
        className: dateField.className ? dateField.className : "",
        formik: validation
      }

      return dateFieldsAttributes;
    })
  }
}

function setDateRange(fieldAttributes, dateFields, dateFieldsAttributes, validation) {
  if (fieldAttributes.field === 'date_range') {
    dateFields = fieldAttributes.date_range_fields;
    setDates(dateFields, dateFieldsAttributes, validation);
  }
  return dateFields;
}

function setCheckboxFields(checkboxFields, checkboxFieldsAttributes, validation, fieldName) {
  {
    checkboxFields && Object.entries(checkboxFields).map(([key, checkboxField], _id) => {
      checkboxFieldsAttributes[key] = {
        id: key,
        name: key,
        placeholder: checkboxField.label,
        variant: "outlined",
        label: checkboxField.label ? stringReplace(checkboxField.label, '', true) : stringReplace(key, '', true),
        required: typeof checkboxField.required === "undefined" ? false : checkboxField.required,
        checked: typeof validation.values[key] === "undefined" ? false : checkboxField.values[key],
        value: typeof validation.values[fieldName] === "undefined" ? false : validation.values[fieldName],
        onChange: validation.handleChange,
        error: validation.touched[key] && Boolean(validation.errors[key]),
        className: checkboxField.className ? checkboxField.className : "",
        formik: validation
      }

      return checkboxFieldsAttributes;
    })
  }
}

function setCheckBox(fieldAttributes, checkboxFields, checkboxFieldsAttributes, validation, fieldName) {
  if (fieldAttributes.field === 'group_checkbox') {
    checkboxFields = fieldAttributes.group;
    setCheckboxFields(checkboxFields, checkboxFieldsAttributes, validation, fieldName);
  }
  return checkboxFields;
}

export function inputBuilder(fieldName, fieldAttributes, validation, onChangeFunction) {
  let inputElement = null;
  let dateFields = null;
  let autocompleteFields = null;
  let dateFieldsAttributes = [];
  let autocompleteFieldsAttributes = [];
  let checkboxFields = null;
  let checkboxFieldsAttributes = [];

  let attributes = setAttributes(fieldName, fieldAttributes, validation);

  addFormik(fieldAttributes, attributes, validation);

  setRadioAutoCompletion(fieldAttributes, autocompleteFields, autocompleteFieldsAttributes, validation);

  dateFields = setDateRange(fieldAttributes, dateFields, dateFieldsAttributes, validation);

  checkboxFields = setCheckBox(fieldAttributes, checkboxFields, checkboxFieldsAttributes, validation, fieldName);

  if (!fieldAttributes.hidden) {
    switch (fieldAttributes.field) {
      case "seperator":
        inputElement = (
          <Typography key={fieldName} component={"legend"}
                      className={fieldAttributes.className + " MuiFormControl-root MuiFormLabel-root"}>
            {fieldAttributes.label}
          </Typography>
        );

        break;
      case "number_only":
        inputElement = <TextField type={"number"}  {...attributes} />;
        break;
      case "password":
        inputElement = <TextField type={"password"} {...attributes} />;
        break;
      case "date":
        inputElement = <DateField {...attributes} />;
        break;
      case "date_range":
        inputElement = <DateRangeField className={fieldAttributes.className} variant={fieldAttributes.variant}
                                       dateFields={dateFields} label={attributes.label} dates={dateFieldsAttributes}/>;
        break;
      case "select":
        inputElement =
          <SelectField {...attributes} limiter={fieldAttributes.limiter} exclude={fieldAttributes.exclude}/>;
        break;
      case "autocomplete":
        inputElement =
          <AutocompleteField {...attributes} limiter={fieldAttributes.limiter} exclude={fieldAttributes.exclude}/>;
        break;
      case "switch":
        inputElement = <SwitchField {...attributes} />;
        break;
      case "showhide":
        inputElement = <ShowHideField {...attributes} onChangeFunction={onChangeFunction}/>;
        break;
      case "checkbox":
        inputElement = <CheckboxField {...attributes} />;
        break;
      case "group_checkbox":
        inputElement = <GroupCheckboxField className={fieldAttributes.className}
                                           checkboxFields={checkboxFields} {...checkboxFieldsAttributes}>
        </GroupCheckboxField>;
        break;
      case "radio":
        inputElement =
          <RadioField {...attributes} limiter={fieldAttributes.limiter} exclude={fieldAttributes.exclude}/>;
        break;
      case "radio_autocomplete":
        inputElement =
          <RadioAutoCompleteField {...attributes} limiter={fieldAttributes.limiter} exclude={fieldAttributes.exclude}
                                  autocompleteFields={autocompleteFieldsAttributes}/>;
        break;
      case "file_upload":
        inputElement = <FileUpload {...attributes} />;
        break;
      case "color_picker":
        inputElement = <ColorPickerUi {...attributes} />;
        break;
      case "custom_fields":
        inputElement = <CustomFieldArray {...attributes} />;
        break;
      case "input":
      case "textarea":
      case "email":
      case "phone":
      case "mobile":
      default:
        inputElement = <TextField {...attributes} />;
    }
  }

  return inputElement;
}
