
import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const AccessLevel = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/access-level")
    .then(res => {
      dispatch({type: "ACCESS_LEVEL", access_level: res.data});
      dispatch({type: "ACCESS_LEVEL_UPDATED", access_level_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default AccessLevel;