import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AppBar, Tab, Tabs, Paper, Box} from "@material-ui/core";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function showTab(visible) {
  let showTab = visible;

  if (typeof visible === 'undefined') {
    showTab = true
  }

  return showTab
}

function setId(visible, id) {
  if (typeof visible !== 'undefined' && !visible) {
    id = id - 1
  }

  return id
}

export default function SimpleTabs(props) {
  const {children} = props
  let childrens = [];
  let i = 0;
  const [value, setValue] = useState(0);
  const [tabChildren, setTabChildren] = useState(children);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    children && children.map((child, id) => {
      if(showTab(child.props.visible)) {
        childrens[i] = child;
        i++;
      }
    })

    setTabChildren(childrens);
  }, [children]);

  return (
    <Paper>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {
            tabChildren && tabChildren.map((child, id) => {
              return (
                showTab(child.props.visible) && <Tab key={"item-" + id} label={child.props.title} {...a11yProps(id)} />
              )
            })
          }
        </Tabs>
      </AppBar>
      {
        tabChildren && tabChildren.map((child, id) => {
          return(
            <TabPanel key={id} value={value} index={id}>
              {child}
            </TabPanel>
          )
        })
      }
    </Paper>
  );
}
