import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import {Typography} from "@material-ui/core";
import AuthContainer from "../../containers/Container/Auth/Auth";
import Logo from "../Logo/LogoAuth";
import BaseForm from '../UI/Form/FormType/BaseForm'
import Submit from "../UI/Button/SubmitButton";
import Field from '../UI/Form/Field/Field'
import LoginLinks from "./LoginLink";
import HelpSchema from '../../stores/action/Help/HelpSchema'
import {HelpDetails} from "../../stores/action/Help/Help";

const helpFields = () => {
    const items = {
        fields: [
            {
                name: 'email_address',
                label: 'Email Address',
                elementType: 'input',
                width: 'full',
                required: true,
                value: '',
            },
        ],
        loading: false
    }

    return items;
};

export default function Login() {
    let history = useHistory();

    const [stateLocal, setstateLocal] = useState({});
    const form = helpFields();
    const customFunc = (values) => (HelpDetails(values.username, setstateLocal, history));
    const formik = HelpSchema(customFunc);

    return (
        <AuthContainer>
            <Logo/>
            <Typography variant='h5'>Password Reset</Typography>
            <Typography variant='body2'>
                Enter your email add we will send you a reset password link.
            </Typography>
            <BaseForm
                stateLocal={stateLocal}
                setstateLocal={setstateLocal}
                onSubmit={formik.handleSubmit}
            >

                {Field(form.fields, formik)}
                <Submit fullWidth>
                    Login
                </Submit>
                <LoginLinks page={'help'}/>
            </BaseForm>
        </AuthContainer>
    )
}