import React, {useState, useEffect} from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Option from "../../../../stores/action/Form/Option";
import {Typography} from "@material-ui/core";

export default function RadioField(props) {
  let i = 0;
  const {name, id, label, variant, option, required, value, className, formik, limiter, exclude, disabled, changeFunctionValue} = props;
  const [selectedOption, setSelectedOption] = useState("");

  const options = Option(option, limiter, exclude);

  useEffect(() => {
    if (options && options.length > 0) {
      let tempValue;
      options.map((items) => {
        if (value == items.id) {
          tempValue = items.id;
        }
      });
      setSelectedOption(tempValue);
    }
  }, [options]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    formik.setFieldValue(id, event.target.value);

    if(typeof onChangeFunction !== 'undefined') {
      onChangeFunction(event);
    }
  };

  return (
    <FormControl className={className} variant={variant} required={required}>
      <FormLabel component="legend" className={(formik.errors[id]) ? 'Mui-error' : ''}>{label}</FormLabel>
      <RadioGroup aria-label={id} name={id} value={selectedOption ? selectedOption : " "}
                  onChange={handleChange}
                  className={(formik.errors[id]) ? 'Mui-error' : ''}>
        {options.map((items) => (
          <FormControlLabel key={items.id} name={items.code} value={items.id} control={<Radio/>} label={items.name}
                            disabled={disabled}/>
        ))}
      </RadioGroup>
      {
        formik.errors[id] && (
          <Typography component="p" color={'error'}
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
            Please select one option.
          </Typography>
        )
      }
    </FormControl>
  );
}
 