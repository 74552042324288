import axiosApiInstance from "../../../helpers/axiosConfig";

const UserDetails = (dispatch, id) => {
  const endpoint = (id) ? "api/v1/user-detail/" + id : "api/v1/user-detail";
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + endpoint)
    .then(res => {
      dispatch({type: "USER_DETAILS", user_details: res.data});
      dispatch({type: "USER_DETAILS_UPDATED", user_details_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default UserDetails;