import {useHistory, useParams} from "react-router-dom";
import {usePath} from 'hookrouter';
import moment from "moment/moment";
import jwt_decode from "jwt-decode";
import React, {useEffect, useState} from "react";
import {useStore} from "../stores/store";
import UserMenu from "../stores/action/User/UserMenu";
import Status from "../stores/action/Status/Status";
import Gender from "../stores/action/Gender/Gender";
import Country from "../stores/action/Country/Country";
import State from "../stores/action/State/State";
import Relationship from "../stores/action/Relationship/Relationship";
import Type from "../stores/action/Type/Type"
import Competency from "../stores/action/Competency/Competency";
import CompetencyCategory from "../stores/action/Competency/CompetencyCategory";
import Vendor from "../stores/action/Vendor/Vendor";
import Alert from "../components/UI/Alert/Alert";
import setAlertMessage from "../stores/action/Alert/alert";
import OrganisationUnit from "../stores/action/OrganisationUnit/OrganisationUnit";
import Position from "../stores/action/Position/Position";
import Role from "../stores/action/Role/Role";
import AccessLevel from "../stores/action/AccessLevel/AccessLevel";
import Menus from "../stores/action/AccessLevel/Menus";

export function CheckTokenRedirect() {
  const {state, dispatch} = useStore();
  const jsonServer = process.env.REACT_APP_API_DOMAIN.includes(':3004');
  let history = useHistory();
  let excludeUrls = ['/auth/help', '/auth/register', '/token=:token'];
  let token = localStorage.getItem('token');

  let path = usePath();

  useEffect(() => {
    token = localStorage.getItem('token');

    if (token && token != '') {
      const decoded = jwt_decode(token);
      const expiredTime = moment.unix(decoded.exp);
      const currentTime = moment();

      if (currentTime > expiredTime && !jsonServer) {
        localStorage.setItem('token', '');
        history.push('/');
        return true;
      }

      if (path === '/') {
        history.push('/dashboard');
      }

      if (excludeUrls.includes(path)) {
        history.push(path);
        return false;
      }
    } else {
      if (excludeUrls.includes(path)) {
        history.push(path);
        return false;
      } else {
        history.push('/');
      }
    }
  }, [history]);

  useEffect(async () => {
    if (token && token != '' && Object.entries(state.user_data.menu).length === 0) {
      UserMenu(dispatch);
      Status(dispatch);
      Gender(dispatch);
      Country(dispatch);
      State(dispatch);
      Relationship(dispatch);
      Type(dispatch);
      Vendor(dispatch);
      Role(dispatch);
      Competency(dispatch);
      CompetencyCategory(dispatch);
      OrganisationUnit(dispatch);
      Position(dispatch);
      Menus(dispatch);
      AccessLevel(dispatch);
    }
  }, []);

  return false;
}