import React from "react";

//Custom Components
import Alert from "../../Alert/Alert";

export default function BaseForm(props) {

    const {stateLocal, setstateLocal, className, type } = props;

    let formClass = 'baseForm';

    if (className) {
        formClass = 'baseForm ' + className;
    }

    return (
        <form className={formClass} noValidate autoComplete="off" onSubmit={props.onSubmit}>

            {(stateLocal.message) && (
                <Alert notification = {stateLocal} state={setstateLocal} />
            )}

            {props.children}

        </form>
    )
}