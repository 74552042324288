const messageReducer = (state, action) => {
    switch (action.type) {
        case "MESSAGE":
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default messageReducer;