const initialSystemSetting = {
  system_setting: {
    "company_details": {
      "company_name": "MobiliseMe",
      "support_number": "+61 (8) 9382 2811",
      "support_email": "contact@cell-media.com",
      "company_main_logo": "/logo/Cell-media-invert.svg",
      "company_login_logo": "/logo/Cell-media.svg"
    },
    "branding_font": {
      "header": "Open Sans",
      "body": "Open Sans",
      "font_size": "14px"
    },
    "branding_color": {
      "primary_color": "#2E3639",
      "secondary_color": "#FFDF00",
      "tertiary_color": "#2F3639",
      "quaternary_color": "#98A4AE",
      "cell_media_blue": "#1c3664",
      "mobilise_me_green": "#a2ce5e",
      "red": "#ba000d",
      "amber": "#ff6300",
      "green": "#5cb85c",
      "white": "#F6F6F6",
      "black": "#2C3033"
    }
  }
};

export default initialSystemSetting;