import React from "react";

import setAlertMessage from '../Alert/alert';
import axiosApiInstance from "../../../helpers/axiosConfig";

export const HelpDetails = (fields, setState, endpoint, history) => {
    const url = process.env.REACT_APP_API_DOMAIN + endpoint;
    let fieldsData = {};

    fields && Object.entries(fields).map(([fieldKey]) => {
        fieldsData[fieldKey] = fields[fieldKey]
    })

    axiosApiInstance
      .post(url, fieldsData)
      .then(res => {
          if (typeof res.data.access_token !== 'undefined') {
              localStorage.setItem('token', JSON.stringify(res.data));
              history.push('/dashboard');
          } else {
              const error = {
                  type: 'error',
                  message: 'Invalid token'
              }

              setAlertMessage(error.response.data, setState);
          }
      })
      .catch((error) => {
          setAlertMessage(error.response.data, setState);
      });
};