import axiosApiInstance from "../../../helpers/axiosConfig";

const SystemSettings = (dispatch) => {
    axiosApiInstance
        .get(process.env.REACT_APP_API_DOMAIN + "api/v1/system/setting")
        .then(res => {
            dispatch({type: "SETTINGS", system_setting: res.data});
            dispatch({type: "SYSTEM_UPDATED", settings_updated: true});
        })
        .catch((error) => {
            console.error(error.message);
        });
};

export default SystemSettings;