import React, {useState} from "react";
import {Skeleton} from "@material-ui/lab";
import {Post} from '../../../stores/action/Form/Post';
import {useHistory} from "react-router-dom";
import Form from "../../Form/Form";
import FieldBuilder from "../../Form/Fields/FieldBuilder";
import Validation from '../../../stores/action/Form/Validation';
import GroupSubmit from "../../UI/Button/Group/GroupSubmit";
import {Update} from "../../../stores/action/Form/Update";
import {useStore} from "../../../stores/store";
import PersonnelFields from './PersonnelFields';
import jwt_decode from "jwt-decode";

const PersonnelForm = (props) => {
  let history = useHistory();
  const {id, uid, data, cmid, sectionCrudAllowed} = props
  const login_data = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
  const decoded = jwt_decode(login_data.access_token);
  let disable = (uid === decoded.sub);
  const {dispatch} = useStore();
  const [stateLocal, setstateLocal] = useState({});
  const endpoint = (id && id !== "create") ? 'api/v1/personnel/' + id : 'api/v1/personnel';
  const fields = PersonnelFields(data, uid, cmid, disable, sectionCrudAllowed);
  const personnelCustomFunc = values => (
    id && id !== "create"
      ? Update(dispatch, values, setstateLocal, endpoint, history)
      : Post(dispatch, values, setstateLocal, endpoint, history)
  );
  const formik = Validation(fields, personnelCustomFunc);

  return (
    <Form
      stateLocal={stateLocal}
      setstateLocal={setstateLocal}
      className={'fieldHalf'}
      onSubmit={formik.handleSubmit}
    >
      {
        fields && Object.entries(fields).map(([fieldKey, field]) => {
          return <FieldBuilder
            key={fieldKey}
            field={fieldKey}
            fieldAttributes={fields[fieldKey]}
            validation={formik}
          />
        })
      }

      {
        ((!disable && !!sectionCrudAllowed['USER_PERSONNEL'].crud.update) && Object.entries(fields).length > 0) &&
        <GroupSubmit
          className={'GroupSubmit'}
          reset={false} auto={false} right={true}
          submitText={(id && id !== "create" ? 'Update' : 'Save')}
          formik={formik}
        />
      }

    </Form>
  )
}

export default PersonnelForm;