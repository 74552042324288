import React from 'react';
import ReactDOM from 'react-dom';
import { VictoryPie, VictoryChart, VictoryBar, VictoryLine } from 'victory';
import ChartTheme from "../../../stores/action/System/ChartTheme"
import CustomGrid from "../../../containers/Grid/CustomGrid"


function CustomChart() {

    const data = [
        { x: "Cats", y: 35 },
        { x: "Dogs", y: 40 },
        { x: "Birds", y: 100 }
    ];

    return (
        <CustomGrid auto={true} right={false} >
            <VictoryPie
                theme={ChartTheme()}
                data={data}
            />
            <VictoryChart
                theme={ChartTheme()}
            >
                <VictoryBar
                    data={data}
                />
            </VictoryChart>
            <VictoryChart
                theme={ChartTheme()}
            >
                <VictoryLine
                    data={data}
                />
            </VictoryChart>
        </CustomGrid>
    )
}

export default CustomChart;
