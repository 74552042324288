import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {InputLabel, MenuItem} from "@material-ui/core";

export default function RadioField(props) {
    let i = 0;
    const {
        className,
        id,
        title,
        variant,
        margin,
        options,
        defaultValue,
        onChange,
        required
    } = props;


    return (
        <FormControl className={className} variant={variant} required={required} margin={margin}>
            <FormLabel component="legend">{title}</FormLabel>
            <RadioGroup
                aria-label={id}
                name={id}
                value={defaultValue}
                onChange={onChange}
            >
                {
                    options.map(
                        items => <FormControlLabel key={items.name + '_' + i++} value={items.value} control={<Radio />} label={items.displayValue}/>
                    )
                }
            </RadioGroup>
        </FormControl>
    );
}
