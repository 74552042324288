import React from 'react';
import {BrowserRouter, Route, Switch, useHistory, useParams} from "react-router-dom";
import {CheckTokenRedirect} from "./helpers/CheckTokenRedirect";
import {StoreProvider} from "./stores/store";
import Login from "./components/Login/Login";
import Registration from './components/Registration/Registration';
import Help from "./components/Login/Help";
import Dashboard from "./components/Pages/Dashboard";
import Users from "./components/Pages/UsersTable";
import Profile from "./components/Pages/Profile";
import AdminSettings from "./components/System/AdminSettings";
import CustomContainer from "./containers/Container/Container";
import AdminCompetencies from "./components/Pages/AdminCompetency"
import TeamLearning from "./components/Pages/TeamLearning";
import AdminVendor from './components/Pages/AdminVendor';
import NotFoundPage from "./components/Pages/NotFoundPage";
import AdminRole from "./components/Pages/AdminRole";
import AdminPersonnel from "./components/Pages/AdminPersonnel";
import AdminOrganisationUnit from "./components/Pages/AdminOrganisationUnit";
import jwt_decode from "jwt-decode";
import AdminPosition from "./components/Pages/AdminPosition";
import AdminAccessLevels from "./components/Pages/AdminAccessLevels";
import Settings from "./components/Pages/Settings";

function Logout() {
  let history = useHistory();
  localStorage.setItem("token", "");
  history.push("/");

  return <CheckTokenRedirect />;
}

function TokenLogin() {
  let history = useHistory();
  let decoded = '';
  let new_token = {};
  let token_login = useParams();

  try {
    decoded = jwt_decode(token_login.token);
  } catch (error) {
    history.push("/");
    return true;
  }

  if(decoded) {
    new_token={access_token: token_login.token}
    localStorage.setItem("token", JSON.stringify(new_token));
    history.push("/dashboard");
  }

  return <CheckTokenRedirect />;
}

function WhichProfile() {
  let { id } = useParams();
  return <Profile id={id} />;
}

export default function App() {
  return (
    <BrowserRouter>
      <StoreProvider>
        <CustomContainer>
          <CheckTokenRedirect />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/dashboard" exact>
              <Dashboard/>
              <CheckTokenRedirect />
            </Route>
            <Route path="/profile" exact>
              <Profile/>
            </Route>
            <Route path="/my-team" exact>
              <TeamLearning/>
            </Route>
            <Route path="/settings" exact>
              <Settings/>
            </Route>
            <Route path="/admin/users" exact>
              <Users path="/admin/users"/>
            </Route>
            <Route path={`/admin/user/profile/:id(\\d+)`} exact>
              <WhichProfile/>
            </Route>
            <Route path="/admin/competencies" exact>
              <AdminCompetencies path="/admin/competencies"/>
            </Route>
            <Route path="/admin/personnels" exact>
              <AdminPersonnel path="/admin/personnels"/>
            </Route>
            <Route path="/admin/positions" exact>
              <AdminPosition path="/admin/positions"/>
            </Route>
            <Route path="/admin/vendor" exact>
              <AdminVendor path="/admin/vendor"/>
            </Route>
            <Route path="/admin/organisation-unit" exact>
              <AdminOrganisationUnit path="/admin/organisation-unit"/>
            </Route>
            <Route path="/admin/roles" exact>
              <AdminRole path="/admin/roles" />
            </Route>
            <Route path="/admin/access-levels" exact>
              <AdminAccessLevels path="/admin/access-levels"/>
            </Route>
            <Route path="/admin/settings" exact>
              <AdminSettings/>
            </Route>
            <Route path="/auth/register" exact>
              <Registration/>
            </Route>
            <Route path="/auth/help" exact>
              <Help/>
            </Route>
            <Route path="/logout" exact component={Logout} />
            <Route path={'/token/:token'} exact component={TokenLogin} />
            <Route component={NotFoundPage}/>
          </Switch>
        </CustomContainer>
      </StoreProvider>
    </BrowserRouter>
  );
}
