import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";

export default function GroupCheckboxField(props) {

  const { className, checkboxFields} = props;

  let checkBoxes = {};

  if (checkboxFields && Object.entries(checkboxFields).length > 0) {
    Object.entries(checkboxFields).map(([checkboxName, checkbox], i) => {
      checkBoxes[checkboxName] = !!(checkbox.initial_value)
    })
  }

  const [checkboxState, setCheckboxState] = React.useState(checkBoxes);

  const handleChange = (event) => {
    setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
  };

  //TODO Group Checkbox with validation
  return (
    <div className={'MuiFormControl-root '+className}>
      <FormGroup row>
        {
          checkboxFields && Object.entries(checkboxFields).map(([checkboxName, checkbox], i) => {
            return (
              <FormControlLabel
                key={checkboxName}
                control={<Checkbox checked={checkboxState[checkboxName] ? checkboxState[checkboxName] : false} onChange={handleChange} name={checkboxName} />}
                label={checkbox.label}
                {...props[checkboxName]}
              />
            )
          })
        }

        {/*{*/}
        {/*  formik.errors[checkboxName] && (*/}
        {/*    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"*/}
        {/*       id="description-helper-text"> Please select one option.*/}
        {/*    </p>*/}
        {/*  )*/}
        {/*}*/}
      </FormGroup>
    </div>

  );
}
