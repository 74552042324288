import React, {useEffect, useState} from 'react';
import AppContainer from "../../containers/Container/App/Main";
import SimpleTabs from "../../containers/Tab/SimpleTabs";
import AccessLevels from "../AccessLevels/AccessLevels";
import AccessLevelPosition from "../AccessLevels/AccessLevelPosition";
import {useStore} from "../../stores/store";
import {setupCrud} from "../../helpers/setupCrud";
import {useHistory} from "react-router-dom";
import AccessLevelTypes from "../AccessLevels/AccessLevelTypes";

const AdminAccessLevels = (props) => {
  const {path} = props;
  const {state} = useStore();
  const [crud, setCrud] = useState([]);
  const [crudAllowed, setCrudAllowed] = useState([]);
  let newTab = [];
  let history = useHistory();

  useEffect(() => {
    setCrud(setupCrud(path, state.user_data.menu.admin));

    if (typeof state.user_data.menu.settings !== 'undefined') {
      state.user_data.menu.settings.map((tab, id) => {
        if (tab.menu_id_parent === 10) {
          newTab[tab.code] = tab;
          setCrudAllowed(newTab);
        }
      })
    }

  }, [state.user_data.menu_updated]);

  if (typeof crudAllowed['POSITION-TO-ACCESS-LEVEL'] == 'undefined') return null;

  if(typeof crud.read !== 'undefined' && !crud.read) {
    history.push("/404");
  }

  return (
    <AppContainer>
      <SimpleTabs>
        <AccessLevelTypes
          title={'Security Role to Access Type'}
          crudAllowed={crudAllowed['POSITION-TO-ACCESS-LEVEL'].crud}
          visible={crudAllowed['POSITION-TO-ACCESS-LEVEL'].crud.read}
        />
        <AccessLevels
          title={'Access Levels Settings'}
          crudAllowed={crudAllowed['ACCESS-LEVEL-SETTINGS'].crud}
          visible={crudAllowed['ACCESS-LEVEL-SETTINGS'].crud.read}
        />
      </SimpleTabs>
    </AppContainer>
  );
};

export default AdminAccessLevels;
