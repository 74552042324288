import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {stringReplace} from "../../../helpers/stringReplace";
import Option from "../../../stores/action/Form/Option";

export default function SearchSelectField(props) {
  const {name, id, label, multi, variant, option, required, value, className, limiter, exclude, onChangeEvent} = props;
  const [searchSelectedOption, setSearchSelectedOption] = useState("");
  let initialValueType = null;
  if (multi) {
    initialValueType = [];
  }

  const options = Option(option, limiter);
  let optionId = initialValueType;

  options && Object.entries(options).map(([fieldKey, field]) => {
    if (multi) {
      if (value.includes(options[fieldKey].id)) {
        optionId.push(field);
      }
    } else {
      if (options[fieldKey].id === value) {
        optionId = options[fieldKey];
      }
    }
  });

  let optionCollection = [];

  optionCollection = Option(option, limiter, exclude)

  useEffect(() => {
    if (optionCollection && Object.entries(optionCollection).length > 0) {
      setSearchSelectedOption((value === 0) ? '' : value);
    }
  }, [optionCollection]);

  useEffect(() => {
    if (value && Object.entries(optionCollection).length > 0) {
      setSearchSelectedOption(value);
    }
  }, [value]);

  return (
    <FormControl variant={variant} required={required} className={className} fullWidth>

      <InputLabel id={id}>{label}</InputLabel>

      <Select id={id} name={name} labelId={id} value={searchSelectedOption} onChange={onChangeEvent} label={label}
              required={required}>

        {
          (option === 'status')
            ? <MenuItem key={0} value='all'> <em>All Status</em> </MenuItem>
            : <MenuItem key={0} value=''> <em>Please Select One</em> </MenuItem>
        }

        {optionCollection && Object.entries(optionCollection).map(([key, optionItem]) => (
          <MenuItem key={optionItem.id+'_'+key} value={optionItem.id} name={optionItem.name}>
            {stringReplace(optionItem.name)}
          </MenuItem>
        ))}

      </Select>

    </FormControl>
  );
}
