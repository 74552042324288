const initialStateUsers = {
    menu: {},
    menu_updated: false,
    profile_progress: {},
    profile_progress_updated: false,
    user_details: {},
    user_details_updated: false,
    emergency_contact: [],
    emergency_contact_updated: false,
    company: [],
    company_updated: false,
    user_completions: [],
    user_user_completions_updated: false,
    user_requirements: [],
    user_requirements_updated: false,
    user_transcript: [],
    user_transcript_updated: false,
    notification_user: {},
    update_notification_user: false,
    notification_team: {},
    update_notification_team: false,
};

export default initialStateUsers;