const cmidReducer = (state, action) => {
  switch (action.type) {
    case "CMID":
      return {
        ...state,
        cmid: action.cmid,
      }
    case "CMID_UPDATED":
      return {
        ...state,
        cmid_updated: action.cmid_updated,
      }
    default:
      return state;
  }
}

export default cmidReducer;