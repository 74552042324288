import React, {useState} from "react";
import moment from "moment";
import AccessLevelPosition from "../AccessLevelPosition";

export default function AccessLevelPositionFields(data = {}) {

  return {
    access_level_id: {
      field: "select",
      multi: false,
      option: 'access_level',
      exclude: 'id|1',
      label: "Security Role",
      required: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.access_level_id !== null) ? data.access_level_id : '',
      className: 'full'
    },
    type_id: {
      field: "radio_autocomplete",
      multi: false,
      option: 'types',
      limiter: 'category_id|17',
      label: "Access Level Type",
      required: true,
      validation: "integer|required",
      initial_value: (Object.entries(data).length > 0 && data.type_id !== null) ? data.type_id : '',
      className: 'full',
      autocomplete_field: {
        type_link_id: {
          required: true,
          validation: "integer|required",
          initial_value: (Object.entries(data).length > 0 && data.type_link_id !== null) ? data.type_link_id : '',
          className: 'full',
        }
      }
    },
    date_range: {
      field: "date_range",
      label: "Date Range",
      className: "full",
      date_range_fields: {
        start_date:{
          field: "date",
          label: "Start Date",
          required: true,
          //disable: (disable ? disable : !sectionCrudAllowed['USER_POSITION'].crud.update || !sectionCrudAllowed['USER_POSITION'].crud.create),
          validation: "date|required",
          past: !(Object.entries(data).length > 0 && data.start_date),
          initial_value: Object.entries(data).length > 0 ?  data.start_date : '',
        },
        end_date:{
          field: "date",
          label: "End Date",
          required: true,
          //disable: (disable ? disable : !sectionCrudAllowed['USER_POSITION'].crud.update || !sectionCrudAllowed['USER_POSITION'].crud.create),
          past: !(Object.entries(data).length > 0 && data.end_date),
          validation: "date|required",
          initial_value: Object.entries(data).length > 0 ? data.end_date : '',
        }
      },
    },
  };
};