const teamLastActivityReducer = (state, action) => {
  switch (action.type) {
    case "TEAM_COMPETENCY":
      return {
        ...state,
        team_competency: action.team_competency,
      }
    case "TEAM_COMPETENCY_UPDATED":
      return {
        ...state,
        updated_team_competency: action.updated_team_competency,
      }
    default:
      return state;
  }
}

export default teamLastActivityReducer;