import {Box, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";

function toId(str) {
    var i, frags = str.split(' ');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toLowerCase() + frags[i].slice(1);
    }
    return frags.join('_');
}

export default function  SelectField(props) {
    let i = 0;

    const {
        className,
        id,
        title,
        variant,
        margin,
        options,
        defaultValue,
        required
    } = props;

    const [selected, setSelected] = useState('');

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

    return (
        <FormControl className={className} variant={variant} required={required} margin={margin}>
            <InputLabel id={id} >{title}</InputLabel>
            <Select
                labelId={id}
                id={id}
                value={defaultValue}
                onChange={handleChange}
                label={title}
            >
                <MenuItem value="">
                    <em>Please Select One</em>
                </MenuItem>
                {
                    options.map(
                        items => <MenuItem key={items.name + '_' + i++} value={items.value} name={items.displayValue}>{items.displayValue}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
}