const changeClass = (expiry) => {
    let className = 'quaternary_color';

    if (expiry === 0) {
        className = 'quaternary_color';
    } else if(expiry <= 30){
        className = 'cellRed';
    } else if (expiry <= 89) {
        className = 'cellAmber';
    } else if (expiry >= 90) {
        className = 'cellGreen';
    }

    return className;
};

export function setValidationColor(expiry = 0) {
    let className = 'quaternary_color';

    if (typeof expiry === 'number') {
        className = changeClass(expiry);
    }

    if (typeof expiry === 'string') {
        className = 'cellGreen';

        if(expiry == 'Expired') {
            className = 'cellRed';
        }

        if(expiry == 'No Expiry') {
            className = 'cellGreen';
        }
    }

    return className;
}