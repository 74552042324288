import React from 'react';
import CustomGrid from "../../containers/Grid/CustomGrid";
import CustomBox from "../../containers/Box/CustomBox";
import CustomCard from "../../containers/Card/CustomCard";
import AccordionForm from "../../containers/Accordion/AccordionForm";
import DynamicValue from "../../containers/DataTable/DynamicTable/DynamicValue";
import {Divider, Typography} from "@material-ui/core";
import UserNotificationForm from "./Form/UserNotificationForm";

const header = (fields) => {
  return (
    <CustomGrid auto={false}>
      <CustomBox className={'noMargin'} direction={'column'}>
        <Typography variant={'body2'}>
          Notification Type: Expiring Competencies
        </Typography>
        <Typography variant={'body2'}>
          Send Notification Before Expiry: <DynamicValue type={'default'} value={fields.days_before_expiry}/> Days
        </Typography>
        <Typography variant={'body2'}>
          Override: <DynamicValue type={'override_status'} value={fields.status_id}/>
        </Typography>
        <Typography variant={'body2'}>
          Repeat: <DynamicValue type={'boolean'} value={fields.repeat}/>
        </Typography>
        <Typography variant={'body2'}>
          Next Notification Release Date: <DynamicValue type={'date'} value={fields.next_notification_date}/>
        </Typography>

      </CustomBox>
    </CustomGrid>
  )
}

const UserNotificationItems = (props) => {
  const {fields, id, crudAllowed, accountHolder} = props;

  return (
    <CustomCard
      variant={'outlined'}
    >
      <AccordionForm
        header={header(fields)}
        addButton={true}
        title={'Notification'}
        form={<UserNotificationForm data={{}} crudAllowed={crudAllowed} accountHolder={accountHolder}/>}
      >
        <Divider/>
        <UserNotificationForm data={fields} id={id} crudAllowed={crudAllowed} accountHolder={accountHolder}/>
      </AccordionForm>
    </CustomCard>
  )
}

export default UserNotificationItems;