const accessLevelReducer = (state, action) => {
  switch (action.type) {
    case "ACCESS_LEVEL":
      return {
        ...state,
        access_level: action.access_level,
      }
    case "ACCESS_LEVEL_UPDATED":
      return {
        ...state,
        access_level_updated: action.access_level_updated,
      }
    default:
      return state;
  }
}

export default accessLevelReducer;