import React from "react";
import {useStore} from "../../store";

const AllowNoEndDate = (id, type ) => {
  const { state } = useStore();
  let allowed = true;
  let data = state[type+'_data'][type];

  data.map((item) => {
    if(item.id === id && item.validity_period > 0) {
      allowed = false;
    }
  });

  return allowed;
}

export default AllowNoEndDate;

