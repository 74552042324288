export function allowManager(allowAdmin, isManagerPosition, isManager, tab ='USER-DETAILS') {

  let allowed = false;

  if(isManagerPosition) {
    if(tab === 'USER-DETAILS') {
      allowed = true;
    } else {
      if(isManager) {
        allowed = true;
      } else {
          if(allowAdmin) {
            allowed = true;
          }
      }
    }
  } else {
    if(allowAdmin) {
      allowed = true;
    }
  }

  return allowed;
}