import React from "react";
import Box from "@material-ui/core/Box";
import DynamicTable from "../../../containers/DataTable/DynamicTable/DynamicTable";
import CompetencyCompetencyCategoryForm from "../Form/CompetencyCompetencyCategory/CompetencyCompetencyCategoryForm";

const columnHeader = [
  {
    id: "competency_id",
    renderType: 'render|competency',
    align: 'left',
    disablePadding: false,
    label: "Competency",
    setWidth: '30%',
    searchable: true,
    searchType: 'select|competency',
    selectExclude: 'type_id_competency_type|36'
  },
  {
    id: "competency_category_id",
    renderType: 'render|competency_category',
    align: 'left',
    disablePadding: false,
    label: "Competency Category",
    setWidth: '30%',
    searchable: true,
    searchType: 'select|competency_category'
  },
  {id: "action", renderType: 'default', align: 'right', disablePadding: false, label: "", setWidth: '17%'},
]

export default function CompetencyCompetencyCategoryTable(props) {
  const {disableField, crudAllowed} = props;
  const buttonEndpoint = 'api/v1/competency-competency-category/';
  let sortBy = "competency_category_id";

  return (
    <Box mt={1} >
      <DynamicTable
        filter={!!(crudAllowed.read)}
        headCells={columnHeader}
        sortBy={sortBy}
        addButton={!!(crudAllowed.create)}
        editButton={!!(crudAllowed.update)}
        deleteButton={!!(crudAllowed.delete)}
        buttonEndpoint={buttonEndpoint}
        modalWidth={'md'}
        form={<CompetencyCompetencyCategoryForm/>}
      />
    </Box>
  );
}