import React from "react";
import {createTheme} from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {useStore} from "../../store";
import SystemSettings from "./Settings";

function pxToRem(value) {
  return `${value / 16}rem`;
}

function hexToRgbA(hex, a = 1) {
  var c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
  }
}

export default function DefaultTheme() {

  const {state} = useStore();

  const breakpoints = createBreakpoints({});

  const primary_color = state.system_setting.system_setting.branding_color.primary_color
  const secondary_color = state.system_setting.system_setting.branding_color.secondary_color
  const tertiary_color = state.system_setting.system_setting.branding_color.tertiary_color
  const quaternary_color = state.system_setting.system_setting.branding_color.quaternary_color
  const cell_media_blue = state.system_setting.system_setting.branding_color.cell_media_blue
  const mobilise_me_green = state.system_setting.system_setting.branding_color.mobilise_me_green
  const red = state.system_setting.system_setting.branding_color.red
  const amber = state.system_setting.system_setting.branding_color.amber
  const green = state.system_setting.system_setting.branding_color.green
  const white = state.system_setting.system_setting.branding_color.white
  const black = state.system_setting.system_setting.branding_color.black
  const header_font = state.system_setting.system_setting.branding_font.header;
  const main_font = state.system_setting.system_setting.branding_font.body;
  const header_bg = (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'testing')
    ? '#4a148c' : tertiary_color;


  return createTheme({
    breakpoints,
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'auto',
          },
          header: {
            height: 'auto',
          },
          main: {
            '&.app': {
              flex: 1,
              display: 'flex',
              minHeight: '100vh',
              flexDirection: 'column',
            },
          },
          nav: {
            MuiButtonBase: {
              root: {
                color: secondary_color,
              }
            },
            '&> .MuiButtonBase-root': {
              '&:hover': {
                backgroundColor: secondary_color,
                '&> .MuiListItemIcon-root': {
                  color: primary_color,
                },
                '&> .MuiListItemText-root': {
                  color: primary_color,
                },
              },
              '&.Mui-selected': {
                backgroundColor: secondary_color,

                '&> .MuiListItemIcon-root': {
                  color: primary_color,
                },
                '&> .MuiListItemText-root': {
                  color: primary_color,
                },
                '&:hover': {
                  backgroundColor: secondary_color,
                  '&> .MuiListItemIcon-root': {
                    color: primary_color,
                  },
                  '&> .MuiListItemText-root': {
                    color: primary_color,
                  },
                },
              },
              '&> .MuiListItemIcon-root': {
                color: primary_color,
              },
              '&> .MuiListItemText-root': {
                color: quaternary_color,
              },
            },
            '& .hide': {
              transition: `height 10ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
              height: '0',
              display: 'none',
            },
            '& .show': {
              transition: `height 10ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
              height: 'auto',
            },
          },
          footer: {
            height: 'auto',
            maxHeight: 85,
            '& .topBorder': {
              background: secondary_color,
              height: 10
            }
          },
          '.loginContainer': {
            minHeight: 500,
            height: `calc(100vh - 85px)`,
            '& .paper': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: white,
              paddingTop: '1rem',
              paddingBottom: '1rem',
              [breakpoints.down("sm")]: {
                boxShadow: 'none'
              }
            },
          },
          '.mainBackground': {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            background: white,
            '&.auth': {
              background: white,
              [breakpoints.up("sm")]: {
                background: 'linear-gradient(45deg, ' + primary_color + ' 25%, ' + tertiary_color + ' 100%)',
                backgroundImage: `url('../images/gemco-bg.png')`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
                backgroundPosition: `center`,
              },
            },
          },
          '.mainContent': {
            flexGrow: 1,
            paddingTop: pxToRem(35),
            paddingBottom: pxToRem(35),
          },
          '.CustomBox': {
            display: 'flex',
            justifyContent: "space-between",
            marginBottom: `1rem`,
            [breakpoints.down("xs")]: {
              flexDirection: 'column',
            },
            '&.imageContainer': {
              alignSelf: "start",
              padding: '1rem',
            },
            '&.row': {
              flexDirection: 'row',
            },
            '&.column': {
              flexDirection: 'column',
            },
            '&> *': {
              '&.auto': {
                width: '100%',
                [breakpoints.up("xs")]: {
                  width: 'auto',
                },
              },
              '&.fullWidth': {
                width: '100%',
              },
              '&> .MuiDivider-vertical': {
                flexGrow: 1,
                alignSelf: 'center',
                margin: '0 0.5rem',
              }
            },
            "&.noMargin": {
              margin: 0,
            },
            '&.alignRight': {
              justifyContent: "flex-end",
            },
            '&.alignLeft': {
              justifyContent: "flex-start",
            },
          },
          '.baseForm': {
            width: "100%",
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
            [breakpoints.down("md")]: {
              flexDirection: "column",
            },
            [breakpoints.up("lg")]: {
              flexWrap: "wrap",
              alignItems: "center",
            },
            "&> .MuiFormControl-root": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              margin: "1rem 0.5rem",
              "&.full": {
                flexBasis: "100%",
                flex: 1,
              },
              "&.half": {
                flexBasis: "45%",
              },
              "&.daterange": {
                "&> .MuiTypography-root": {
                  marginBottom: "1rem",
                },
                "&> .MuiFormControl-root": {
                  marginBottom: "1rem",
                },
              },
              "&.flexRowAuto": {
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: 'space-between',
                "&> .additionalOption": {
                  width: "92%",
                  [breakpoints.down("md")]: {
                    width: "80%",
                  },
                  [breakpoints.down("sm")]: {
                    width: "50%",
                  },
                  flexWrap: "nowrap",
                  display: "flex",
                  justifyContent: 'space-between',
                  "&> .item": {
                    display: "flex",
                    justifyContent: 'start',
                    "& .MuiInputLabel-formControl": {
                      top: "15px",
                    },
                    "&.additionalRadio": {
                      marginLeft: '0.5rem',
                      "&.show": {
                        display: 'flex',
                      },
                      "&.hide": {
                        display: 'none',
                      },
                    },
                    "&> .MuiInputBase-root": {
                      width: "100%",
                    },
                    "&.full": {
                      width: "100%",
                    },
                    "&.auto": {
                      width: "75%",
                      [breakpoints.down("md")]: {
                        width: "65%",
                      },
                    },
                  },
                },
                "&> .buttonGroup": {
                  marginLeft: '0.5rem'
                },
                "&> button.buttonMinus": {
                  width: "5%",
                  flexShrink: 1,
                },
                "&> button.buttonAdd": {
                  width: "5%",
                  flexShrink: 0,
                },
                "&> .error": {
                  width: "100%",
                  flexShrink: 0,
                },
              },
              "&.Mui-error": {
                "&> .MuiFormControl-root .MuiInputLabel-formControl": {
                  color: red,
                },
                "&> .MuiFormControl-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: red,
                }
              }
            },
            "&> .MuiFormControl-fullWidth": {
              flexBasis: "100%",
              "&.formTitle": {
                margin: "0rem 0.5rem",
              },
            },
            "&> .MuiDivider-root": {
              flexGrow: 1,
            },
            "&.fieldFull": {
              "&> .MuiFormControl-root": {
                flexBasis: "100%",
                flex: 1,
              },
            },
            "&.fieldHalf": {
              "&> .MuiFormControl-root": {
                flexBasis: "48%",
                flex: 2,
              },
            },
            "&.fieldThird": {
              "&> .MuiFormControl-root": {
                flexBasis: "30%",
                flex: 3,
              },
            },
            "& button": {
              margin: "1rem 0rem",
              padding: "16px 0",
            },
            "& .MuiInputAdornment-root button": {
              padding: `0.5rem`,
              "&:hover": {
                backgroundColor: primary_color,
                "& .MuiSvgIcon-root": {
                  fill: white
                }
              },
            },
            "& .splitButtonContainer": {
              "&> .MuiGrid-root": {
                paddingRight: "3px",
                paddingTop: "30px"
              }
            },
            "& .splitButton": {
              margin: "0rem 0rem",
              padding: "0px 0",

              "&> .left": {
                padding: "8px 22px",
                margin: "0",
                fontSize: "0.9375rem",
              },
              "&> .right": {
                padding: "8px 8px",
                margin: "0",
                fontSize: "0.9375rem",
              },
            },
            "& .GroupSubmit": {
              "&> *": {
                padding: "0 0.5rem",
                "& button": {
                  marginTop: pxToRem(18),
                  padding: "0.5rem",
                  minWidth: pxToRem(100),
                  "&.reset": {
                    backgroundColor: red,
                    borderColor: red,
                    color: white,
                    "&:hover": {
                      backgroundColor: "#0069d9",
                      borderColor: "#0062cc",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "#0062cc",
                      borderColor: "#005cbf",
                    },
                    "&:focus": {
                      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
                    },
                  },
                },
              },
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
              top: "inherit",
            },
          },
          '.navMinimise': {
            "&> .MuiIconButton-edgeEnd": {
              marginRight: "-6px",
            },
          },
          '.chips-approved': {
            background: '#398439',
            color: white
          },
          '.chips-requested': {
            background: '#204d74',
            color: white
          },
          '.chips-progress': {
            background: '#d58512',
            color: white
          },
          '.chips-new': {
            background: '#269abc',
            color: white
          },
          '.chips-rejected': {
            background: '#ac2925',
            color: white
          },
          '.seperator': {
            background: primary_color + " !important",
            color: white + " !important",
            padding: "1rem !important",
            borderRadius: "5px"
          },
          '.tableContainer .MuiBox-root.searchFilter': {
            margin: '0 0.5rem'
          },
          '.titleBackground': {
            backgroundColor: primary_color,
            marginBottom: '0.5rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            borderRadius: '5px',
            '&.primary_color': {
              backgroundColor: primary_color,
            },
            '&.secondary_color': {
              backgroundColor: secondary_color,

            }
          },
          '.teamCompetencyTable': {
            '& .header': {
              display: 'intial',
              [breakpoints.down("sm")]: {
                display: 'none'
              },
            },
            '& .row': {
              '& .column': {
                '& .columnTitle': {
                  display: 'none',
                  [breakpoints.down("sm")]: {
                    display: 'block'
                  },
                },
              },
            },
          },
          '.cellBg': {
            backgroundColor: '#FFFFFF',
            "&.cellGreen": {
              backgroundColor: green,
            },
            "&.cellAmber": {
              backgroundColor: amber,
            },
            "&.cellRed": {
              backgroundColor: red,
            },
            "&.primary_color": {
              backgroundColor: primary_color,
            },
            "&.secondary_color": {
              backgroundColor: secondary_color,
            },
            "&.tertiary_color": {
              backgroundColor: tertiary_color,
            },
            "&.quaternary_color": {
              backgroundColor: quaternary_color,
            },
          }
        },
      },
      MuiCard: {
        root: {
          "&.fixedHeight": {
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            "& .MuiCardHeader-root": {
              top: 0,
              zIndex: 1,
              position: 'sticky',
            }
          }
        }
      },
      MuiToolbar: {
        root: {
          background: header_bg,
        }
      },
      MuiDrawer: {
        paper: {
          background: `#fff`,
        },
      },
      MuiAvatar: {
        img: {
          width: 'auto',
          height: 'auto',
        },
        root: {
          '&.fieldItem': {
            width: '150px',
            height: '150px',
            marginBottom: '15px',
          },
          '&.systemLogo': {
            backgroundColor: primary_color,
            padding: '5px',
          },
        },

        colorDefault: {
          backgroundColor: primary_color,
          '&.circleRed': {
            backgroundColor: red,
          },
          '&.circleAmber': {
            backgroundColor: amber,
          },
          '&.circleGreen': {
            backgroundColor: green,
          },
        }
      },
      MuiCardHeader: {
        root: {
          backgroundColor: tertiary_color,
          padding: `0.5rem 1rem`,
          color: white,
          '&.right': {
            justifyContent: "flex-end",
          },
        },
      },
      MuiCardContent: {
        root: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          '&:last-child': {
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem'
          },
          '&> .MuiDataGrid-root ': {
            border: 0
          }
        },
      },
      MuiCardActions: {
        root: {
          '&.right': {
            justifyContent: "flex-end",
          }
        },
      },
      MuiChip: {
        root: {
          backgroundColor: primary_color,
          color: white,
          "&.chips-custom": {
            backgroundColor: primary_color,
            color: white
          },
          "&.chips-enabled": {
            backgroundColor: green,
            color: white
          },
          "&.chips-active": {
            backgroundColor: green,
            color: white
          },
          "&.chips-completed": {
            backgroundColor: green,
            color: white
          },
          "&.chips-approved": {
            backgroundColor: green,
            color: white
          },
          "&.chips-pending": {
            backgroundColor: amber,
            color: white
          },
          "&.chips-progress": {
            backgroundColor: amber,
            color: white
          },
          "&.chips-requested": {
            backgroundColor: amber,
            color: white
          },
          "&.chips-new": {
            backgroundColor: amber,
            color: white
          },
          "&.chips-deleted": {
            backgroundColor: red,
            color: white
          },
          "&.chips-disabled": {
            backgroundColor: red,
            color: white
          },
          "&.chips-rejected": {
            backgroundColor: red,
            color: white
          },
          "&.chips-archived": {
            backgroundColor: red,
            color: white
          },
          "&.chips-revoked": {
            backgroundColor: red,
            color: white
          },
          "&.chips-expired": {
            backgroundColor: red,
            color: white
          },
        }
      },
      MuiPaper: {
        root: {
          '&.flex': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '&> .MuiCardContent-root': {
              flexGrow: 1,
            },
          }
        },
      },
      MuiDivider: {
        root: {
          margin: '0',
          backgroundColor: hexToRgbA(tertiary_color, 0.3)
        },
        middle: {},
        vertical: {
          // height: '95%',
          // minHeight: '210px'
        }
      },
      MuiDialog: {
        root: {
          "&.modalForm": {
            "& .MuiDialogTitle-root": {
              padding: '1rem',
              paddingBottom: 0,
            },
            "& .MuiDialogContent-root": {
              padding: '0',
            },
            "& .MuiDialogActions-root": {
              padding: '1rem',
              paddingTop: 0,
            },
          }
        }
      },
      MuiButtonBase: {
        root: {
          '&.reset': {
            backgroundColor: red,
            borderColor: red,
            color: white,
            '&:hover': {
              backgroundColor: '#f50057',
              borderColor: '#f50057',
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#f50057',
              borderColor: '#f50057',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
          }
        }
      },
      MuiFormHelperText: {
        root: {
          fontSize: `0.8rem`,
        }
      },
      MuiFormGroup: {
        root: {
          flexDirection: 'row',
        }
      },
      MuiAccordion: {
        root: {
          '&.customAccordion': {
            '&.Mui-expanded': {
              '&> .accordionSummary': {
                '&> .MuiAccordionSummary-content.Mui-expanded': {
                  display: `none`,
                },
                '&> .MuiAccordionSummary-expandIcon.Mui-expanded': {
                  background: primary_color,
                  transform: `none`,
                }
              }
            },
          }
        }
      },
      MuiAccordionDetails: {
        root: {
          "&.accordionDetails": {
            flexDirection: 'column',
          },
        },
      },
      MuiCollapse: {
        root: {
          '&.FormAlert': {
            padding: `1rem`,
            margin: `0rem 0.5rem`,
          }
        }
      },
      MuiTable: {
        root: {
         padding: '0.375rem',
         fontSize: '0.75rem',
        },
      },
      MuiTableHead: {
        root: {
          "&.DynamicTableHead": {
            display: 'table',
            width: '100%',
            [breakpoints.down("sm")]: {
              display: 'none'
            },
            "& .MuiTableRow-root": {
              background: "#7A7D80",
              "&> .MuiTableCell-stickyHeader": {
                background: "#7A7D80",
              },
              "&> .MuiTableCell-root": {
                color: white,
                fontWeight: "bold",
                "&> .MuiTableSortLabel-root.MuiTableSortLabel-active": {
                  color: white,
                  "&> .MuiTableSortLabel-icon": {
                    color: white,
                  },
                },
                "&> .MuiTableSortLabel-root:hover": {
                  color: white,
                },
              },
            },
          }
        },
      },
      MuiTableContainer: {
        root: {
          "&.recordTable": {
            maxHeight: `70VH`,
            '& .tableDetail': {
              display: 'table-caption',
              captionSide: 'bottom',
              width: '100%',
              background: "rgba(0, 0, 0, 0.04)",
              '& .tableDetailInfo': {
                width: '100%',
                '& .tableDetailExpanded': {
                  padding: '1rem',
                },
              },
            },
            "& .MuiTableRow-root": {
              '&.tableDetailExpanded': {
                paddingLeft: '20px',
                paddingTop: '20px',
              },
            },
            "& .MuiTableBody-root": {
              width: '100%',
              "& .MuiTableRow-root": {
                [breakpoints.down("sm")]: {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                },
                "& .MuiTableCell-root": {
                  [breakpoints.down("sm")]: {
                    width: '50% !important',
                  },
                  "&.mobileTableLabel": {
                    [breakpoints.up("md")]: {
                      display: 'none',
                    },
                  }
                },
              },
            },
          },
        },
      },
      MuiTablePagination: {
        root: {
          color: white,
          width: `100%`,
          "& .MuiTablePagination-input .MuiSelect-icon": {
            fill: secondary_color
          },
          "& .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root": {
            fill: quaternary_color
          },
          "& .MuiIconButton-root .MuiSvgIcon-root": {
            fill: secondary_color
          }
        },
      },
      MuiIconButton: {
        root: {
          padding: '0.2rem',
          "&.cellGreen": {
            "& .MuiSvgIcon-root": {
              fill: green
            },
            "&:hover": {
              backgroundColor: green,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
          "&.cellAmber": {
            "& .MuiSvgIcon-root": {
              fill: amber
            },
            "&:hover": {
              backgroundColor: amber,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
          "&.cellRed": {
            "& .MuiSvgIcon-root": {
              fill: red
            },
            "&:hover": {
              backgroundColor: red,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
          "&.primary_color": {
            "& .MuiSvgIcon-root": {
              fill: primary_color
            },
            "&:hover": {
              backgroundColor: primary_color,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
          "&.secondary_color": {
            "& .MuiSvgIcon-root": {
              fill: secondary_color
            },
            "&:hover": {
              backgroundColor: secondary_color,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
          "&.tertiary_color": {
            "& .MuiSvgIcon-root": {
              fill: tertiary_color
            },
            "&:hover": {
              backgroundColor: tertiary_color,
              "& .MuiSvgIcon-root": {
                fill: white
              },
            },
          },
        }
      },
      MuiSvgIcon: {
        root: {
          "&.cellGreen": {
            fill: green
          },
          "&.cellAmber": {
            fill: amber
          },
          "&.cellRed": {
            fill: red
          },
          "&.primary_color": {
            fill: primary_color
          },
          "&.secondary_color": {
            fill: secondary_color
          },
          "&.tertiary_color": {
            fill: tertiary_color
          },
        }
      },
      MuiTypography: {
        root: {
          "&.noPadding": {
            padding: 0
          },
          "&.noMargin": {
            margin: 0
          },
        }
      },
    },
    palette: {
      primary: {
        main: primary_color,
        contrastText: white
      },
      secondary: {
        main: secondary_color,
        contrastText: primary_color
      },
      cell_media_blue: {
        light: '#1c3664',
        main: cell_media_blue,
        dark: '#112446',
        contrastText: white,
      },
      mobilise_me_green: {
        light: '#a2ce5e',
        main: mobilise_me_green,
        dark: '#a2ce5e',
        contrastText: white,
      },
      red: {
        main: red,
        contrastText: white
      },
      amber: {
        main: amber,
        contrastText: white
      },
      green: {
        main: green,
        contrastText: white
      },
      white: {
        main: white,
      },
      black: {
        main: black,
      },
    },
    typography: {
      fontFamily: {
        fontFamily: main_font,
      },
      h1: {
        fontFamily: header_font,
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: header_font,
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h3: {
        fontFamily: header_font,
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h4: {
        fontFamily: header_font,
        fontWeight: 700,
      },
      h5: {
        fontFamily: header_font,
        fontWeight: 700,
      },
      h6: {
        fontFamily: header_font,
        fontWeight: 700,
      },
      subtitle1: {
        fontFamily: main_font,
      },
      subtitle2: {
        fontFamily: main_font,
      },
      body1: {
        fontFamily: main_font,
      },
      body2: {
        fontFamily: main_font,
        fontWeight: 700,
      },
      button: {
        fontFamily: header_font,
        fontWeight: 700,
      },
      caption: {
        fontFamily: header_font,
        textTransform: 'uppercase',
        fontWeight: 700,
      },
      overline: {
        fontFamily: main_font,
      },
    },
  });
};