import React, {useEffect, useState} from "react";
import {ColorPicker} from 'material-ui-color';
import {FormControl, Typography} from "@material-ui/core";

export default function ColorPickerUi(props) {
    let i = 0;
    const {title, defaultValue} = props;
    const [color, setColor] = useState("#000");

    useEffect(() => {
        setColor(defaultValue)
    });

    const handleChange = (value) => {
        setColor(value);
    };

    return (
        <FormControl
            key={title + '_' + i++}
        >
            <Typography
                variant="button"
                gutterBottom
            >
                {title}
            </Typography>
            <ColorPicker value={color} onChange={handleChange} />
        </FormControl>
    );
};
