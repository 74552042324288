import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {FormControl, InputLabel} from "@material-ui/core";
import {useStore} from "../../../../stores/store";

export default function SwitchField(props) {
    const {className, id, label, variant, fullwidth, margin, required, onChange} = props;

    return (
        <FormControl className={className} variant={variant} required={required} fullWidth={fullwidth} margin={margin}>
            <FormControlLabel
                id={id}
                control={
                    <Switch
                        onChange={onChange}
                        name={id}
                    />}
                label={label}
            />
        </FormControl>
    );
}
