
import React from "react";
import axiosApiInstance from "../../../helpers/axiosConfig";

const Menus = (dispatch) => {
  axiosApiInstance
    .get(process.env.REACT_APP_API_DOMAIN + "api/v1/menus")
    .then(res => {
      dispatch({type: "MENUS", menus: res.data});
      dispatch({type: "MENUS_UPDATED", menus_updated: true});
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default Menus;