import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export function booleanFormat(value, type = '') {
  let typeToRender = type;
  let newValue = value;

  if (type.includes("|")) {
    typeToRender = type.split("|")[1];
  }

  switch (typeToRender) {
    case 'icon':
      if (value) {
        newValue = <CheckIcon className={'cellGreen'}/>;
      } else {
        newValue = <ClearIcon className={'cellRed'}/>;
      }
      break;
    default:
      if (value) {
        newValue = 'Yes';
      } else {
        newValue = 'No';
      }
  }

  return newValue;
}