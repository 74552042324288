import React from 'react';
import {useStore} from '../stores/store';
import {Chip} from '@material-ui/core';
import {stringReplace} from './stringReplace';

export function RenderStateValue(props) {
  const {type, value} = props;
  let stateToRender = type;
  let valueRender = value;
  const {state} = useStore();

  if (type.includes('|')) {
    stateToRender = type.split('|')[1];
  }
  if (state[stateToRender + '_data'] && state[stateToRender + '_data'][stateToRender].length > 0) {
    state[stateToRender + '_data'][stateToRender].map((thisState, key) => {

      if (thisState.id === value) {
        valueRender = thisState.name;
      }

      if(valueRender === value) {
        valueRender = '';
      }
    });
  }

  if (!valueRender || typeof (valueRender) === 'undefined') {
    valueRender = '';
  }

  switch (stateToRender) {
    case 'chip':
      return <Chip label={valueRender} className={'chips-custom chips-' + stringReplace(valueRender, 'lowercase', true)}/>;
    case 'status':
      return <Chip label={valueRender} className={'chips-custom chips-' + stringReplace(valueRender, 'lowercase', true)}/>;
    case 'types':
      return valueRender;
    default:
      return valueRender;
  }
}